import { getImagePath } from './product';
import API, { ThenArg } from '../services/API';

export const getAddressesFromOrder = order => {
  const addresses: any = [];

  // fakturacna adresa
  const { address, company, delivery } = order;
  const billingAddress = {
    isBillingAddress: true,
    company: company.name || address.company,
    name: `${address.person.name} ${address.person.surname}`,
    street: `${address.street} ${address.street_number}`,
    zip: address.zip,
    city: address.city,
    country:
      address.country.toLowerCase() === 'slovensko' ? '' : address.country, // ak je krajina Slovensko, tak ju nezobrazujeme
    phone: address.person.phone,
    email: address.person.email,
    ico: company.ico,
    dic: company.dic,
    icDph: company.ic_dph,
  };
  addresses.push(billingAddress);

  if (order.use_delivery_address) {
    // adresa dorucenia
    const deliveryAddress = {
      isBillingAddress: false,
      company: delivery.delivery_firm,
      name: `${delivery.person.name} ${delivery.person.surname}`,
      street: `${delivery.delivery_addr_street} ${delivery.delivery_addr_street_number}`,
      zip: delivery.delivery_addr_zip,
      city: delivery.delivery_addr_city,
      country:
        delivery.delivery_addr_country === 'slovensko'
          ? ''
          : delivery.delivery_addr_country, // ak je krajina Slovensko, tak ju nezobrazujeme
      phone: delivery.person.phone,
      email: delivery.person.email,
      ico: company.ico,
      dic: company.dic,
      icDph: company.ic_dph,
    };
    addresses.push(deliveryAddress);
  }

  return addresses;
};

export const getPaymentUrl = (paymentType: string, orderPublicId: string) => {
  switch (paymentType) {
    case 'CARDPAY':
      return `${process.env.REACT_APP_API_BASE_URL}/payment/cardpay/${orderPublicId}`;
    case 'TATRAPAY':
      return `${process.env.REACT_APP_API_BASE_URL}/payment/tatrapay/${orderPublicId}`;
    case 'SPOROPAY':
      return `${process.env.REACT_APP_API_BASE_URL}/payment/sporopay/${orderPublicId}`;
    case 'VUBPAY':
      return `${process.env.REACT_APP_API_BASE_URL}/payment/vubpay/${orderPublicId}`;
    case 'GPWEBPAY':
      return `${process.env.REACT_APP_API_BASE_URL}/payment/gpwebpay/${orderPublicId}`;
    default:
      return null;
  }
};

export const getOrderItemImage = images => {
  const mainImg: any = Object.values(images).find((img: any) => img.primary);
  let url = '';
  if (mainImg) {
    url = getImagePath(mainImg.filename);
  }
  return url;
};

export const isBesteron = (payment_type: string): boolean => {
  return /^BESTERON/.test(payment_type);
};

export const shouldRedirect = (
  order: ThenArg<typeof API.getOrder>,
): boolean => {
  if (!order.payment) {
    return false;
  }

  if (
    window &&
    window.location &&
    window.location.href &&
    (isPaid() || isError())
  ) {
    return false;
  }

  if (order.payment.payment_type !== 'GPWEBPAY') {
    return false;
  }

  if (order.order_paid_datetime !== null) {
    return false;
  }

  return true;
};

export const isError = (): boolean => {
  if (!window || !window.location || !window.location.href) {
    return false;
  }
  const url = new URL(window.location.href);

  return url.searchParams.get('error') !== null;
};

export const isPaid = (): boolean => {
  if (!window || !window.location || !window.location.href) {
    return false;
  }
  const url = new URL(window.location.href);

  return url.searchParams.get('paid') !== null;
};
