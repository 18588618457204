import { LoaderWrapper } from 'eshop-defaults/lib/components/ZlataPriadka/General/LoaderWrapper';
import { ProductList } from 'eshop-defaults/lib/components/ZlataPriadka/Product/ProductList';
import * as React from 'react';
import styled from 'styled-components';
import {
  loadFavouriteProducts,
  removeItemFromFavourite,
} from '../../containers/Home/actions';

interface Props {
  products: any;
  dispatch: any;
  user: any;
  isFetching: boolean;
}

export const MyFavourites = ({
  products,
  dispatch,
  user,
  isFetching,
}: Props) => {
  const toggleFavourite = async productId => {
    await dispatch(removeItemFromFavourite(user.id, Number(productId)));
    dispatch(loadFavouriteProducts(user.id?.toString() as string, true));
  };

  return (
    <React.Fragment>
      <ListWrapper>
        <ProductList
          isFetching={isFetching}
          products={products}
          visible={true}
          isElasticCategory={false}
          productsInRow={3}
          toggleFavourite={toggleFavourite}
          isFavourite={true}
          isAccountFavourite={true}
        />
      </ListWrapper>
    </React.Fragment>
  );
};

const ListWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
    width: 90%;
    margin: 0 auto;
  `}
`;
