import { rem, rgba } from 'polished';
import { transition, transitions } from './libs/transition';
import { media } from './libs/media';
import { css } from 'styled-components';

const alerts = {
  success: {
    borderColor: 'rgb(165,187,162)',
    backgroundColor: 'rgb(165,187,162,0.3)',
  },
  error: {
    borderColor: 'rgb(235,103,108)',
    backgroundColor: 'rgb(235,103,108,0.3)',
  },
};

const blogColors = {
  primary: '#414141',
  secondary: '#FFD607',

  darkGray: '#606060',
  gray: '#AAAAAA',
  lightGray: '#000000',
  tagBg: '#F2F2F2',
  white: '#FFFFFF',
  black: '#000000',
  border: '#DDDDDD',
  link: '#337BCC',
};

const blogFonts = {
  primaryFont: `Barlow, sans-serif;`,
  secondaryFont: `omnes-pro, sans-serif`,
};

const blogShadows = {
  default: `0 ${rem(1)} ${rem(2)} ${rgba(blogColors.black, 0.16)}`,
};

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  whiteOnHover: '#f2f2f2',
  light: '#0000000A',
  textPrimary: '#07151C',
  primary: '#D2A4B0',
  primaryLight: '#F8EDE5',
  primaryOnHover: '#c297a2',
  gold: '#D2A167',
  purple: '#ADAEDB',
  success: '#A5BBA2',
  danger: '#EB676C',
  secondary: '#A5BBA2',
  secondaryOnHover: '#91a68f',
  primaryBackground: 'rgba(210, 164, 176, 0.12)',
  secondaryBackground: 'rgba(165, 187, 162, 0.12)',
  highlight: '#FFF46B',
  textRowBackground: 'rgba(7, 21, 28, 0.02)',
  tertiary: '#3962A8',
  gray: '#CCC',
  lightGray: '#EEE',
  silver: '#CCCCCC',
  tertiaryBackground: 'rgba(57, 98, 168, 0.04)',
  backgroundProducts: '#F7F6FA',
  footer: '#555555',
  secondaryTransparent: 'rgba(255,201,87, 0.1)',
  placeholder: rgba('#07151C', 0.56),
  base: '#F2EEE8',
  footerLink: '#BBBBBB',
  loaderPrimary: '#3E3E3E',
  loaderSecondary: '#d4cdc3',
  inactiveFontColor: '#aaaaaa',
  fontHighlightColor: '#EE227B',
  primaryFontColor: '#07151C',
  secondaryFontColor: '#3E3E3E',
  menuCartTextColor: '#555555',
  borderColor: '#EEEEEE',
  dotsColor: '#e3e3e3',
  activeDotColor: '#FFC957',
  sliderRailColor: '#eaeaea',
  successColor: '#9CC381',
  dangerColor: '#FF4464',
  red: '#FF0000',
  fuscousGray: '#514E49',
  separatorColor: '#eee',
  alto: '#dddddd',
  hover: 'rgb(49, 39, 131, 0.03)',
  disabled: '#F9F9F9',
  stockCountAvailable: '#2DCC8D',
  stockCountUnavailable: '#FF2D58',
  navSecondary: 'rgba(49, 39, 131, 0.04)',
  paginationSecondary: 'rgba(57, 98, 168, 0.08)',
  grey: '#888888',
};

const infoTexts = {
  colorError: '#FF2D58',
  colorInfo: colors.black,
  colorSuccess: '#2DCC8D',
  bgError: '#FF2D5814',
  bgInfo: colors.tertiaryBackground,
  bgSucces: '#2dcc8d26',
  borderInfo: '#FFD21E52',
};

const tagColors = {
  new: '#A5BBA2',
  action: '#EB676C',
  saleout: '#D2A167',
};

const font = {
  primary: `urbane-rounded, sans-serif`,
  secondary: `terfens, sans-serif`,
};

const cartStep = {
  done: colors.secondary,
  current: colors.secondary,
  next: colors.secondary,
  font: font.primary,
  fontSize: `${rem(20)}`,
  fontColor: `#F4F7F4`,
  mobileFontSize: `${rem(14)}`,
  mobileFont: font.primary,
  borderDefault: colors.secondary,
};

const cartChooser = {
  active: colors.primary,
  bgHover: 'rgb(49, 39, 131, 0.08)',
};

const borders = {
  primary: `${rem(1)} solid rgb(49, 39, 131, 0.16)`,
  secondary: `${rem(2)} solid #EEEEEE`,
  footer: `${rem(1)} solid rgba(255, 255, 255, 0.08)`,
  product: `${rem(1)} solid #E6E4F0`,
  table: `${rem(1)} solid #31278329`,
  active: `${rem(2)} solid ${colors.primary}`,
  weak: `${rem(1)} solid #31278314`,
  productList: `${rem(1)} solid #E8E8E8`,
  decoration: `${rem(2)} solid #D2A4B052`,
};

const categoryFilter = {
  // filter item styles
  bgColor: colors.white,
  color: colors.primary,
  border: borders.weak,
  borderRadius: rem(4),
  padding: `${rem(16)} ${rem(24)}`,
  margin: `0 0 ${rem(12)}`,

  // active filter styles
  activeBgColor: colors.primary,
  activeColor: colors.white,

  // slider styles
  sliderTrackColor: colors.primary,
  sliderRailColor: rgba(colors.primary, 0.24),
  trackBorder: `${rem(4)} solid black`,
  trackBorderColor: `${colors.primary}`,
  trackBgColor: '#FAF4F6',

  // reset filter styles
  textColor: colors.black,
  resetFontSize: rem(14),
  resetFont: font.secondary,

  // mobile styles
  mobilePadding: `${rem(24)} ${rem(16)}`,
  mobileMargin: 0,
};

const boxShadow = {
  default: `0 ${rem(2)} ${rem(8)} #00000014;`,
  input: `0 ${rem(2)} ${rem(4)} #00000014;`,
  secondary: `0px ${rem(2)} ${rem(8)} #00000029;`,
  header: `0 ${rem(1)} ${rem(4)} ${rgba(colors.black, 0.16)}`,
};

const grid = {
  padding: 48,
  container: {
    base: 976,
    wide: 1456,
    huge: 1568,
  },
  col: {
    col1: (1 / 12) * 100 + '%',
    col2: (2 / 12) * 100 + '%',
    col3: (3 / 12) * 100 + '%',
    col4: (4 / 12) * 100 + '%',
    col5: (5 / 12) * 100 + '%',
    col6: (6 / 12) * 100 + '%',
    col7: (7 / 12) * 100 + '%',
    col8: (8 / 12) * 100 + '%',
    col9: (9 / 12) * 100 + '%',
    col10: (10 / 12) * 100 + '%',
    col11: (11 / 12) * 100 + '%',
    col12: (12 / 12) * 100 + '%',
  },
};

const breakpoints = {
  l1620: 1620,
  l1400: 1400,
  l1300: 1300,
  l1150: 1150,
  l1050: 1050,
  l925: 925,
  m810: 810,
  m760: 760,
  m700: 700,
  m600: 600,
  m580: 580,
  s450: 450,
  s380: 380,
  s320: 320,
};

const mediab = {
  l1620: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.l1620}px) {
      ${css(arg, ...args)}
    }
  `,
  l1400: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.l1400}px) {
      ${css(arg, ...args)}
    }
  `,
  l1300: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.l1300}px) {
      ${css(arg, ...args)}
    }
  `,
  l1150: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.l1150}px) {
      ${css(arg, ...args)}
    }
  `,
  l1050: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.l1050}px) {
      ${css(arg, ...args)}
    }
  `,
  l925: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.l925}px) {
      ${css(arg, ...args)}
    }
  `,
  m810: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.m810}px) {
      ${css(arg, ...args)}
    }
  `,
  m760: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.m760}px) {
      ${css(arg, ...args)}
    }
  `,
  m700: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.m700}px) {
      ${css(arg, ...args)}
    }
  `,
  m600: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.m600}px) {
      ${css(arg, ...args)}
    }
  `,
  m580: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.m580}px) {
      ${css(arg, ...args)}
    }
  `,
  s450: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.s450}px) {
      ${css(arg, ...args)}
    }
  `,
  s380: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.s380}px) {
      ${css(arg, ...args)}
    }
  `,
  s320: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.s320}px) {
      ${css(arg, ...args)}
    }
  `,
};

const infoBar = {
  activeCircleColor: '#514E49',
  inActiveCircleColor: '#F2EEE8',
};

const pagination = {
  backgroundActive: colors.fuscousGray,
};

const tags = {
  freeDelivery: '#9CC381',
  discount: '#FFC957',
  new: '#9CC381',
  sale: '#FF4464',
  saleOut: '#FFC957',
};

const button = {
  background: colors.primary,
  borderRadius: 4,
};

const mobileNavItem = {
  fontSize: '21px',
  lineHeight: '1.4',
  padding: '15px 0',
  border: '1px dashed colors.borderColor',
  arrow: {
    width: '8px',
    height: '5px',
  },
};

const box = {
  mainBorder: `${rem(1)} solid ${colors.separatorColor}`,
  headerBorder: `${rem(1)} solid ${colors.separatorColor}`,
  iconBorder: `${rem(1)} solid ${colors.separatorColor}`,
  padding: rem(20),
  boxTitle: colors.primaryFontColor,
  iconContentWidth: rem(60),
};

const myAccount = {
  leftSidebar: {
    background: rgba(colors.secondary, 0.15),
    padding: rem(20),
    width: rem(250),
    headerSpacing: `0 0 ${rem(20)} 0`,
  },
  tab: {
    title: colors.primaryFontColor,
    textLeft: colors.primaryFontColor,
    textRight: colors.primaryFontColor,
  },
  orderItem: {
    imageHeight: rem(45),
    imageWidth: rem(45),
    titleColor: colors.primaryFontColor,
    padding: `${rem(10)} 0`,
  },
};

const footerDropdownTitle = {
  titleSizeMobile: '21px',
  titleColor: colors.textPrimary,
  lineHeight: '1.5',
  arrow: {
    width: '8px',
    height: '8px',
  },
};

const product = {
  onStockColor: 'rgba(156, 195, 129, 1)',
  notInStockColor: 'rgba(225, 201,87, 1)',
  activeThumbnailBorder: `${rem(1)} solid ${colors.primary}`,
};

const formElements = {
  border: {
    width: 1,
    color: colors.borderColor,
    radius: 4,
    colorOnFocus: colors.secondary,
  },
  font: font.primary,
  gridItem: {
    margin: '0 -7.5px 60px -7.5px',
    padding: '0 7.5px',
    paddingMediaS: '0',
  },
  table: {
    rowHoverColor: rgba(colors.secondary, 0.15),
    border: `${rem(2)} solid ${rgba(colors.secondary, 0.5)}`,
    headerBorder: `${rem(2)} solid ${rgba(colors.secondary, 0.5)}`,
  },
};

const cart = {
  cartStep: {
    default: {
      textColor: '#dddddd',
      numberColor: '#dddddd',
      paddingBottom: '0',
      circleFontFamily: font.primary,
      circleFontSize: rem(18),
      circleFontWeight: '600',
    },
    selected: {
      textColor: colors.textPrimary,
      numberColor: colors.secondary,
    },
    done: {
      textColor: colors.textPrimary,
      numberColor: '#81c277',
    },
  },
  button: {
    width: 275,
    height: 60,
    background: button.background,
    borderRadius: button.borderRadius,
  },
  totalPrice: {
    color: colors.primary,
    priceMarginLeft: 10,
    fontSize: 32,
    fontWeight: 600,
    top: rem(3),
    wrapperValign: 'flex-end',
  },
  cartMenuItem: {
    primary: '#ffc957',
    background: '#fef8ed',
  },
  cartItem: {
    imageContainer: {
      width: 40,
      height: 40,
      border: {
        width: 1,
        color: colors.borderColor,
        radius: 0,
      },
    },
    stockAvailabilityColor: colors.textPrimary,
  },
  itemCounter: {
    height: 50,
    border: {
      width: 1,
      style: 'solid',
      color: colors.borderColor,
    },
    counter: {
      width: 60,
    },
    buttons: {
      width: 40,
      leftBorderRadius: 4,
      rightborderRadius: 4,
    },
  },
  discountCard: {
    toggleButtonColor: colors.textPrimary,
    useButtonColor: colors.primary,
  },
  cartSummary: {
    borderTop: `${rem(1)} dashed ${colors.borderColor}`,
    borderBottom: `${rem(1)} dashed ${colors.borderColor}`,
  },
};

export const theme = {
  alerts,
  blogColors,
  blogFonts,
  blogShadows,
  tagColors,
  color: {
    ...colors,
  },
  boxShadow: {
    ...boxShadow,
  },
  font: {
    ...font,
  },
  grid: {
    ...grid,
  },
  // loader: {
  //   ...loader,
  // },
  // header: {
  //   ...header,
  // },
  // footer: {
  //   ...footer,
  // },
  // formWithIcon: {
  //   ...formWithIcon,
  // },
  // filter: {
  //   ...filter,
  // },
  infoTexts,
  primary: '#FFFFFF',
  secondary: '#F2FAFA',
  successColor: '#9CC381',
  warningColor: colors.secondary,
  dangerColor: '#D24D57',
  btnPrimary: colors.primary,
  btnSecondary: colors.base,
  btnSecondaryTextColor: colors.black,
  separatorColor: colors.borderColor,
  borderRadius: rem(4),
  borderSolid: `${rem(1)} solid ${colors.borderColor}`,
  borderDashed: `${rem(1)} dashed ${colors.borderColor}`,
  box,
  black: '#000000',
  fontSizeXXS: rem(12),
  fontSizeXS: rem(14),
  fontSizeS: rem(16),
  fontSizeM: rem(18),
  fontSizeL: rem(24),
  fontSizeXL: rem(32),
  index: {
    default: 0,
    categoryOverlay: 100,
    priorityContent: 200,
    priorityContentShadow: 199,
    overlay: 300,
    menu: 400,
    tooltip: 450,
    modalShadow: 500,
    modal: 600,
    max: 999999,
  },
  myAccount,

  button,
  pagination,
  mobileNavItem,
  footerDropdownTitle,
  cart,
  infoBar,
  colors,
  cartStep,
  cartChooser,
  categoryFilter,
  borders,
  tags,
  product,
  mainContainerSize: '1167px',
  formElements,
  media,
  mediab,
  transition,
  transitions,
};

export interface ThemeInterface {
  any;
}
