import {
  RECEIVE_HOME_BANNER_SUCCESS,
  REQUEST_HOME_NEW_PRODUCTS,
  RECEIVE_HOME_NEW_PRODUCTS_SUCCESS,
  RECEIVE_HOME_NEW_PRODUCTS_ERROR,
  REQUEST_HOME_SALE_PRODUCTS,
  RECEIVE_HOME_SALE_PRODUCTS_SUCCESS,
  RECEIVE_HOME_SALE_PRODUCTS_ERROR,
  RECEIVE_COMPANY_INFO_SUCCESS,
  REQUEST_FAVOURITE_PRODUCTS,
  RECEIVE_FAVOURITE_PRODUCTS_SUCCESS,
  RECEIVE_FAVOURITE_PRODUCTS_ERROR,
  RECEIVE_NEW_ARTICLES_SUCCESS,
} from './constants';

const INITIAL_STATE: any = {
  newProducts: {
    isFetching: false,
    productsData: null,
  },
  saleProducts: {
    isFetching: false,
    productsData: null,
  },
  favouriteProducts: {
    isFetching: false,
    productsData: null,
  },
  adBanner: null,
  firstBenefits: null,
  secondBenefits: null,
  companyInfo: null,
  newArticles: {
    articles: null,
    total: 0,
  },
};

export const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_FAVOURITE_PRODUCTS: {
      return {
        ...state,
        favouriteProducts: {
          ...state.favouriteProducts,
          isFetching: true,
        },
      };
    }

    case RECEIVE_FAVOURITE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        favouriteProducts: {
          ...state.favouriteProducts,
          isFetching: false,
          productsData: action.payload.products,
        },
      };
    }

    case RECEIVE_FAVOURITE_PRODUCTS_ERROR: {
      return {
        ...state,
        favouriteProducts: {
          ...state.favouriteProducts,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_NEW_PRODUCTS: {
      return {
        ...state,
        newProducts: {
          ...state.newProducts,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_NEW_PRODUCTS_SUCCESS: {
      return {
        ...state,
        newProducts: {
          ...state.newProducts,
          isFetching: false,
          productsData: action.payload.products,
        },
      };
    }

    case RECEIVE_HOME_NEW_PRODUCTS_ERROR: {
      return {
        ...state,
        newProducts: {
          ...state.newProducts,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_SALE_PRODUCTS: {
      return {
        ...state,
        saleProducts: {
          ...state.saleProducts,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_SALE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        saleProducts: {
          ...state.saleProducts,
          isFetching: false,
          productsData: action.payload.products,
        },
      };
    }

    case RECEIVE_HOME_SALE_PRODUCTS_ERROR: {
      return {
        ...state,
        saleProducts: {
          ...state.saleProducts,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case RECEIVE_HOME_BANNER_SUCCESS: {
      return {
        ...state,
        adBanner: action.payload.banner,
      };
    }

    // case RECEIVE_HOME_BENEFITS_SUCCESS: {
    //   return {
    //     ...state,
    //     firstBenefits:
    //       action.payload.benefits && action.payload.benefits.slice(0, 4)
    //         ? action.payload.benefits.slice(0, 4)
    //         : [],
    //     secondBenefits:
    //       action.payload.benefits && action.payload.benefits.slice(4, 8)
    //         ? action.payload.benefits.slice(4, 8)
    //         : [],
    //   };
    // }

    case RECEIVE_COMPANY_INFO_SUCCESS: {
      return {
        ...state,
        companyInfo: action.payload.companyInfo
          ? action.payload.companyInfo
          : [],
      };
    }

    case RECEIVE_NEW_ARTICLES_SUCCESS: {
      return {
        ...state,
        newArticles: action.payload.newArticles,
      };
    }

    default: {
      return state;
    }
  }
};
