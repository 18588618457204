import API, { ThenArg } from '../services/API';

export function heurekaPurchase(order: ThenArg<typeof API.getOrder>) {
  if (order && Object.keys(order).length !== 0 && window._customHrq) {
    window._hrq.push(['setOrderId', `${order.order_id}`]);

    if (order.items && order.items.length) {
      order.items.map(item => {
        if (item.product_name !== 'Doprava a platba') {
          window._hrq.push([
            'addProduct',
            item.product_name,
            `${item.totalprice}`,
            `${item.quantity}`,
            `${item.good_id}`,
          ]);
        }
      });
    }

    window._hrq.push(['trackOrder']);

    if (window.trackHeurekaOrder) {
      window.trackHeurekaOrder();
    }
  }
}
