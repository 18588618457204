import { createSelector } from 'reselect';
import { prop } from '../../utilities';

export const cmsDomainSelector = state => state.cms || {};

export const sitemapSelector = createSelector(cmsDomainSelector, substate =>
  prop(substate, 'sitemap', []),
);

export const sitemapByIdSelector = createSelector(cmsDomainSelector, substate =>
  prop(substate, 'byId', {}),
);

export const sitemapIsFetchingSelector = createSelector(
  cmsDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const allArticlesSelector = createSelector(cmsDomainSelector, substate =>
  prop(substate, 'allArticles', null),
);

export const articlesIsFetchingSelector = createSelector(
  cmsDomainSelector,
  substate => prop(substate, 'isFetchingArticles', true),
);

export const articleConnectionsIsFetchingSelector = createSelector(
  cmsDomainSelector,
  substate => prop(substate, 'connections.isFetching', false),
);

export const articleConnectionsSelector = createSelector(
  cmsDomainSelector,
  substate => prop(substate, 'connections.articles', null),
);
