import * as React from 'react';
import styled from 'styled-components';

import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from 'pure-react-carousel';
import { getBannerImagePath, getImagePath } from '../../../utilities/product';
import { Link } from 'react-router';
import { rem, rgba } from 'polished';
import {
  ProductTabSmall,
  NoItemsWrapper,
  TextP,
  ZlataPriadkaSvgIcon as SvgIcon,
} from 'eshop-defaults';
import { prop } from '../../../utilities';
import { css } from 'styled-components';
import { SliderBanner } from './SliderBanner';
import { IconType } from 'eshop-defaults/lib/components/ZlataPriadka/General/SvgIcon';

const buttonLikeCss = css`
  position: absolute;
  height: ${({ type }) => (type === 'photogallery' ? '80px' : '40px')};
  width: ${({ type }) => (type === 'photogallery' ? '48px' : '40px')};
  background-color: ${({ type, theme }) =>
    type === 'photogallery' ? theme.colors.white : '#DADFED'};
  z-index: 1;
  border: none;
  top: ${({ type }) =>
    type === 'photogallery' ? '40%' : type === 'brands' ? '20%' : '45%'};

  visibility: ${({ type }) =>
    type === 'product-detail' ? 'hidden' : 'visible'};

  &:disabled {
    display: none;
  }

  /* @media only screen and (max-width: 925px) {
    visibility: hidden;
    z-index: -10;
  } */

  ${({ theme }) => theme.media(0, 'sm')} {
    visibility: hidden;
    z-index: -10;
  }

  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const blogProductLikeCss = css`
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  height: 30px;
  width: 30px;
  z-index: 500;
  top: -56px;
  border: none;
  background-color: transparent;
  visibility: ${({ type }) =>
    type === 'product-detail' ? 'hidden' : 'visible'};

  &:disabled {
    cursor: unset;
  }

  @media only screen and (max-width: 580px) {
    visibility: hidden;
    z-index: -10;
  }

  @media only screen and (max-width: 520px) {
    visibility: hidden;
    z-index: -10;
  }
`;

const BtnBackWrapper = styled(ButtonBack)<{ type?: string }>`
  left: ${({ type }) =>
    type === 'photogallery' ? `0` : type === 'brands' ? '-14px' : '-20px'};

  ${props =>
    props.type && props.type === 'blog-product'
      ? `${blogProductLikeCss};
    background-image: url('/images/blog_assets/back-arrow.svg');
    right: 56px;
    left: unset;
    &:disabled {
      background-image: url('/images/blog_assets/arrow_back_gray.svg');
    }
    `
      : buttonLikeCss}

    border-radius: 0 ${rem(4)} ${rem(4)} 0;
`;

const BtnNextWrapper = styled(ButtonNext)`
  right: ${({ type }) =>
    type === 'photogallery' ? `0` : type === 'brands' ? '-14px' : '-20px'};

  ${props =>
    props.type && props.type === 'blog-product'
      ? `${blogProductLikeCss};
    background-image: url('/images/blog_assets/right-arrow.svg');
    right: 0px;
    left: unset;
    &:disabled {
      background-image: url('/images/blog_assets/right-arrow-gray.svg');
    }
    `
      : buttonLikeCss}

  border-radius: ${rem(4)} 0 0 ${rem(4)};
`;

const BtnBack = styled(SvgIcon)`
  opacity: 1;
`;

const BtnNext = styled(BtnBack)`
  opacity: 1;
`;

const Carousel = styled(CarouselProvider)`
  margin: auto;
  position: relative;
  width: 100%;
  overflow: visible;
`;

const ClassicDotComponent = styled(Dot)`
  width: 8px;
  height: 8px;
  border: none;
  padding: 0;
  margin: 0 8px;
  border-radius: 50%;
  background-color: #dddddd;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const BannerDotComponent = styled(Dot)`
  border: none;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: ${rem(24)};
  /* opacity: 0.16; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: ${({ theme }) => theme.colors.disabled};

  @media only screen and (max-width: 925px) {
    width: ${rem(8)};
    height: ${rem(8)};
    border-radius: 50%;
    padding: 0;
    border: none;
    background: #eeeeee;
    opacity: 1;
  }

  &:disabled {
    opacity: 1;
    color: ${({ theme }) => theme.colors.textPrimary};
    border-bottom: ${rem(2)} solid ${({ theme }) => theme.colors.primary};

    @media only screen and (max-width: 925px) {
      background: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const BannerDotWrapper = styled.div`
  position: relative;
  width: 100%;
  border-left: ${({ theme }) => theme.borders.banners};
  border-right: ${({ theme }) => theme.borders.banners};
  border-top: none;
  border-bottom: none;

  @media only screen and (max-width: 925px) {
    border: none;
    width: initial;
    padding: ${rem(4)};
  }
`;

// const BannerDotImage = styled.img`
//   margin-right: ${rem(24)};
//   ${({ theme }) => theme.mediab.l925`
//      width: 40px;
//      height: 40px;
//   `}

//   @media only screen and (max-width: 925px) {
//     display: none;
//   }
// `;

const BannerDotName = styled(TextP)`
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.primary};
  color: inherit;
  font-size: ${rem(16)};
  line-height: ${rem(20)};

  margin: auto 0;

  ${({ theme }) => theme.mediab.l925`
     font-size: ${rem(10)};
  `}

  @media only screen and (max-width: 925px) {
    display: none;
  }
`;

const Dots = styled.div`
  width: 100%;
  margin: auto;
  bottom: 0;
  text-align: center;
  position: absolute;
  display: none;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  /* @media only screen and (max-width: 1130px) {
    margin-top: -35px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 620px) {
    margin-top: -10px;
  }
  @media only screen and (max-width: 580px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 520px) {
    margin-top: -2vw;
  }
  @media only screen and (max-width: 450px) {
    margin-top: 0;
  } */
  @media only screen and (max-width: 380px) {
    bottom: ${({ type }) => (type === 'ebook' ? '-220px' : 'initial')};
  }
  @media only screen and (max-width: 340px) {
    bottom: ${({ type }) => (type === 'ebook' ? '-200px' : 'initial')};
  }

  bottom: 0;
  position: static;

  ${({ theme }) => theme.mediab.l925`
     box-shadow: none;
     padding-top: ${rem(12)};
     padding-bottom: ${rem(12)};
     background: ${({ theme }) => theme.colors.white};
  `}

  ${({ theme }) => theme.media(0, 'sm')} {
    display: block;
  }
`;

const ClassicDots = styled.div`
  width: 100%;
  margin: auto;
  margin-top: -32px;
  text-align: center;
  position: absolute;
  @media only screen and (max-width: 1130px) {
    margin-top: -35px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 620px) {
    margin-top: -10px;
  }
  @media only screen and (max-width: 580px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 520px) {
    margin-top: -2vw;
  }
  @media only screen and (max-width: 450px) {
    margin-top: 0;
  }
  @media only screen and (max-width: 380px) {
    bottom: ${({ type }) => (type === 'ebook' ? '-220px' : 'initial')};
  }
  @media only screen and (max-width: 340px) {
    bottom: ${({ type }) => (type === 'ebook' ? '-200px' : 'initial')};
  }
`;

const DotsWrapper = styled.div<{ isContentCenter?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ isContentCenter }) =>
    isContentCenter ? `center` : 'space-between'};
  bottom: 0;
  position: static;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  margin: 0 auto;

  ${({ theme }) => theme.mediab.l925`
    justify-content: center;
  `}
`;

const PhotogalleryItem = styled.div`
  margin: auto;
  width: 100%;
  line-height: 20px;
  display: flex;
  height: 100%;
  /* max-height: ${rem(540)}; */

  div {
    width: 100%;
  }

  /* @media only screen and (max-width: 580px) {
    width: 100%;
    height: 528px;
  } */

  /* @media only screen and (max-width: 420px) {
    width: 100%;
    height: 210px;
  }
  @media only screen and (max-width: 380px) {
    width: 100%;
    height: 210px;
  } */
`;

const PhotogalleryCover = styled.img`
  margin: auto;
  width: 100%;
  height: 305px;
  border: 1px solid #dddddd;

  @media only screen and (max-width: 900px) {
    width: auto;
  }
  /* @media only screen and (max-width: 580px) {
    width: 100%;
    height: 528px;
  } */
  /* @media only screen and (max-width: 420px) {
    width: 100%;
    height: 210px;
  }
  @media only screen and (max-width: 380px) {
    width: 100%;
    height: 210px;
  } */
`;

const BrandWrapper = styled.div`
  width: 95%;
  height: ${rem(80)};
  margin: 0 auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000014;
  border-radius: 4px;
  text-align: center;
`;

const BrandImage = styled.img``;

// const EbookItem = styled(Link)`
//   text-decoration: none;
//   margin: auto;
//   width: 210px;
//   height: 305px;
//   box-shadow: 1px 3px 15px #dddddd;
//   margin-right: 32px;
//   line-height: 20px;
//
//   @media only screen and (max-width: 900px) {
//     width: 180px;
//     height: 255px;
//   }
//
//   @media only screen and (max-width: 620px) {
//     width: 150px;
//     height: 210px;
//   }
//   @media only screen and (max-width: 520px) {
//     width: 180px;
//     height: 255px;
//   }
//
//   @media only screen and (max-width: 420px) {
//     width: 150px;
//     height: 210px;
//   }
//   @media only screen and (max-width: 380px) {
//     width: 120px;
//     height: 170px;
//   }
// `;

// const Cover = styled.img`
//   margin: auto;
//   width: 210px;
//   height: 305px;
//   /* border: 1px solid #dddddd; */
//
//   @media only screen and (max-width: 900px) {
//     width: 180px;
//     height: 255px;
//   }
//   @media only screen and (max-width: 620px) {
//     width: 150px;
//     height: 210px;
//   }
//   @media only screen and (max-width: 520px) {
//     width: 180px;
//     height: 255px;
//   }
//   @media only screen and (max-width: 420px) {
//     width: 150px;
//     height: 210px;
//   }
//   @media only screen and (max-width: 380px) {
//     width: 120px;
//     height: 170px;
//   }
// `;

const ProductItem = styled(Link)`
  text-decoration: none;
  /* margin: auto; */
  width: 228px;
  height: 231px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${rem(4)};
  background-color: white;
  box-shadow: 0px 1px 4px #00000014;

  @media only screen and (max-width: 1130px) {
    margin: auto;
  }
  @media only screen and (max-width: 580px) {
    width: 100%;
    height: 230px;
  }
  @media only screen and (max-width: 420px) {
    width: 100%;
    height: 230px;
  }
  @media only screen and (max-width: 380px) {
    width: 100%;
    height: 230px;
  }
`;

const ProductThumb = styled.img`
  margin: auto;
  width: 91px;
  height: 120px;
  max-height: 120px;
`;

const ProductName = styled.p`
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 24px;
  width: 85%;
  color: #414141;
  font-weight: 500;
  line-height: 20px;
`;

const SliderCustom = styled(Slider)`
  /* li {
    min-height: ${rem(540)};
  }

  @media only screen and (max-width: 1130px) {
    min-height: initial;
  } */

  li {
    @media only screen and (max-width: 580px) {
    width: 100%;
    height: 100%;
    
    article {
      flex: 1 0 auto;
    }
    }
  }
`;

interface State {
  width: number;
  height: number;
  visibleSlides: number;
  type: string;
  slides: any;
}

export interface Props {
  type: string;
  slides: any;
  addToCart?: any;
}

function SliderComponent({ type, slides, addToCart }: Props) {
  const [state, setState]: any = React.useState(() => {
    if (type === 'ebook') {
      return {
        width: 210,
        height: 360,
        visibleSlides: 4,
      };
    }

    if (type === 'brands') {
      return {
        width: 210,
        height: 80,
        visibleSlides: 4,
      };
    }
    return {
      width: 1920,
      height: 540,
      visibleSlides: 1,
    };
  });

  React.useEffect(() => {
    const handleResize = () => updateState(window.innerWidth);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, []);

  const { width, height, visibleSlides } = state;

  const renderItem = (key: number, img: string, item: any) => {
    if (type === 'photogallery') {
      return (
        <PhotogalleryItem key={key}>
          {/* <PhotogalleryCover
            alt="Obrázok vo fotogalerií"
            src={getImagePath(
              img,
              {
                width: 1085,
                height: 430,
              },
              false,
              false,
              true,
            )}
          /> */}
          <SliderBanner
            title={item.b_name}
            description={item.b_description}
            imagePath={
              item.b_path !== 'banners/'
                ? getBannerImagePath(
                    item.b_path,
                    {
                      width: 1085,
                      height: 430,
                    },
                    false,
                    true,
                    true,
                  )
                : null
            }
            nonWebpImage={
              item.b_path !== 'banners/'
                ? getBannerImagePath(
                    item.b_path,
                    {
                      width: 1085,
                      height: 430,
                    },
                    false,
                    false,
                    true,
                  )
                : null
            }
            redirectUrl={item.b_url}
            buttonDescription="Zobraziť"
          />
        </PhotogalleryItem>
      );
    } else if (type === 'product') {
      return (
        <ProductItem to={`/${item.url}`} key={item.id}>
          <ProductThumb
            alt={item.name}
            src={getImagePath(item.image, { width: 90, height: 120 })}
          />
          <ProductName>{item.name}</ProductName>
        </ProductItem>
      );
    } else if (type === 'blog-product') {
      return (
        <ProductItem to={`/${item.url}`} key={item.id}>
          <ProductThumb
            alt={item.name}
            src={getImagePath(item.image, { width: 90, height: 120 })}
          />
          <ProductName>{item.name}</ProductName>
        </ProductItem>
      );
    } else if (type === 'product-detail') {
      return (
        <PhotogalleryItem key={key}>
          <PhotogalleryCover
            alt={`Obrázok vo fotogalerií č. ${key}`}
            src={getImagePath(img)}
          />
        </PhotogalleryItem>
      );
    } else if (type === 'brands') {
      return (
        <BrandWrapper>
          <BrandImage alt={`Logo značky`} src={getImagePath(img)} />
        </BrandWrapper>
      );
    }
    return null;
  };

  const renderDots = (items: any[], slide: number) => {
    const table: any[] = [];
    for (let i = 0; i < items.length; i += slide) {
      table.push(
        <BannerDotWrapper key={i}>
          <BannerDotComponent slide={i}>
            {/* <BannerDotImage
              src={getImagePath(
                items[i].b_path,
                { width: 56, height: 56 },
                false,
                false,
                true,
              )}
            /> */}
            <BannerDotName>{items[i].b_name}</BannerDotName>
          </BannerDotComponent>
        </BannerDotWrapper>,
      );
    }
    return table;
  };

  const renderClassicDots = (items: any[], slide: number) => {
    const table: any[] = [];
    for (let i = 0; i < items.length; i += slide) {
      table.push(<ClassicDotComponent slide={i} />);
    }
    return table;
  };

  const renderBtnBack = (type: string) => {
    return (
      <BtnBackWrapper type={type}>
        <BtnBack
          icon={IconType.leftArrow}
          width={16}
          height={16}
          viewBox="0 0 16 16"
          cursor="pointer"
        />
      </BtnBackWrapper>
    );
  };

  const renderBtnNext = (type: string) => {
    return (
      <BtnNextWrapper type={type}>
        <BtnBack
          icon={IconType.rightArrow}
          width={16}
          height={16}
          viewBox="0 0 16 16"
          cursor="pointer"
        />
      </BtnNextWrapper>
    );
  };

  const updateState = win => {
    if (type === 'photogallery' || type === 'product-detail') {
      if (win > 1300) {
        setState({
          width: 1920,
          height: 640,
          visibleSlides: 1,
        });
      } else if (win <= 1130 && win >= 950) {
        setState({
          width: 1130,
          height: 500,
          visibleSlides: 1,
        });
      } else if (win < 950 && win >= 768) {
        setState({
          width: 950,
          height: 450,
          visibleSlides: 1,
        });
      } else if (win < 768 && win >= 580) {
        setState({
          width: 768,
          height: 320,
          visibleSlides: 1,
        });
      } else if (win < 580 && win >= 420) {
        setState({
          width: 580,
          height: 320,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 300) {
        setState({
          width: 420,
          height: 420,
          visibleSlides: 1,
        });
      }
    } else if (type === 'product') {
      if (win > 1130) {
        setState({
          width: 210,
          height: 255,
          visibleSlides: 4,
        });
      } else if (win <= 1130 && win >= 780) {
        setState({
          width: 210,
          height: 205,
          visibleSlides: 3,
        });
      } else if (win < 780 && win >= 680) {
        setState({
          width: 200,
          height: 165,
          visibleSlides: 2,
        });
      } else if (win < 680 && win >= 580) {
        setState({
          width: 200,
          height: 195,
          visibleSlides: 2,
        });
      } else if (win < 580 && win >= 550) {
        setState({
          width: 400,
          height: 205,
          visibleSlides: 1,
        });
      } else if (win < 550 && win >= 480) {
        setState({
          width: 400,
          height: 215,
          visibleSlides: 1,
        });
      } else if (win < 480 && win >= 440) {
        setState({
          width: 400,
          height: 235,
          visibleSlides: 1,
        });
      } else if (win < 440 && win >= 420) {
        setState({
          width: 400,
          height: 250,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        setState({
          width: 350,
          height: 240,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        setState({
          width: 300,
          height: 260,
          visibleSlides: 1,
        });
      } else {
        setState({
          width: 300,
          height: 280,
          visibleSlides: 1,
        });
      }
    } else if (type === 'blog-product') {
      if (win > 1130) {
        setState({
          width: 210,
          height: 255,
          visibleSlides: 4,
        });
      } else if (win <= 1130 && win >= 780) {
        setState({
          width: 210,
          height: 205,
          visibleSlides: 3,
        });
      } else if (win < 780 && win >= 680) {
        setState({
          width: 200,
          height: 165,
          visibleSlides: 2,
        });
      } else if (win < 680 && win >= 580) {
        setState({
          width: 200,
          height: 195,
          visibleSlides: 2,
        });
      } else if (win < 580 && win >= 550) {
        setState({
          width: 400,
          height: 205,
          visibleSlides: 1,
        });
      } else if (win < 550 && win >= 480) {
        setState({
          width: 400,
          height: 215,
          visibleSlides: 1,
        });
      } else if (win < 480 && win >= 440) {
        setState({
          width: 400,
          height: 235,
          visibleSlides: 1,
        });
      } else if (win < 440 && win >= 420) {
        setState({
          width: 400,
          height: 250,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        setState({
          width: 350,
          height: 240,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        setState({
          width: 300,
          height: 260,
          visibleSlides: 1,
        });
      } else {
        setState({
          width: 300,
          height: 280,
          visibleSlides: 1,
        });
      }
    } else if (type === 'ebook') {
      if (win > 1300) {
        setState({
          width: 210,
          height: 330,
          visibleSlides: 4,
        });
      } else if (win <= 1300 && win >= 1150) {
        setState({
          width: 210,
          height: 400,
          visibleSlides: 4,
        });
      } else if (win < 1150 && win >= 1050) {
        setState({
          width: 300,
          height: 464,
          visibleSlides: 3,
        });
      } else if (win < 1050 && win >= 800) {
        setState({
          width: 250,
          height: 270,
          visibleSlides: 2,
        });
      } else if (win < 800 && win >= 700) {
        setState({
          width: 250,
          height: 300,
          visibleSlides: 2,
        });
      } else if (win < 700 && win >= 560) {
        setState({
          width: 250,
          height: 380,
          visibleSlides: 2,
        });
      } else if (win < 560 && win >= 500) {
        setState({
          width: 250,
          height: 430,
          visibleSlides: 2,
        });
      } else if (win < 500 && win >= 430) {
        setState({
          width: 300,
          height: 300,
          visibleSlides: 1,
        });
      } else if (win < 430 && win >= 370) {
        setState({
          width: 300,
          height: 350,
          visibleSlides: 1,
        });
      } else if (win < 370 && win >= 300) {
        setState({
          width: 300,
          height: 450,
          visibleSlides: 1,
        });
      } else {
        setState({
          width: 300,
          height: 450,
          visibleSlides: 1,
        });
      }
    } else if (type === 'brands') {
      if (win > 1130) {
        setState({
          width: 210,
          height: 80,
          visibleSlides: 4,
        });
      } else if (win <= 1130 && win >= 780) {
        setState({
          width: 210,
          height: 80,
          visibleSlides: 3,
        });
      } else if (win < 780 && win >= 680) {
        setState({
          width: 200,
          height: 80,
          visibleSlides: 2,
        });
      } else if (win < 680 && win >= 580) {
        setState({
          width: 200,
          height: 80,
          visibleSlides: 2,
        });
      } else if (win < 580 && win >= 550) {
        setState({
          width: 400,
          height: 80,
          visibleSlides: 1,
        });
      } else if (win < 550 && win >= 480) {
        setState({
          width: 400,
          height: 80,
          visibleSlides: 1,
        });
      } else if (win < 480 && win >= 440) {
        setState({
          width: 400,
          height: 80,
          visibleSlides: 1,
        });
      } else if (win < 440 && win >= 420) {
        setState({
          width: 400,
          height: 80,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        setState({
          width: 350,
          height: 80,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        setState({
          width: 300,
          height: 80,
          visibleSlides: 1,
        });
      } else {
        setState({
          width: 300,
          height: 80,
          visibleSlides: 1,
        });
      }
    }
  };

  return (
    <>
      {slides && slides.length > 0 ? (
        <Carousel
          naturalSlideHeight={height}
          naturalSlideWidth={width}
          visibleSlides={visibleSlides}
          step={1}
          totalSlides={slides.length}
          infinite={true}
        >
          {slides.length > 1 && renderBtnBack(type)}
          <SliderCustom type={type}>
            {slides.map((item, index: number) => {
              return (
                <Slide
                  innerClassName="sliderCentered"
                  index={index}
                  key={index}
                >
                  {renderItem(
                    index,
                    type === 'photogallery'
                      ? item.b_path
                      : type === 'brands'
                      ? item.brand_image
                      : item.img,
                    item,
                  )}
                </Slide>
              );
            })}
          </SliderCustom>
          {slides.length > 1 && renderBtnNext(type)}
          {type === 'photogallery' && (
            <Dots type={type}>
              <DotsWrapper>{renderDots(slides, visibleSlides)}</DotsWrapper>
            </Dots>
          )}
          {type === 'blog-product' && (
            <ClassicDots type={type}>
              <DotsWrapper isContentCenter={true}>
                {renderClassicDots(slides, visibleSlides)}
              </DotsWrapper>
            </ClassicDots>
          )}
        </Carousel>
      ) : (
        <NoItemsWrapper
          text={`Nenašli sa žiadne ${
            type === 'brands' ? 'značky' : 'produkty'
          }.`}
        />
      )}
    </>
  );
}
export default SliderComponent;
