import React from 'react';
import {
  FlexRowCenterVertical,
  ZlataPriadkaSvgIcon as SvgIcon,
  ZlataPriadkaIconType as IconType,
  FlexRow,
} from 'eshop-defaults';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { __, __r } from 'react-i18n';
import { Link } from 'react-router';
import { rem, rgba } from 'polished';
import { fetchCart, cartIdSelector } from '../../containers/Cart/cartSlice';
import { logoutUser } from 'react-auth/lib/containers/Auth/actions';

interface Props {
  user: any;
  dispatch: any;
  cartId: string;
}

function HeaderProfile({ user, dispatch, cartId }: Props) {
  const { name, surname, email } = user || {};
  const userName =
    name || surname ? `${name || ''} ${surname || ''}` : `${email}`;

  const handleLogoutUser = async () => {
    await dispatch(
      logoutUser(cartId, () => {
        dispatch(fetchCart(true));
      }),
    );
  };

  return (
    <Wrapper>
      <StyledLink
        to={
          user
            ? __r('routes:moj-ucet', '/moj-ucet')
            : __r('routes:prihlasenie', '/prihlasenie')
        }
      >
        <StyledSvgIcon
          icon={IconType.account}
          alt="Účet"
          cursor={'pointer'}
          viewBox="0 0 24 24"
          marginRight={12}
          width={24}
          height={24}
        />
      </StyledLink>

      <NameLinkWrapper>
        <TextLink
          to={
            user
              ? __r('routes:moj-ucet', '/moj-ucet')
              : __r('routes:prihlasenie', '/prihlasenie')
          }
        >
          {user ? userName : __('Prihlásenie')}
        </TextLink>
      </NameLinkWrapper>
    </Wrapper>
  );
}

const StyledLink = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  transition: color 0.2s ease;

  ${({ theme }) => theme.mediab.l925`
    width: 100%;
    display: flex;
    padding: 0 ${rem(16)};
  `}
`;

const StyledSvgIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.tertiary};

  ${({ theme }) => theme.mediab.l925`
    width: ${rem(16)};
    height: ${rem(16)};
    margin-right: ${rem(16)};
  `}
`;

const NameLinkWrapper = styled(FlexRow)`
  align-items: center;
`;

const headerTextLikecss = css`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${rem(16)};
  line-height: ${rem(19)};
  font-family: ${({ theme }) => theme.font.primary};
  margin-right: ${rem(32)};

  &:hover,
  &:focus {
    text-decoration: none;
  }

  ${({ theme }) => theme.mediab.l925`
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    font-family: ${({ theme }) => theme.font.primary};
    font-weight: 500;
    padding: ${rem(14)} ${rem(16)};
    padding-left: 0;
  `}
`;

const TextLink = styled(Link)`
  ${headerTextLikecss};
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    cartId: cartIdSelector(state),
  };
};

const TextWrapper = styled.div`
  display: block;
  cursor: pointer;

  p {
    margin: 0;
  }
`;

const Separator = styled.span`
  display: inline-block;
  margin: 0 ${rem(8)};
  ${headerTextLikecss};
`;

export default connect(mapStateToProps)(HeaderProfile);
