import { prop } from 'eshop-defaults';
import { resolveBaseUrlByLang } from '../configureTrans';

export const getCategoryIdsFromProduct = (product: any): string[] => {
  const pCategories = prop(product, 'parent_categories', []);
  const uniqueCatIds: string[] = [];
  pCategories.map(p => uniqueCatIds.push(p.category_number));
  return uniqueCatIds;
};

export function getCategoryImagePath(
  filename,
  { width, height } = { width: 56, height: 56 },
  noSize = false,
  webp = false,
  typeCover = false,
) {
  const requestedImagePath = filename
    ? filename.startsWith('catalog/')
      ? filename
      : 'catalog/' + filename
    : null;
  if (height === 0) {
    return `${
      process.env.REACT_APP_API_BASE_URL
    }/image?query=${requestedImagePath}&width=${width}&webp=${webp ? 1 : 0}${
      typeCover ? '&type=cover' : ''
    }`;
  }

  return noSize
    ? `${
        process.env.REACT_APP_API_BASE_URL
      }/image?query=${requestedImagePath}&webp=${webp ? 1 : 0}${
        typeCover ? '&type=cover' : ''
      }`
    : `${
        process.env.REACT_APP_API_BASE_URL
      }/image?query=${requestedImagePath}&width=${width}&height=${height}&webp=${
        webp ? 1 : 0
      }${typeCover ? '&type=cover' : ''}`;
}

export const getCategoryUrl = (url: string, lang: string = 'sk') => {
  return `${resolveBaseUrlByLang(lang)}/${url}`;
};
