import * as React from 'react';
import { connect } from 'react-redux';
import { default as API, ThenArg } from '../../services/API';
import { langSelector, titleSelector } from '../Category/selectors';
import { userSelector, contactInfoSelector } from '../App/selectors';
import Product from '../../components/Product/Product';
import { connectSsr } from 'ssr-service';
import { loadProductDetail, loadProductDetailRelated } from './actions';
import {
  productDataSelector,
  productDetailIsFetchingSelector,
  productAlternativesIsFetchingSelector,
  productAlternativesSelector,
} from './selectors';
import {
  ZlataPriadkaInfoText as InfoText,
  FlexCol,
  ZlataPriadkaLoaderWrapper as LoaderWrapper,
  prop,
} from 'eshop-defaults';
import { __ } from 'react-i18n';
import { InfoType } from 'eshop-defaults/lib/components/ZlataPriadka/General/InfoText';
import styled from 'styled-components';
import { rem } from 'polished';
import { cartDataSelector } from '../Cart/cartSlice';
import {
  productCategoriesSelector,
  productCategoriesIsFetchingSelector,
} from '../Header/selectors';
import { loadFavouriteProducts } from '../Home/actions';
import { favouriteProductsSelector } from '../Home/selectors';

import { getGoogleAdsId } from '../../configureTagManager';
import {
  fbViewItem,
  ga4ViewItem,
  gaViewItem,
} from '@bart.sk-ecommerce/react-online-marketing';

export interface ProductProps {
  user: ThenArg<typeof API.tokeninfo>;
  productData: ThenArg<typeof API.loadProduct>;
  dispatch: any;
  isFetching: boolean;
  lang: string;
  params: {
    product_id: number | string;
    product_url: string;
  };
  alternativeProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAlternatives: boolean;
  cart: ThenArg<typeof API.getCart> | null;
  categories: ThenArg<typeof API.loadTree>;
  categoriesIsFetching;
  favouriteProducts: any;
}

class ProductContainer extends React.Component<ProductProps> {
  public static async getInitialProps({
    product_id,
    params: { product_id: productId },
    dispatch,
    user,
  }) {
    try {
      await Promise.all[
        (user && (await dispatch(loadFavouriteProducts(user.id))),
        await dispatch(loadProductDetail(product_id)),
        await dispatch(loadProductDetailRelated(product_id)))
      ];
      return;
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
      };
    }
  }

  // public metaHandler = lang => {
  //   const { product } = this.props;
  //   const productPublish = propByKey(product, 'publish', []).find(
  //     pp => pp.lang_id === lang,
  //   );
  //
  //   const metaTags = propByKey(productPublish, 'content.json_content.meta', {
  //     title: this.props.defaultTitle,
  //   });
  //
  //   if (!metaTags.title.length) {
  //     metaTags.title = `${this.props.defaultTitle} - ${product.name}`;
  //   }
  //
  //   return <MetaTags tags={metaTags} />;
  // };

  public componentDidMount() {
    if (this.props.productData) {
      // ga4ViewItem(this.props.productData, getGoogleAdsId(), 'EUR');
      gaViewItem(this.props.productData, getGoogleAdsId());
      fbViewItem(this.props.productData);
    }
  }

  public componentDidUpdate(prevProps: ProductProps) {
    const { productData } = this.props;
    if (productData && prevProps.productData !== productData) {
      // ga4ViewItem(productData, getGoogleAdsId(), 'EUR');
      gaViewItem(this.props.productData, getGoogleAdsId());
      fbViewItem(productData);
    }
  }

  public render() {
    const {
      productData,
      isFetching,
      alternativeProducts,
      isFetchingAlternatives,
      dispatch,
      user,
      cart,
      categories,
      categoriesIsFetching,
      favouriteProducts,
    } = this.props;

    if (isFetching) {
      return <LoaderWrapper height={300} />;
    }

    if (!productData) {
      return (
        <Wrapper className="container container--wide">
          <InfoText info={__('Produkt nebol nájdený')} type={InfoType.ERROR} />
        </Wrapper>
      );
    }

    const products = prop(favouriteProducts, 'productsData.products', []);
    const favProductsIds: number[] = [];
    products.map(p => favProductsIds.push(p.product_id));

    return (
      <Product
        product={productData}
        isFetching={isFetching}
        dispatch={dispatch}
        alternativeProducts={alternativeProducts}
        isFetchingAlternatives={isFetchingAlternatives}
        user={user}
        cart={cart}
        categories={categories}
        categoriesIsFetching={categoriesIsFetching}
        favouriteProducts={favProductsIds}
      />
    );
  }
}

const Wrapper = styled(FlexCol)`
  padding: ${rem(56)} ${rem(24)} ${rem(80)};
  color: #444444;
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
      padding: ${rem(32)} 0;
  `}
`;

const mapStateToProps = state => {
  return {
    user: userSelector(state),
    lang: langSelector(state),
    defaultTitle: titleSelector(state),
    contactInfo: contactInfoSelector(state),
    productData: productDataSelector(state),
    isFetching: productDetailIsFetchingSelector(state),
    alternativeProducts: productAlternativesSelector(state),
    isFetchingAlternatives: productAlternativesIsFetchingSelector(state),
    cart: cartDataSelector(state),
    categories: productCategoriesSelector(state),
    categoriesIsFetching: productCategoriesIsFetchingSelector(state),
    favouriteProducts: favouriteProductsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ProductContainer' })(ProductContainer),
);
