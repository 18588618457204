import { prop } from './index';

interface ArticleContentDetails {
  body: string;
  annotation: string;
  name: string;
  anotation?: string;
}

export const resolveArticleContentDetails = (
  article,
): ArticleContentDetails => {
  const name = prop(article, 'json_content.name', '');
  let body = prop(article, 'json_content.body', {});
  let annotation = prop(article, 'json_content.annotation', {});
  if (
    body &&
    Object.entries(body).length === 0 &&
    body.constructor === Object
  ) {
    body = '';
  }

  if (
    Object.entries(annotation).length === 0 &&
    annotation.constructor === Object
  ) {
    annotation = '';
  }
  return {
    name,
    body,
    annotation,
  };
};

export const getRssFeedUrl = (sitemapId = 0, lang) => {
  const basicUrl = `${process.env.REACT_APP_API_BASE_URL}/rss`;

  return sitemapId !== 0
    ? `${basicUrl}?sitemap_id=${sitemapId}&lang_id=${lang}`
    : `${basicUrl}?lang_id=${lang}`;
};

export const replaceMatchesInBody = (
  body: string,
  regex: RegExp,
  newTag: string,
  matchValuesArray: Array<{ matchValueId: string; newValueId: string }>,
) => {
  if (!body) {
    return '';
  }

  const matches = body && body.match(regex);
  if (!matches) {
    return body;
  }

  let newBody = body;
  matches.map(stringMatch => {
    let values = '';
    matchValuesArray.map(m => {
      const srcSplit = stringMatch.split(`${m.matchValueId}="`);
      let id = '';
      if (srcSplit && srcSplit.length > 1) {
        id = srcSplit[1].split('"')[0];
      }
      console.log({ id });
      if (id !== '') {
        values += ` ${m.newValueId}="${id}" `;
      }
    });
    newBody = newBody.replace(stringMatch, `<${newTag}${values}></${newTag}>`);
  });

  return newBody;
};
