/* eslint-disable */
import * as React from 'react';
import { __, __r } from 'react-i18n/lib';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { rem, rgba } from 'polished';
import {
  FlexCol,
  ZlataPriadkaLoaderWrapper as LoaderWrapper,
  FlexRow,
  FlexRowCenterVertical,
  ZlataPriadkaPagination as Pagination,
  ZlataPriadkaCategoryFilter as CategoryFilter,
  ZemplinFilterType as FilterType,
  ZlataPriadkaMobileCategoryFilterButton as MobileCategoryFilterButtons,
  ZlataPriadkaMobileNavMenu as MobileNavMenu,
  ZlataPriadkaMobileCategoryFilterMenu as MobileCategoryFilterMenu,
  ZlataPriadkaLinksFilter as LinksFilter,
  ZlataPriadkaActiveFilters as ActiveFilters,
  ZlataPriadkaCategoryTopFilters as CategoryTopFilters,
  ZlataPriadkaCategoryFilterCheckboxes as CategoryFilterCheckboxes,
  ZlataPriadkaCategoryFilterDesktop as CategoryFilterDesktop,
  ZlataPriadkaProductList as ProductList,
  ZemplinCategoryTableList as CategoryTableList,
  ZlataPriadkaNoItemsWrapper as NoItemsWrapper,
  ZlataPriadkaCategoryCard as CategoryCard,
  ZlataPriadkaCategoryCardType as CategoryCardType,
  FlexRowCenter,
  ZlataPriadkaIconType as IconType,
  ZlataPriadkaSvgIcon as SvgIcon,
  CategoryHeader,
} from 'eshop-defaults';
import { prop, arrayToObject, getCompanyInfoData } from '../../utilities';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { useLocalStorage } from '../../utilities/hooks';
import { getCategoryMetaTags } from '../../utilities/metatags';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import { withRouter, WithRouterProps } from 'react-router';
import {
  urlAttribsToObject,
  attribsObjectToUrl,
  fetchCategory,
} from '../../containers/Category/categorySlice';
import { resolveCategoryShortUrl } from 'eshop-defaults/lib/utilities/selectors';
import { setCustomerGoodOrderNr } from '../../containers/Product/actions';
import { isCategory } from 'eshop-defaults/lib/components/Zemplin/selectors';
import {
  getCategoryIdsFromProduct,
  getCategoryImagePath,
} from '../../utilities/category';
import { getImagePath } from '../../utilities/product';
import BreadCrumb from '../../containers/BreadCrumb/BreadCrumb';
import { css } from 'styled-components';
import {
  addItemToFavourite,
  loadFavouriteProducts,
  removeItemFromFavourite,
} from '../../containers/Home/actions';
import { push } from 'react-router-redux';

const INITIALLY_OPEN_ATTRIBS = ['Farba'];
export interface Props {
  dispatch: any;
  isFetching: boolean;
  // products: ThenArg<typeof API.loadElasticCategoryProducts>;
  products: any;
  data: ThenArg<typeof API.loadCategory>;
  user: any;
  catName: string;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  categories: any[];
  renderHeader: any;
  searchTerm: string;
  cart: any;
  treeById: any;
  homeCompanyInfo: any[];
  favouriteProducts: any;
}

function SearchResults({
  isFetching,
  products,
  data,
  dispatch,
  user,
  catName,
  filterData,
  location,
  router,
  categories,
  renderHeader,
  searchTerm,
  cart,
  treeById,
  homeCompanyInfo,
  favouriteProducts,
}: Props & WithRouterProps) {
  const categoriesRef = React.useRef<HTMLDivElement>(null);
  const productsRef = React.useRef<HTMLDivElement>(null);
  const catUrl = prop(location, 'pathname');
  const query = prop(location, 'search');
  const urlAttribs = prop(location, 'query');
  const urlAttribsObj = urlAttribsToObject(urlAttribs);
  const currentCategoryImage = prop(data, 'image', null);
  const parentCategories = prop(data, 'parent_categories', []);
  const parentCategoryId = prop(data, 'category_parent', null);

  const [currentView, setCurrentView] = useLocalStorage('product-view', 'card');
  const [isCategoryFilterVisible, setIsCategoryFilterVisible] = React.useState(
    false,
  );

  const cartItems = prop(cart, 'items', []);
  const cartItemsIds: any = cartItems
    ? cartItems.map(c => prop(c, 'good.good_id'))
    : [];

  const [isFilterMenuVisible, setIsFilterMenuVisible] = React.useState(false);
  const categoryName = catName ? catName : prop(data, 'category_name');

  const productCategoryIds = getCategoryIdsFromProduct(data);
  productCategoryIds.push(prop(data, 'category_number', ''));

  const filteredCategories = categories.filter(
    c => prop(treeById[c.category_id], 'counts', 0) > 0,
  );
  const categoryLinks = filteredCategories
    // .filter(c => c.counts && c.counts > 0)
    .map(c => ({
      name: c.category_name,
      url: resolveCategoryShortUrl(c.category_id, c.url),
    }));

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.SEARCH, { query: searchTerm }));
  }, [data, dispatch]);

  React.useEffect(() => {
    const scroll = prop(location, 'state.scroll');
    if (
      scroll &&
      ((categoriesRef && categoriesRef.current) ||
        (productsRef && productsRef.current))
    ) {
      if (scroll === 'categories' && categories) {
        window.scrollTo({
          behavior: 'smooth',
          top: categoriesRef!.current!.offsetTop - 50,
        });
      } else if (scroll === 'products' && categories && products) {
        window.scrollTo({
          behavior: 'smooth',
          top: productsRef!.current!.offsetTop - 50,
        });
      }
    }
  }, [location, categoriesRef, productsRef, categories, products]);

  const toggleCategoryFilterMenu = () => {
    document.body.style.overflow = isCategoryFilterVisible
      ? 'visible'
      : 'hidden';
    setIsCategoryFilterVisible(!isCategoryFilterVisible);
  };

  const toggleFilterMenu = () => {
    document.body.style.overflow = isFilterMenuVisible ? 'visible' : 'hidden';
    setIsFilterMenuVisible(!isFilterMenuVisible);
  };

  const handleFilterChange = (
    type: FilterType,
    newData:
      | { min: number; max: number; attribId?: string }
      | {
          attribId: string;
          valueId: string;
          newValue: boolean;
          sameIds?: string[];
        },
  ) => {
    switch (type) {
      case FilterType.RANGE: {
        const attribId = prop(newData, 'attribId');
        if (attribId) {
          const currentRangeAttribFilters = prop(urlAttribsObj, 'rangeAttribs');
          const min = parseInt(prop(newData, 'min').toFixed(0));
          const max = parseInt(prop(newData, 'max').toFixed(0));
          const { attribs } = filterData;
          const attribDataMin = prop(attribs[attribId], 'attrib_data.min');
          const attribDataMax = prop(attribs[attribId], 'attrib_data.max');

          let rangeAttribsObj = currentRangeAttribFilters
            ? currentRangeAttribFilters
            : {};

          if (
            Math.floor(attribDataMin) !== min ||
            Math.ceil(attribDataMax) !== max
          ) {
            rangeAttribsObj[attribId] = {
              min: parseInt(prop(newData, 'min').toFixed(0)),
              max: parseInt(prop(newData, 'max').toFixed(0)),
            };
          } else {
            delete rangeAttribsObj[attribId];
          }
          router.push(
            attribsObjectToUrl(catUrl, {
              ...urlAttribsObj,
              rangeAttribs: rangeAttribsObj,
            }),
          );
        } else {
          urlAttribsObj.min = parseInt(prop(newData, 'min').toFixed(0));
          urlAttribsObj.max = parseInt(prop(newData, 'max').toFixed(0));
          router.push(attribsObjectToUrl(catUrl, urlAttribsObj));
        }

        break;
      }

      case FilterType.OPTIONS: {
        const attribId = prop(newData, 'attribId');
        const valueId = prop(newData, 'valueId');
        const newValue = prop(newData, 'newValue');
        const currentAttribFilters = prop(urlAttribsObj, 'attribs');

        let updatedAttribsObj = currentAttribFilters;
        let values = updatedAttribsObj
          ? prop(currentAttribFilters[attribId], 'values')
          : null;
        if (values) {
          if (newValue) {
            values.push(valueId);
          } else {
            values = values.filter(v => valueId.toString() !== v);
          }
          if (values.length === 0) {
            updatedAttribsObj[attribId].values = null;
            delete updatedAttribsObj[attribId];
          } else {
            updatedAttribsObj[attribId].values = values;
          }
        } else {
          updatedAttribsObj = {
            ...updatedAttribsObj,
            [attribId]: { values: [valueId] },
          };
        }

        router.push(
          attribsObjectToUrl(catUrl, {
            ...urlAttribsObj,
            attribs: updatedAttribsObj,
          }),
        );

        break;
      }

      default:
        break;
    }
  };

  const handleSortChange = e => {
    const value = e.target.value;
    const [sort, sortDir] = value.split('_');
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        sort,
        sortDir: sortDir ? sortDir : null,
      }),
    );
  };

  const handleCheckboxChange = ({ name, value }) => {
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        otherAttribs: {
          ...urlAttribsObj.otherAttribs,
          [name]: value ? 1 : 0,
        },
      }),
    );
  };

  if (isFetching || !products) {
    return <LoaderWrapper height={200} />;
  }

  const allowedCustomAttribs = [
    'Farba',
    'Materiál',
    'Obdobie',
    'Výrobca',
    'Zloženie',
  ];

  const { limit, offset, total, products: productsArray } = products || {};
  const { minPrice, maxPrice, attribs } = filterData;

  const renderCustomAttribs = () => {
    return (
      <>
        {attribs &&
          Object.keys(attribs).map(key => {
            const attrib = attribs[key];
            if (
              !allowedCustomAttribs.includes(
                prop(attrib, 'attrib_data.attrib_name'),
              )
            ) {
              return;
            }

            const attribType = prop(attrib, 'attrib_data.attrib_type');
            const attribUnit = prop(attrib, 'attrib_data.attrib_unit');
            const valuesObj = attribs[key].values;
            const filteredAttribs = {};
            const attribKeys: any[] = [];
            Object.keys(valuesObj).map(key => {
              const value = prop(valuesObj[key], 'info.attrib_value');
              if (!value || value === '' || value === '-') {
              } else {
                filteredAttribs[key] = valuesObj[key];
              }

              const attribId = prop(valuesObj[key], 'info.attrib_id');
              if (!attribKeys.includes(attribId)) {
                attribKeys.push(attribId);
              }
            });

            const hasValues = Object.keys(filteredAttribs).length > 0;
            if (hasValues) {
              const showFilter = Object.keys(filteredAttribs).findIndex(
                valueKey => filteredAttribs[valueKey].doc_count > 0,
              );
              const activeValues: any[] = [];
              attribKeys.map(attrId => {
                activeValues.push(
                  ...prop(
                    prop(urlAttribsObj, 'attribs', {})[attrId],
                    'values',
                    [],
                  ),
                );
              });
              const rangeActiveValues = {};
              attribKeys.map(attrId => {
                const value = prop(urlAttribsObj, 'rangeAttribs', {})[attrId];
                if (value) {
                  rangeActiveValues[attrId] = value;
                }
              });
              const rangeActive =
                rangeActiveValues[prop(attrib, 'attrib_data.attrib_id')];
              const hasActive = rangeActive || activeValues.length > 0;

              const attribName = prop(
                attrib,
                'attrib_data.attrib_name',
                `Attrib ${key}`,
              );

              if (showFilter !== -1 || hasActive) {
                if (attribType === 'rozsah') {
                  return (
                    <CategoryFilter
                      key={key}
                      name={attribName}
                      type={FilterType.RANGE}
                      filterData={{
                        activeMin: prop(
                          rangeActive,
                          'min',
                          prop(attrib, 'attrib_data.min', 0),
                        ),
                        activeMax: prop(
                          rangeActive,
                          'max',
                          prop(attrib, 'attrib_data.max', 0),
                        ),
                        min: +prop(attrib, 'attrib_data.min'),
                        max: +prop(attrib, 'attrib_data.max'),
                        attribId: prop(attrib, 'attrib_data.attrib_id'),
                      }}
                      handleFilterChange={handleFilterChange}
                      activeValues={rangeActiveValues}
                      initialIsOpen={rangeActive}
                      unit={attribUnit}
                    />
                  );
                }

                return (
                  <CategoryFilterDesktop
                    key={key}
                    name={attribName}
                    type={FilterType.OPTIONS}
                    filterData={{
                      values: prop(attrib, 'values'),
                      attribId: key,
                    }}
                    handleFilterChange={handleFilterChange}
                    activeValues={activeValues}
                    initialIsOpen={
                      hasActive || INITIALLY_OPEN_ATTRIBS.includes(attribName)
                    }
                  />
                );
              }
            }
            return null;
          })}
      </>
    );
  };

  const renderLeftSide = () => {
    return (
      <LeftWrapper>
        <CategoryNameBagde>{categoryName}</CategoryNameBagde>
        {renderCustomAttribs()}
        {renderCompanyInfo()}
      </LeftWrapper>
    );
  };

  const renderCompanyInfo = () => {
    let { output } = getCompanyInfoData(homeCompanyInfo);

    return (
      <CompanyInfoWrapper>
        <CompanyInfoElement>
          <StyledSvgIcon
            width={26}
            height={26}
            viewBox={'0 0 52 52'}
            marginRight={16}
            icon={IconType.ball}
          />
          {output[0]}
        </CompanyInfoElement>
        <CompanyInfoElement>
          <StyledSvgIcon
            width={26}
            height={26}
            viewBox={'0 0 52 52'}
            marginRight={16}
            icon={IconType.calendar}
          />
          {output[1]}
        </CompanyInfoElement>
        <CompanyInfoElement>
          <StyledSvgIcon
            width={26}
            height={26}
            viewBox={'0 0 52 52'}
            marginRight={16}
            icon={IconType.heart}
          />
          {output[2]}
        </CompanyInfoElement>
      </CompanyInfoWrapper>
    );
  };

  const renderCategoriesFilter = () => {
    return (
      <MobileCategoryFilter>
        {categoryLinks && categoryLinks.length > 0 ? (
          <LinksFilter
            isOpen={true}
            isMobile={true}
            filterData={{ links: categoryLinks }}
          />
        ) : (
          <NoItemsWrapper
            style={{ marginLeft: '0' }}
            height={100}
            text={__('Nenašli sa žiadne ďalšie podkategórie')}
          />
        )}
      </MobileCategoryFilter>
    );
  };

  const renderFilterCheckboxes = () => {
    return (
      <CategoryFilterCheckboxes
        filters={prop(urlAttribsObj, 'otherAttribs')}
        handleCheckboxChange={handleCheckboxChange}
        isB2B={user && user.b2b}
        label={'Len skladom'}
      />
    );
  };

  const renderOtherFilters = () => {
    return (
      <MobileCategoryFilter>
        <MobileCheckboxesWrapper>
          <CategoryFilter
            name={''}
            type={FilterType.RANGE}
            initialIsOpen={true}
            isMobile={true}
            filterData={{
              min: minPrice,
              max: maxPrice,
              activeMin: prop(urlAttribs, 'min'),
              activeMax: prop(urlAttribs, 'max'),
            }}
            handleFilterChange={handleFilterChange}
            unit={'€'}
          />
          {renderFilterCheckboxes()}
        </MobileCheckboxesWrapper>
        {renderCustomAttribs()}
      </MobileCategoryFilter>
    );
  };

  const renderRightSide = () => {
    if (isFetching || !attribs) {
      return;
    }

    const sort = prop(urlAttribs, 'sort');
    const sortDir = prop(urlAttribs, 'sortDir');

    const sortValue = sort && sortDir ? `${sort}_${sortDir}` : 'none';
    const activeAttribs = JSON.parse(
      JSON.stringify(prop(urlAttribsObj, 'attribs', {})),
    );

    Object.keys(activeAttribs).map(key => {
      const activeValues = activeAttribs[key].values;
      // const valuesObj = arrayToObject(attribs[key].values, 'attrib_value');
      const valuesObj = attribs[key].values;
      const newValues: any[] = [];
      activeValues.map(value => {
        newValues.push({
          valueId: value,
          name: prop(valuesObj, `${value}.info.attrib_value`, value),
          attribName: prop(attribs, `${key}.attrib_data.attrib_name`),
        });
      });
      activeAttribs[key].values = newValues;
    });

    const topLevelParentCategory =
      parentCategories && parentCategories.length > 0
        ? parentCategories[0].image
        : !parentCategoryId && currentCategoryImage
        ? currentCategoryImage
        : null;

    const placeholderImgSrc = topLevelParentCategory
      ? getCategoryImagePath(
          topLevelParentCategory,
          {
            width: 56,
            height: 56,
          },
          false,
          false,
          false,
        )
      : '/images/zlatapriadka/placeholder.png';
    const renderSubcategories = (): JSX.Element => {
      return (
        <CategoryCardsContainer
          offset={filteredCategories.length === 0 ? 0 : `16px`}
        >
          {filteredCategories.map((c, index) => (
            <CategoryCard
              categoryName={c.category_name}
              key={index}
              type={CategoryCardType.CLASSIC}
              name={c.category_name}
              url={resolveCategoryShortUrl(c.category_id, c.url)}
              img={
                c.image && c.image !== 'catalog/'
                  ? getCategoryImagePath(
                      c.image,
                      {
                        width: 56,
                        height: 56,
                      },
                      false,
                      false,
                      false,
                    )
                  : placeholderImgSrc
              }
            />
          ))}
        </CategoryCardsContainer>
      );
    };

    const toggleFavourite = async (productId, newVal = null) => {
      if (user) {
        if (!newVal) {
          await dispatch(removeItemFromFavourite(user.id!, productId));
        } else {
          await dispatch(addItemToFavourite(user.id!, productId));
        }

        dispatch(loadFavouriteProducts(user.id?.toString() as string, true));
      } else {
        await dispatch(push(__r('routes:moj-ucet', '/moj-ucet')));
      }
    };

    return (
      <RightWrapper>
        {renderHeader(products, searchTerm)}
        {isFetching ? (
          <LoaderWrapper height={500} />
        ) : !productsArray ? null : (
          <>
            {filteredCategories &&
              filteredCategories.length > 0 &&
              renderSubcategories()}
            <SortWrapper isOffsetTop={filteredCategories.length > 0}>
              <CategoryTopFilters
                handleChange={handleSortChange}
                sortValue={sortValue}
              >
                <CategoryFilter
                  name={''}
                  type={FilterType.RANGE}
                  initialIsOpen={true}
                  isMobile={false}
                  filterData={{
                    min: minPrice,
                    max: maxPrice,
                    activeMin: prop(urlAttribs, 'min'),
                    activeMax: prop(urlAttribs, 'max'),
                  }}
                  handleFilterChange={handleFilterChange}
                  unit={'€'}
                />
                <DesktopCheckboxesWrapper>
                  {renderFilterCheckboxes()}
                </DesktopCheckboxesWrapper>
              </CategoryTopFilters>
            </SortWrapper>
            {activeAttribs && Object.keys(activeAttribs).length > 0 && (
              <DesktopActiveFiltersWrapper>
                <ActiveFilters
                  resetUrl={catUrl}
                  activeAttribs={activeAttribs}
                  onClickAttrib={handleFilterChange}
                />
              </DesktopActiveFiltersWrapper>
            )}
            <MobileFiltersWrapper>
              <MobileCategoryFilterButtons
                onClick={toggleCategoryFilterMenu}
                text={__('Ponuka tovaru')}
                iconType={'CATEGORY'}
              />
              <MobileCategoryFilterButtons
                onClick={toggleFilterMenu}
                text={__('Filter')}
                iconType={'FILTER'}
              />
            </MobileFiltersWrapper>
            {activeAttribs && Object.keys(activeAttribs).length > 0 && (
              <MobileActiveFiltersWrapper>
                <ActiveFilters
                  resetUrl={catUrl}
                  activeAttribs={activeAttribs}
                  onClickAttrib={handleFilterChange}
                />
              </MobileActiveFiltersWrapper>
            )}
            <ListWrapper>
              <ProductList
                isFetching={isFetching}
                products={productsArray}
                visible={true}
                productsInRow={3}
                isElasticCategory={true}
                toggleFavourite={toggleFavourite}
                favouriteProducts={favouriteProducts}
              />
            </ListWrapper>
            {/* {renderMoreItemsButton()} */}
            <Pagination
              query={`${catUrl}${`${query}` ? query : '?'}`}
              totalItems={total}
              limit={limit}
              offset={offset}
            />
          </>
        )}
      </RightWrapper>
    );
  };

  return (
    <>
      <Wrapper className="container container--wide">
        <MetaTags
          tags={{ title: `${__('Vyhľadávanie výrazu')} "${searchTerm}"` }}
        />
        <BreadCrumb />
        <ContentWrapper>
          {renderLeftSide()}
          {renderRightSide()}
        </ContentWrapper>
      </Wrapper>
      {isCategoryFilterVisible && (
        <>
          <MobileNavMenu closeMenu={toggleCategoryFilterMenu}>
            <MobileCategoryFilterMenu
              title={__('Ponuka tovaru')}
              handleClose={toggleCategoryFilterMenu}
            >
              {renderCategoriesFilter()}
            </MobileCategoryFilterMenu>
          </MobileNavMenu>
          <Dimmer height={100} />
        </>
      )}
      {isFilterMenuVisible && (
        <>
          <MobileNavMenu closeMenu={toggleFilterMenu}>
            <MobileCategoryFilterMenu
              title={__('Filter')}
              handleClose={toggleFilterMenu}
            >
              {renderOtherFilters()}
            </MobileCategoryFilterMenu>
          </MobileNavMenu>
          <Dimmer height={100} />
        </>
      )}
    </>
  );
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  margin-bottom: ${rem(56)} !important;

  /* margin-top: ${rem(32)}; */
  padding-top: ${rem(24)};
  padding-left: ${rem(24)};
  padding-right: ${rem(24)};
  flex-direction: column;

  ${({ theme }) => theme.mediab.l1050`
      padding: ${rem(16)} ${rem(16)} 0;
  `}
`;

const MobileCategoryFilter = styled(FlexCol)`
  width: 100%;
`;

const RightWrapper = styled(FlexCol)`
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
    max-width: 100%;
    width: 100%;
  `}
`;

const ContentWrapper = styled(FlexRow)``;

export const CategoryCardsContainer = styled.div<{ offset: number }>`
  padding-bottom: ${props => props.offset};
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, minmax(328px, 1fr));
  grid-gap: ${rem(32)};
  grid-row-gap: ${rem(16)};

  ${({ theme }) => theme.mediab.l1400`
    grid-gap: ${rem(16)};
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  `}

  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}

  ${({ theme }) => theme.mediab.m580`
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  `}

  ${({ theme }) => theme.mediab.s450`
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    display: none;
  `}
`;

const ListWrapper = styled.div`
  ${({ theme }) => theme.mediab.l925`

  `}
`;

const SortWrapper = styled(FlexRowCenter)<{ isOffsetTop: boolean }>`
  margin-top: ${({ isOffsetTop }) => (isOffsetTop ? `${rem(40)}` : 0)};
  margin-bottom: ${rem(32)};
  padding: ${rem(24)};
  justify-content: space-between;
  background: ${({ theme }) => rgba(theme.colors.primary, 0.12)};
  border-radius: ${rem(8)};

  ${({ theme }) => theme.mediab.l925`
    margin-top: ${rem(0)};
    padding-bottom: ${rem(24)};
    margin-bottom: ${rem(0)};
    border-bottom: none;
    background: transparent;
    padding-left: 0;
    padding-right: 0;
  `}
`;

const MobileFiltersWrapper = styled(FlexRowCenterVertical)`
  display: none;
  justify-content: space-between;
  margin: 0 -${rem(16)};
  padding-bottom: ${rem(16)};
  position: relative;
  z-index: 1;
  border-bottom: ${rem(2)} solid #eeeeee;
  box-shadow: 0 ${rem(-16)} ${rem(1)} #fff, 0 ${rem(2)} ${rem(32)} #0000000a;

  ${({ theme }) => theme.mediab.l925`
      display: flex;
  `};
`;

const ActiveFiltersWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l1050`
      margin-top: ${rem(32)};
      margin-bottom: ${rem(32)};
  `}
`;

const DesktopCheckboxesWrapper = styled.div`
  margin-left: auto;
  margin-right: ${rem(2)};

  ${({ theme }) => theme.mediab.l925`
   display: none;
  `}
`;

export const ChildrenCategoryCards = styled.div<{ offset: number }>`
  padding-bottom: ${props => props.offset};
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(328px, 1fr));
  border-bottom: ${({ theme }) => theme.borders.productList};
  grid-gap: ${rem(32)};
  grid-row-gap: ${rem(16)};

  ${({ theme }) => theme.mediab.l1150`
    grid-gap: ${rem(16)};
  `}

  ${({ theme }) => theme.mediab.s450`
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    display: none;
  `}

  /* ${({ theme }) => theme.mediab.s450`
   display: none;
  `} */
`;

const LeftWrapper = styled.div`
  flex: 0 0 ${rem(361)};
  padding-right: ${rem(33)};
  padding-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l1400`
    padding-right: ${rem(24)};
  `}

  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const buttonLikeCss = css`
  font-size: ${rem(16)};
  font-weight: 600;
  line-height: ${rem(19)};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(4)};
  background: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.font.primary};
  margin: 0 auto;
`;

const CategoryNameBagde = styled.p`
  ${buttonLikeCss};
  padding: ${rem(18)} ${rem(24)} ${rem(19)};
  margin-bottom: ${rem(8)};
`;

const CompanyInfoWrapper = styled.aside`
  margin-top: ${rem(56)};
  padding: 0 ${rem(24)};
`;

const CompanyInfoElement = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: ${rem(14)};
  font-weight: 500;
  line-height: ${rem(21)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  margin-top: 0;
  margin-bottom: ${rem(32)};
`;

const StyledSvgIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.primary};
`;

const StyledButton = styled.button`
  ${buttonLikeCss};
  padding: ${rem(14)} ${rem(16)} ${rem(15)};
  border: none;
  box-shadow: none;
  margin-top: ${rem(56)};
  max-width: ${rem(288)};
  width: 100%;
  cursor: pointer;
`;

const DesktopActiveFiltersWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.l1050`
      margin-bottom: ${rem(32)};
  `}

  ${({ theme }) => theme.mediab.m580`
    display: none;
  `}
`;

const MobileActiveFiltersWrapper = styled(FlexRowCenterVertical)`
  display: none;
  margin-top: ${rem(24)};

  ${({ theme }) => theme.mediab.m580`
    display: flex;
  `}
`;

const MobileCheckboxesWrapper = styled.div`
  border-bottom: ${rem(2)} solid #eeeeee;
  padding: ${rem(32)} 0;
`;

export default withRouter(SearchResults);
