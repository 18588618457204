const namespace = 'Product';

export const REQUEST_PRODUCT_DETAIL = `${namespace}/REQUEST_PRODUCT_DETAIL`;
export const RECEIVE_PRODUCT_DETAIL_SUCCESS = `${namespace}/RECEIVE_PRODUCT_DETAIL_SUCCESS`;
export const RECEIVE_PRODUCT_DETAIL_ERROR = `${namespace}/RECEIVE_PRODUCT_DETAIL_ERROR`;

export const REQUEST_PRODUCT_RELATED_DETAIL = `${namespace}/REQUEST_PRODUCT_RELATED_DETAIL`;
export const RECEIVE_PRODUCT_RELATED_DETAIL_SUCCESS = `${namespace}/RECEIVE_PRODUCT_RELATED_DETAIL_SUCCESS`;
export const RECEIVE_PRODUCT_DETAIL_RELATED_ERROR = `${namespace}/RECEIVE_PRODUCT_DETAIL_RELATED_ERROR`;

export const REQUEST_PRODUCT_ACCESSORY_DETAIL = `${namespace}/REQUEST_PRODUCT_ACCESSORY_DETAIL`;
export const RECEIVE_PRODUCT_ACCESSORY_DETAIL_SUCCESS = `${namespace}/RECEIVE_PRODUCT_ACCESSORY_DETAIL_SUCCESS`;
export const RECEIVE_PRODUCT_DETAIL_ACCESSORY_ERROR = `${namespace}/RECEIVE_PRODUCT_DETAIL_ACCESSORY_ERROR`;
