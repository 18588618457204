import { createSelector } from 'reselect';
import { prop } from '../../utilities';

const productDetailDomainSelector = state => state.productDetail;

export const productDataSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'productDetail', ''),
);

export const productDetailIsFetchingSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const productDetailErrorSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'error'),
);

export const productAlternativesSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'connections.alternatives.products', []),
);

export const productAlternativesIsFetchingSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'connections.alternatives.isFetching', false),
);

export const productAccessoriesSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'connections.accessories.products', []),
);

export const productAccessoriesIsFetchingSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'connections.accessories.isFetching', false),
);
