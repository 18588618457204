import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexCol,
  TextP,
  // ZlataPriadkaNavigation as Navigation,
  ZlataPriadkaSvgIcon as SvgIcon,
  ZlataPriadkaIconType as IconType,
  ZlataPriadkaHeaderLogo as HeaderLogo,
  FlexColCenter,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { Link } from 'react-router';
import { __, __r } from 'react-i18n';
import HeaderProfile from './HeaderProfile';
import { getImagePath } from '../../utilities/product';
import { resolveCategoryShortUrl } from 'eshop-defaults/lib/utilities/selectors';
import { css } from 'styled-components';
import { getCategoryImagePath } from '../../utilities/category';

export interface Props {
  items: ThenArg<typeof API.loadTree>;
  closeMenu: () => void;
  user: any;
  location: any;
  dispatch: any;
  isCategoriesInMenuShown?: boolean;
}

function MobileNavigation({
  items,
  closeMenu,
  user,
  location,
  dispatch,
  isCategoriesInMenuShown = false,
}: Props) {
  const [isMainContentShown, setIsMainContentShown] = React.useState(true);

  const title = 'Ponuka tovaru';
  const logoSrc = '/images/zlatapriadka/zlatapriadka_logo.svg';

  const showCategories = (e: any): void => {
    e.preventDefault();
    setIsMainContentShown(false);
  };

  const showMainContent = (e: any): void => {
    e.preventDefault();
    setIsMainContentShown(true);
  };

  React.useEffect(() => {
    if (isCategoriesInMenuShown) {
      setIsMainContentShown(false);
    }
  }, [isCategoriesInMenuShown]);

  const renderMenuDefaultLinks = (): JSX.Element => {
    return (
      <ListWrapper>
        <ListItem onClick={showCategories}>
          <MenuLink>
            <SvgIcon
              icon={IconType.listMenu}
              alt="Zobraziť kategórie"
              cursor={'pointer'}
              viewBox="0 0 24 24"
              marginRight={16}
              width={16}
              height={16}
            />
            {__('Ponuka tovaru')}
            <ArrowSvgIcon
              icon={IconType.chevronRightSmall}
              alt="Zobraziť kategórie"
              cursor={'pointer'}
              viewBox="0 0 16 16"
              width={16}
              height={16}
            />
          </MenuLink>
        </ListItem>
        <ListItem>
          <FavouriteMenuLink
            onClick={closeMenu}
            to={__r('routes:moj-ucet/oblubene', '/moj-ucet/oblubene')}
          >
            <SvgIcon
              icon={IconType.favourite}
              alt="Zobraziť kategórie"
              cursor={'pointer'}
              viewBox="0 0 24 24"
              marginRight={16}
              width={16}
              height={16}
            />
            Obľúbené
          </FavouriteMenuLink>
        </ListItem>
        <ListItem onClick={closeMenu}>
          <HeaderProfile user={user} dispatch={dispatch} />
        </ListItem>
      </ListWrapper>
    );
  };

  const renderMenuLinks = (): JSX.Element => {
    return (
      <ListWrapper>
        <ListItem>
          <HeaderMenuLink
            onClick={closeMenu}
            to={__r('routes:kontakt', '/kontakt')}
          >
            Kontakt
          </HeaderMenuLink>
        </ListItem>
        <ListItem>
          <HeaderMenuLink onClick={closeMenu} to={__r('routes:blog', '/blog')}>
            Blog
          </HeaderMenuLink>
        </ListItem>
      </ListWrapper>
    );
  };

  return (
    <Wrapper>
      {isMainContentShown && (
        <MainContent>
          <IconTitleWrapper isMainContentShown={isMainContentShown}>
            <SvgIcon
              icon={IconType.x}
              alt="Zavrieť menu"
              viewBox={'0 0 24 24'}
              width={24}
              height={24}
              cursor={'pointer'}
              onClick={closeMenu}
              marginRight={16}
            />
            <HeaderLogo logo={logoSrc} />
          </IconTitleWrapper>
          <MenuItemsWrapper>{renderMenuDefaultLinks()}</MenuItemsWrapper>
          <MenuItemsWrapper>{renderMenuLinks()}</MenuItemsWrapper>
        </MainContent>
      )}

      {!isMainContentShown && (
        <CategoriesContent>
          <IconTitleWrapper>
            <SvgIcon
              icon={IconType.chevronLeft}
              alt="Zavrieť menu"
              viewBox={'0 0 24 24'}
              width={24}
              height={24}
              cursor={'pointer'}
              onClick={showMainContent}
              marginRight={16}
            />
            <Title>{title}</Title>
          </IconTitleWrapper>

          <NavigationWrapper>
            {renderCategories(items, location, closeMenu)}
          </NavigationWrapper>
        </CategoriesContent>
      )}
    </Wrapper>
  );
}

const renderCategories = (items, location, closeMenu) => {
  if (!items || items.length === 0) return null;
  const placeholderImgSrc = '/images/zlatapriadka/placeholder.png';

  return items.map(item => {
    const normalizedCategoryName =
      item.category_name && item.category_name.trim().toLowerCase();
    const isSpecialCategory =
      normalizedCategoryName === 'výpredaj' ||
      normalizedCategoryName === 'darčeková poukážka';

    return (
      <CategoryLink
        onClick={closeMenu}
        title={item.category_name}
        to={resolveCategoryShortUrl(item.category_id, item.url)}
      >
        {!isSpecialCategory ? (
          <Image
            alt={`Obrázok kategórie ${item.category_name}`}
            src={
              item.image
                ? getCategoryImagePath(
                    item.image,
                    {
                      width: 56,
                      height: 56,
                    },
                    false,
                    false,
                    false,
                  )
                : placeholderImgSrc
            }
          />
        ) : (
          <SpecialCategoryIcon type={normalizedCategoryName}>
            <SvgIcon
              width={40}
              height={40}
              viewBox="0 0 40 40"
              icon={
                normalizedCategoryName === 'výpredaj'
                  ? IconType.sale
                  : IconType.gift
              }
              fill={
                normalizedCategoryName === 'výpredaj' ? '#D2A4B0' : '#A5BBA2'
              }
              alt={
                normalizedCategoryName === 'výpredaj'
                  ? __('Výpredaj')
                  : __('Darčeková poukážka')
              }
              title={
                normalizedCategoryName === 'výpredaj'
                  ? __('Výpredaj')
                  : __('Darčeková poukážka')
              }
            />
          </SpecialCategoryIcon>
        )}
        <Title>{item.category_name}</Title>
      </CategoryLink>
    );
  });
};

const Wrapper = styled.div`
  width: 100%;
`;

const MainContent = styled.div``;

const CategoriesContent = styled.div``;

const NavigationWrapper = styled.div`
  padding: ${rem(16)};
`;

const IconTitleWrapper = styled(FlexRowCenterVertical)<{
  isMainContentShown: boolean;
}>`
  width: 100%;
  // justify-content: ${props =>
    props.isMainContentShown ? `flex-start` : `space-between`};
  justify-content: flex-start;
  padding: 0 ${rem(16)};

  border-bottom: ${rem(2)} solid #eeeeee;

  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }

  min-height: ${rem(74)};
`;

const MenuItemsWrapper = styled(FlexCol)`
  padding-top: ${rem(8)};
  padding-bottom: ${rem(8)};
  border-bottom: ${rem(2)} solid #eeeeee;

  &:last-of-type {
    border-bottom: 0;
  }
`;

const titleLikeCss = css`
  font-weight: 500;
  font-size: ${rem(14)};
  line-height: ${rem(21)};
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.colors.black};
`;

const Title = styled(TextP)`
  ${titleLikeCss};
`;

const ListWrapper = styled.ul`
  padding-left: 0;
  margin: 0;
`;

const ListItem = styled.li`
  list-style: none;
  margin: 0;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const MenuLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 500;
  padding: ${rem(14)} ${rem(16)};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const FavouriteMenuLink = styled(MenuLink)`
  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const HeaderMenuLink = styled(MenuLink)`
  font-weight: 300;
`;

const ArrowSvgIcon = styled(SvgIcon)`
  margin-left: auto;
`;

const CategoryLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  margin-bottom: ${rem(24)};
`;

const Image = styled.img`
  width: ${rem(32)};
  height: ${rem(32)};
  margin-right: ${rem(12)};
  flex: 0 0 ${rem(32)};
`;

const SpecialCategoryIcon = styled(FlexColCenter)`
  width: ${rem(32)};
  height: ${rem(32)};
  min-width: ${rem(32)};
  margin-right: ${rem(12)};
  border-radius: 50%;
`;

export { MobileNavigation };
