import * as actionTypes from './constants';
import { langSelector } from '../Category/selectors';
import { prop } from 'eshop-defaults';
import { setCurrentTheme } from '../App/actions';
import { userSelector } from '../App/selectors';
import { AppThunk } from '../../rootReducer';

const requestProductDetail = () => ({
  type: actionTypes.REQUEST_PRODUCT_DETAIL,
});

const receiveProductDetailSuccess = productDetail => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_SUCCESS,
  payload: {
    productDetail,
  },
});

const receiveProductDetailError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetail = (id): AppThunk => {
  return async (dispatch, getState, API) => {
    // if (!productId || productId.toString() !== id.toString()) {
    try {
      dispatch(requestProductDetail());

      const lang = langSelector(getState());
      const productDetail = await API.loadProduct(
        id,
        {
          withParentCategories: '1',
          withProductPackages: '1',
          withCustomOrderNr: '1',
          goodsWithStores: '1',
          // withFreeDelivery: '1',
          // withProductVariants: '1',
        },
        { xAcceptLanguage: lang },
      );

      dispatch(receiveProductDetailSuccess(productDetail));
      const productCategories = prop(productDetail, 'parent_categories');
      if (productCategories) {
        const uniqueCatIds: string[] = productCategories.map(
          p => p.category_number,
        );
      }
    } catch (e) {
      dispatch(receiveProductDetailError(e));
    }
    // }
  };
};

const requestProductDetailRelated = () => ({
  type: actionTypes.REQUEST_PRODUCT_RELATED_DETAIL,
});

const receiveProductDetailRelatedSuccess = connections => ({
  type: actionTypes.RECEIVE_PRODUCT_RELATED_DETAIL_SUCCESS,
  payload: {
    connections,
  },
});

const receiveProductDetailRelatedError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_RELATED_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetailRelated = id => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductDetailRelated());

      const lang = langSelector(getState());
      const connections = await API.loadProductConnections(
        id,
        {
          // TODO check type
          connectionType: 'COLOR',
          limit: 4,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveProductDetailRelatedSuccess(connections));
    } catch (e) {
      dispatch(receiveProductDetailRelatedError(e));
    }
  };
};

const requestProductDetailAccessory = () => ({
  type: actionTypes.REQUEST_PRODUCT_ACCESSORY_DETAIL,
});

const receiveProductDetailAccessorySuccess = connections => ({
  type: actionTypes.RECEIVE_PRODUCT_ACCESSORY_DETAIL_SUCCESS,
  payload: {
    connections,
  },
});

const receiveProductDetailAccessoryError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_ACCESSORY_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetailAccessory = id => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductDetailAccessory());

      const lang = langSelector(getState());
      const connections = await API.loadProductConnections(
        id,
        {
          connectionType: 'ACCESSORY',
          limit: 12,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveProductDetailAccessorySuccess(connections));
    } catch (e) {
      dispatch(receiveProductDetailAccessoryError(e));
    }
  };
};

export const setCustomerGoodOrderNr = (goodId: number, value: string) => {
  return async (dispatch, getState, API) => {
    try {
      const user = userSelector(getState());
      // console.log({ user, goodId, value });
      await API.updateCustomOrderNumber(
        user.id,
        goodId,
        {},
        { orderNr: value },
      );
    } catch (e) {
      console.log({ e });
    }
  };
};
