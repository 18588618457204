import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import {
  loadHomeNewProducts,
  loadHomeSaleProducts,
  loadHomeBanner,
  loadHomeBenefits,
  loadNewArticles,
  loadFavouriteProducts,
} from './actions';
import { langSelector, titleSelector } from '../Category/selectors';
import {
  saleProductsSelector,
  newProductsSelector,
  adBannerSelector,
  firstHomeBenefitsSelector,
  newArticlesSelector,
  favouriteProductsSelector,
} from './selectors';
import Home from '../../components/Home/Home';
import {
  productCategoriesSelector,
  productCategoriesIsFetchingSelector,
  homeDimmerSelector,
  firstCategoryIdSelector,
} from '../Header/selectors';
import { loadCategoriesTree } from '../Header/actions';
import { cartDataSelector } from '../Cart/cartSlice';
import {
  basicContactInfoSelector,
  secondContactInfoSelector,
} from '../Contact/contactSlice';
import { prop } from '../../utilities';

export const MainWrapper = styled.div`
  padding: ${rem(30)} 0 0;
  width: 100%;

  ${({ theme }) => theme.mediab.m810`
     padding-top: ${rem(16)};
  `}
`;

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

interface Props {
  parentProps: {};
  dispatch: any;
  isError: boolean;
  loaded: any;
  defaultTitle: string;
  lang: string;
  modal: any;
  adBanner: any;
  router: any;
  location: any;
  newProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  saleProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  productCategories: ThenArg<typeof API.loadTree>;
  productCategoriesIsFetching: boolean;
  dimmerVisible: boolean;
  firstCategoryId: number;
  user: any;
  cart: any;
  basicData: ThenArg<typeof API.loadSitemap>;
  secondData: ThenArg<typeof API.loadSitemap>;
  firstBenefits: any[];
  isCategoriesInMenuShown: boolean;
  newArticles: {
    articles: ThenArg<typeof API.loadArticles>;
    total: number;
  };

  favouriteProducts: any;
}

class HomeContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, user } = props;
    try {
      const firstCategoryId = await dispatch(loadCategoriesTree());
      await Promise.all[
        (user && (await dispatch(loadFavouriteProducts(user.id))),
        await dispatch(loadHomeNewProducts(firstCategoryId)),
        await dispatch(loadHomeSaleProducts(firstCategoryId)),
        await dispatch(loadHomeBanner()),
        await dispatch(loadNewArticles()),
        await dispatch(loadHomeBenefits()))
      ];

      return {
        props,
        isLoaded: true,
      };
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
        isLoaded: true,
      };
    }
  }

  public render() {
    const {
      productCategories,
      productCategoriesIsFetching,
      saleProducts,
      newProducts,
      dimmerVisible,
      dispatch,
      firstCategoryId,
      user,
      cart,
      adBanner,
      basicData,
      secondData,
      firstBenefits,
      newArticles,

      favouriteProducts,
    } = this.props;

    const products = prop(favouriteProducts, 'productsData.products', []);
    const favProductsIds: number[] = [];
    products.map(p => favProductsIds.push(p.product_id));

    return (
      <Home
        categories={productCategories}
        productCategoriesIsFetching={productCategoriesIsFetching}
        newProducts={newProducts}
        saleProducts={saleProducts}
        dimmerVisible={dimmerVisible}
        dispatch={dispatch}
        firstCategoryId={firstCategoryId}
        user={user}
        cart={cart}
        adBanner={adBanner}
        basicData={basicData}
        secondData={secondData}
        homeCompanyInfo={firstBenefits}
        newArticles={newArticles}
        favouriteProducts={favProductsIds}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    newProducts: newProductsSelector(state),
    saleProducts: saleProductsSelector(state),
    productCategories: productCategoriesSelector(state),
    productCategoriesIsFetching: productCategoriesIsFetchingSelector(state),
    dimmerVisible: homeDimmerSelector(state),
    firstCategoryId: firstCategoryIdSelector(state),
    user: state.auth.user,
    cart: cartDataSelector(state),
    adBanner: adBannerSelector(state),
    basicData: basicContactInfoSelector(state),
    secondData: secondContactInfoSelector(state),
    firstBenefits: firstHomeBenefitsSelector(state),
    newArticles: newArticlesSelector(state),

    favouriteProducts: favouriteProductsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'HomeContainer' })(HomeContainer),
);
