import * as React from 'react';
import { __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  ZlataPriadkaSidebar as Sidebar,
  FlexRow,
  FlexCol,
} from 'eshop-defaults';
import { WithRouterProps } from 'react-router';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

interface Props {
  user: any;
  dispatch: any;
  children: any;
}

class ContactContainer extends React.Component<Props & WithRouterProps> {
  public render() {
    const { children } = this.props;
    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags tags={{ title: 'Kontakt' }} />
          {this.renderSidebar()}
          <ContentWrapper>{children}</ContentWrapper>
        </Wrapper>
      </>
    );
  }

  public renderSidebar = () => {
    const {
      location: { pathname },
    } = this.props;
    const items = [
      {
        name: 'Kontakt',
        url: __r('routes:kontakt', '/kontakt'),
        active:
          pathname.includes(__r('routes:kontakt', '/kontakt')) &&
          !pathname.includes(
            __r('routes:kontakt/kontaktne-osoby', '/kontakt/kontaktne-osoby'),
          ),
      },
      // {
      //   name: 'Kontaktné osoby',
      //   url: __r('routes:kontakt/kontaktne-osoby', '/kontakt/kontaktne-osoby'),
      //   active: pathname.includes(
      //     __r('routes:kontakt/kontaktne-osoby', '/kontakt/kontaktne-osoby'),
      //   ),
      // },
    ];

    return <Sidebar withoutLogout={true} items={items} />;
  };
}

const ContentWrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
  `}
`;

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding: ${rem(56)} ${rem(24)};
  justify-content: space-between;

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    padding: 0 ${rem(16)} ${rem(32)};
  `}
`;

export const LogoutButton = styled.button`
  text-decoration: none;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  transition: filter 0.2s ease, background-color 0.2s ease;

  &:disabled {
    cursor: not-allowed;

    &:hover {
      filter: brightness(100%);
    }
  }

  &:hover {
    filter: brightness(90%);
  }

  margin: ${rem(56)} auto;
  flex: 0 1 auto;
  border: none;
  border-radius: 0 ${rem(2)} ${rem(2)} 0;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.secondary};
  height: ${rem(48)};
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  max-width: ${rem(320)};

  padding: ${rem(15)} ${rem(32)};
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Contact' })(ContactContainer),
);
