import { ZlataPriadkaAddToCartModal as AddToCartModal } from 'eshop-defaults';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  wasAddedToCartModalSelector,
  wasAddedToCartModalProductSelector,
  setWasAddedToCartModalVisibility,
  wasAddedToCartModalCountSelector,
  addItemToCartFromModal,
} from './cartSlice';
import { __ } from 'react-i18n';
import { WithRouterProps, withRouter } from 'react-router';

interface Props {
  wasAddedToCartModal: boolean;
  wasAddedToCartModalProduct: any;
  wasAddedToCartModalCount: number;
  dispatch: any;
}

function AddedToCartModal({
  wasAddedToCartModal,
  wasAddedToCartModalProduct,
  wasAddedToCartModalCount,
  dispatch,
  router,
}: Props & WithRouterProps) {
  const handleToCartButtonClick = () => {
    dispatch(setWasAddedToCartModalVisibility());
    router.push('/kosik');
  };

  return (
    <>
      {wasAddedToCartModal && wasAddedToCartModalProduct && (
        <AddToCartModal
          redirectToCart={handleToCartButtonClick}
          initCount={wasAddedToCartModalCount}
          isActive={true}
          product={wasAddedToCartModalProduct}
          handleClose={() => dispatch(setWasAddedToCartModalVisibility())}
          addToCart={count => dispatch(addItemToCartFromModal(count))}
        />
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    wasAddedToCartModal: wasAddedToCartModalSelector(state),
    wasAddedToCartModalProduct: wasAddedToCartModalProductSelector(state),
    wasAddedToCartModalCount: wasAddedToCartModalCountSelector(state),
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(AddedToCartModal));
