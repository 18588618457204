import { prop } from './index';

export function getImagePath(
  filename,
  { width, height } = { width: 64, height: 80 },
  noSize = false,
  webp = false,
  typeCover = false,
  watermark = false,
) {
  const productName = resolveProductNameFromImagePath(filename);
  if (height === 0) {
    return `${
      process.env.REACT_APP_API_BASE_URL
    }/image-product-name/${productName}?query=${filename}&width=${width}&webp=${
      webp ? 1 : 0
    }&watermark=${watermark ? 1 : 0}${typeCover ? '&type=cover' : ''}`;
  }

  return noSize
    ? `${process.env.REACT_APP_API_BASE_URL}/image?query=${filename}&webp=${
        webp ? 1 : 0
      }&watermark=${watermark ? 1 : 0}${typeCover ? '&type=cover' : ''}`
    : `${
        process.env.REACT_APP_API_BASE_URL
      }/image-product-name/${productName}?query=${filename}&width=${width}&height=${height}&webp=${
        webp ? 1 : 0
      }&watermark=${watermark ? 1 : 0}${typeCover ? '&type=cover' : ''}`;
}

export function getBannerImagePath(
  filename,
  { width, height } = { width: 64, height: 80 },
  noSize = false,
  webp = false,
  typeCover = false,
) {
  return `${
    process.env.REACT_APP_API_BASE_URL
  }/image?query=${filename}&width=${width}&height=${height}&webp=${
    webp ? 1 : 0
  }${typeCover ? '&type=cover' : ''}`;
}

export function getFilePath(filename) {
  return `${process.env.REACT_APP_API_BASE_URL}/file?query=${filename}`;
}

export function getColorFromName(name) {
  switch (name) {
    case 'Červená':
      return 'red';
    case 'Modrá':
      return 'blue';
    case 'Žltá':
      return 'yellow';
    case 'Čierna':
      return 'black';

    case 'Light Cyan':
      return 'lightcyan';
    case 'Red':
      return 'red';
    case 'Blue':
      return 'blue';
    case 'Yellow':
      return 'yellow';
    case 'Black':
      return 'black';

    case 'Cyan':
      return 'cyan';
    case 'Green':
      return 'green';
    case 'Gloss Optimizer':
      return 'grey';
    case 'Grey':
      return 'grey';
    case 'Light Black':
      return '#000000ab';
    case 'Light Light Black':
      return '#00000059';
    case 'Light Magenta':
      return '#E78BE7';

    case 'Orange':
      return 'orange';
    case 'Matte Black':
      return '#141414';
    case 'Magenta':
      return 'magenta';

    case 'Photo Black':
      return '#191616';
    case 'Photo Cyan':
      return '#00ffffb5';
    case 'Photo Grey':
      return '#808080db';
    case 'Photo Magenta':
      return '#fac';

    case 'Vivi Light Magenta':
      return '#F245C9';
    case 'Vivid Magenta':
      return '#F41AC1';

    default:
      return '';
  }
}

export function resolveProductUrl(product) {
  let slug;
  let publish = prop(product, 'publish');
  if (publish && Array.isArray(publish)) {
    publish = publish[0];
  }
  if (publish) {
    slug = publish.product_url;
  }

  const url = prop(product, 'url', '');
  if (url.substr(0, 1) === '/') {
    slug = url;
  }

  slug = `/${prop(product, 'url', '')}`;

  return '/product/' + slug;
}

export function resolveProductName(product) {
  let publish = prop(product, 'publish');
  if (publish && Array.isArray(publish)) {
    publish = publish[0];
    // publish = publish.find(el => {
    //   return el.lang_id === lang;
    // });
  }
  if (publish) {
    return prop(publish, 'product_name', null) || product.name;
  }
  return product.name;
}

export function resolveProductNameFromImagePath(url) {
  const index = url.lastIndexOf('/');
  const productName = url.substring(index + 1);
  return productName;
}

export function resolveShortDescription(product) {
  let publish = prop(product, 'publish');
  if (publish && Array.isArray(publish)) {
    publish = publish[0];
  }

  if (publish && publish.content) {
    const descr = publish.content.json_content.short_description;
    if (typeof descr !== 'object') {
      return descr;
    }
  }
  return product.description;
}

export function resolveAvailabilityName(availability) {
  let publish = prop(availability, 'translations');
  if (publish && Array.isArray(publish)) {
    publish = publish[0];
  }
  if (publish) {
    return prop(publish, 'avail_name');
  }

  return prop(availability, 'avail_uniqid', '');
}

export function resolveAttribValueName(value) {
  if (
    value.translations &&
    Array.isArray(value.translations) &&
    value.translations.length
  ) {
    const valueTranslation = value.translations[0];
    if (valueTranslation) {
      return prop(valueTranslation, 'attrib_value');
    }
  }
  return prop(value, 'attrib_value');
}

export function resolveAttribUrl(value) {
  if (
    value.translations &&
    Array.isArray(value.translations) &&
    value.translations.length
  ) {
    const valueTranslation = value.translations[0];
    if (valueTranslation) {
      return prop(valueTranslation, 'attrib_url');
    }
  }
  return prop(value, 'attrib_url');
}

export function resolveCategoryName(category) {
  // let name;
  // if (
  //   category.content
  // ) {
  //   const content = category.content[0];
  //   if (content) {
  //     name = prop(content, 'json_content.name');
  //   }
  // }
  // if(name){
  //   return name;
  // }
  return prop(category, 'category_name');
}

export function resolveCategoryDescr(category) {
  let name;
  if (category.content) {
    const content = category.content[0];
    if (content) {
      name = prop(content, 'json_content.body');
    }
  }
  if (name) {
    return name;
  }
  prop(category, 'category_descr');
}

export function resolveProductPlu(product) {
  if (product.product_plu) {
    return product.product_plu;
  } else if (product.plu) {
    return product.plu;
  }
  return '';
}

export function resolveProductSymbols(symbolsAttrib) {
  const symbolsString = prop(symbolsAttrib, 'values.0.attrib_value');
  if (symbolsString) {
    return symbolsString.split('|').map(function(item) {
      return item.trim();
    });
  }
  return [];
}

export function resolveProductInfo(attribs) {
  const productInfo: any = [];

  const ihliceAttrib = attribs.find(a => a.attrib_name === 'Ihlice');
  const ihlice = prop(ihliceAttrib, 'values.0.attrib_value');

  const hacikAttrib = attribs.find(a => a.attrib_name === 'Háčik');
  const hacik = prop(hacikAttrib, 'values.0.attrib_value');

  const rozmerAttrib = attribs.find(a => a.attrib_name === 'Rozmer');
  const rozmer = prop(rozmerAttrib, 'values.0.attrib_value');

  const hrubkaVlnyAttrib = attribs.find(a => a.attrib_name === 'Hrúbka vlny');
  const hrubkaVlny = prop(hrubkaVlnyAttrib, 'values.0.attrib_value');

  const navinAttrib = attribs.find(a => a.attrib_name === 'Návin');
  const navin = prop(navinAttrib, 'values.0.attrib_value');

  const gramazAttrib = attribs.find(a => a.attrib_name === 'Gramáž');
  const gramaz = prop(gramazAttrib, 'values.0.attrib_value');

  const zlozenieWPAttrib = attribs.find(a => a.attrib_name === 'Zloženie WP');
  const zlozenieWP = prop(zlozenieWPAttrib, 'values.0.attrib_value');

  const zlozenieAttrib = attribs.find(a => a.attrib_name === 'Zloženie');
  const zlozenie = prop(zlozenieAttrib, 'values.0.attrib_value');

  const sirkaAttrib = attribs.find(a => a.attrib_name === 'Šírka');
  const sirka = prop(sirkaAttrib, 'values.0.attrib_value');

  const vyrobcaWPAttrib = attribs.find(a => a.attrib_name === 'Výrobca WP');
  const vyrobcaWP = prop(vyrobcaWPAttrib, 'values.0.attrib_value');

  navin && productInfo.push({ title: 'Návin', value: navin });
  rozmer && productInfo.push({ title: 'Rozmer', value: rozmer });
  hacik && productInfo.push({ title: 'Háčik', value: hacik });
  ihlice && productInfo.push({ title: 'Ihlice', value: ihlice });
  hrubkaVlny && productInfo.push({ title: 'Hrúbka vlny', value: hrubkaVlny });
  gramaz && productInfo.push({ title: 'Gramáž', value: gramaz });
  if (zlozenieWP) {
    productInfo.push({ title: 'Zloženie', value: zlozenieWP });
  } else if (zlozenie) {
    productInfo.push({ title: 'Zloženie', value: zlozenie });
  }
  sirka && productInfo.push({ title: 'Šírka', value: sirka });
  vyrobcaWP && productInfo.push({ title: 'Výrobca', value: vyrobcaWP });

  return productInfo;
}

export function isWritable<T extends Object>(obj: T, key: keyof T) {
  const desc = Object.getOwnPropertyDescriptor(obj, key) || {};
  return Boolean(desc.writable);
}

export function getAbsoluteUrl(url) {
  let baseUrl = process.env.REACT_APP_BASE_URL;
  if (baseUrl && !baseUrl.startsWith('https://www.')) {
    baseUrl = baseUrl.replace(/^https?:\/\//, 'https://www.');
  }
  if (!url.startsWith('/')) {
    url = '/' + url;
  }
  return `${baseUrl}${url}`;
}

export function getBrandFromProductInfo(productInfo) {
  let brands: any[] = [];
  brands = productInfo.filter(object => object.title === 'Výrobca');
  return brands && brands[0] ? brands[0].value : undefined;
}
