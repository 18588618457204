import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZlataPriadkaTitle as Title,
  ZlataPriadkaInfoText as InfoText,
  Invoices,
  ZlataPriadkaOrders as Orders,
  ZlataPriadkaPagination as Pagination,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';

interface Props {
  invoices: any;
  orders: ThenArg<typeof API.getOrders>;
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
}

function MyOrders({
  // currentId,
  orders,
  invoices,
  // ordersData,
  // handleOffsetChange,
  // handleSelectChange,
  // options,
  isFetchingOrders,
}: Props) {
  // const { limit, offset, total } = ordersData;

  const downloadInvoice = async public_id => {
    await API.getDocumentPdf(public_id, 'faktura');
  };

  return (
    <Wrapper>
      {false && (
        <>
          <StyledTitle marginTop={0} marginBottom={32}>
            {__('Neuhradené faktúry')}
          </StyledTitle>
          <InfoText
            type={'ERROR'}
            info={'Máte 1 neuhradenú faktúru po splatnosti.'}
          />
          <Invoices isFetching={false} invoices={invoices} />
        </>
      )}

      <StyledTitle marginTop={0} marginBottom={32}>
        {__('Moje objednávky')}
      </StyledTitle>
      <Orders
        isFetching={isFetchingOrders}
        orders={orders}
        download={downloadInvoice}
      />
      {/* <Pagination
        totalItems={total}
        limit={limit}
        offset={offset}
        handleOffsetChange={handleOffsetChange}
      /> */}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  /* max-width: ${rem(1048)}; */
  margin-right: 0;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const StyledTitle = styled(Title)`
  font-size: ${rem(24)};
  line-height: ${rem(31)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.l925`
    margin-bottom: ${rem(56)};
  `}
`;

export default MyOrders;
