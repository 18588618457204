import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  MetaTags,
  ZlataPriadkaContactUpper as ContactUpper,
  ZlataPriadkaLoaderWrapper as LoaderWrapper,
} from 'eshop-defaults';
import APIClass, { ThenArg } from '../../services/API';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import {
  SecondaryZemplinTitle,
  StyledZemplinTitle,
} from './ContactPersonsPage';
import { rem } from 'polished';
import styled from 'styled-components';
import {
  fetchContactInfo,
  thirdContactInfoSelector,
  secondContactInfoSelector,
  basicContactInfoSelector,
  contactInfoIsFetchingSelector,
} from './contactSlice';
import {
  getContactInfoBasicData,
  getContactInfoOtherData,
} from '../../utilities';

interface Props {
  user: any;
  basicData: ThenArg<typeof APIClass.loadSitemap>;
  secondData: ThenArg<typeof APIClass.loadSitemap>;
  thirdData: ThenArg<typeof APIClass.loadSitemap>;
  isFetching: boolean;
}

class ContactInfoPageContainer extends React.Component<Props> {
  public static async getInitialProps({ dispatch }) {
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.CONTACT));
      await dispatch(fetchContactInfo());
      return true;
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
      };
    }
  }

  public render() {
    const { basicData, secondData, thirdData, isFetching } = this.props;

    if (isFetching) {
      return <LoaderWrapper height={300} />;
    }

    const {
      address1,
      address2,
      openingHours,
      email,
      phone,
      mobilePhone,
      title,
    } = getContactInfoBasicData(basicData);
    const { ico, dic, icdph } = getContactInfoOtherData(secondData);

    return (
      <>
        <MetaTags tags={{ title: 'Kontakt' }} />
        <StyledZemplinTitle>{__('Kontakt')}</StyledZemplinTitle>
        <ContactUpper
          title={title}
          address1={address1}
          address2={address2}
          phone={mobilePhone}
          email={email}
          ico={ico}
          icdph={icdph}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    basicData: basicContactInfoSelector(state),
    secondData: secondContactInfoSelector(state),
    thirdData: thirdContactInfoSelector(state),
    isFetching: contactInfoIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ContactInfoPageContainer' })(
    ContactInfoPageContainer,
  ),
);
