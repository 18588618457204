import * as React from 'react';
import { connectSsr } from 'ssr-service';
import API, { ThenArg } from '../../services/API';
import { connect } from 'react-redux';
import {
  langSelector,
  contactInfoSelector,
  partialSearchProductsSelector,
  partialSearchProductsIsFetchingSelector,
  partialSearchCategoriesSelector,
  partialSearchCategoriesIsFetchingSelector,
  searchTermSelector,
  partialSearchCategoryProductsSelector,
  isCategoriesInMenuShown,
  partialSearchArticlesSelector,
  partialSearchArticlesIsFetchingSelector,
} from '../App/selectors';
import Header from '../../components/Header/Header';
import { productCategoriesSelector } from './selectors';
import {
  cartDataSelector,
  freeDelRemainingSelector,
  addItemToCart,
} from '../Cart/cartSlice';
import { prop } from 'eshop-defaults';
import { favouriteProductsTotalSelector } from '../Home/selectors';

interface Props {
  user: any;
  lang: string;
  // productCategories: ThenArg<typeof API.loadTree>;
  productCategories: any;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  partialSearchCategoryProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  partialSearchArticles: ThenArg<typeof API.loadArticles>;
  partialSearchArticlesIsFetching: boolean;
  cartData: ThenArg<typeof API.getCart>;
  lastSearchTerm: string;
  freeDel: number | null;
  isCategoriesInMenuShown: boolean;
  favouriteProductsTotal: number;
}

class ContainerHeader extends React.Component<Props> {
  private addToCart = (product: any, count: number) => {
    const cartProduct = {
      ...product,
      product_id: prop(product, 'product.product_id'),
      main_good: {
        good_id: prop(product, 'good.good_id'),
      },
    };
    this.props.dispatch(
      addItemToCart(
        cartProduct,
        prop(product, 'good.good_id'),
        prop(product, 'product.product_id'),
        count,
        false,
        false,
      ),
    );
  };

  public render() {
    const {
      socialUrls,
      lang,
      contact,
      dispatch,
      productCategories,
      partialSearchProducts,
      partialSearchProductsIsFetching,
      partialSearchCategories,
      partialSearchCategoriesIsFetching,
      partialSearchArticles,
      partialSearchArticlesIsFetching,
      user,
      cartData,
      lastSearchTerm,
      partialSearchCategoryProducts,
      freeDel,
      isCategoriesInMenuShown,
      favouriteProductsTotal,
    } = this.props;

    return (
      <Header
        user={user}
        dispatch={dispatch}
        socialUrls={socialUrls}
        lang={lang}
        contact={contact}
        items={productCategories}
        partialSearchCategoryProducts={partialSearchCategoryProducts}
        partialSearchProducts={partialSearchProducts}
        partialSearchProductsIsFetching={partialSearchProductsIsFetching}
        partialSearchCategories={partialSearchCategories}
        partialSearchCategoriesIsFetching={partialSearchCategoriesIsFetching}
        partialSearchArticles={partialSearchArticles}
        partialSearchArticlesIsFetching={partialSearchArticlesIsFetching}
        cartData={cartData}
        lastSearchTerm={lastSearchTerm}
        freeDel={freeDel}
        addToCart={this.addToCart}
        isCategoriesInMenuShown={isCategoriesInMenuShown}
        favouriteProductsTotal={favouriteProductsTotal}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    contact: contactInfoSelector(state),
    socialUrls: state.general.socialUrls,
    productCategories: productCategoriesSelector(state),
    partialSearchCategoryProducts: partialSearchCategoryProductsSelector(state),
    partialSearchProducts: partialSearchProductsSelector(state),
    partialSearchProductsIsFetching: partialSearchProductsIsFetchingSelector(
      state,
    ),
    partialSearchCategories: partialSearchCategoriesSelector(state),
    partialSearchCategoriesIsFetching: partialSearchCategoriesIsFetchingSelector(
      state,
    ),
    partialSearchArticles: partialSearchArticlesSelector(state),
    partialSearchArticlesIsFetching: partialSearchArticlesIsFetchingSelector(
      state,
    ),
    user: state.auth.user,
    cartData: cartDataSelector(state),
    lastSearchTerm: searchTermSelector(state),
    freeDel: freeDelRemainingSelector(state),
    isCategoriesInMenuShown: isCategoriesInMenuShown(state),
    favouriteProductsTotal: favouriteProductsTotalSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ContainerHeader' })(ContainerHeader),
);
