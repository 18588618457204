import * as React from 'react';
import { connect } from 'react-redux';
import { breadCrumbSelector, BreadCrumbPath } from './breadCrumbSlice';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import styled from 'styled-components';
import { rem } from 'polished';

interface Props {
  breadCrumb: BreadCrumbPath;
  withWrapper?: boolean;
}

function BreadCrumbContainer({ breadCrumb, withWrapper }: Props) {
  return withWrapper ? (
    <BreadCrumbWrapper className="container container--wide">
      {' '}
      <BreadCrumb breadCrumb={breadCrumb} />
    </BreadCrumbWrapper>
  ) : (
    <BreadCrumb breadCrumb={breadCrumb} />
  );
}

const BreadCrumbWrapper = styled.div`
  padding: 0 ${rem(24)};
  width: 100%;
`;

const mapStateToProps = state => {
  return {
    breadCrumb: breadCrumbSelector(state),
  };
};

export default connect(mapStateToProps)(BreadCrumbContainer);
