import * as React from 'react';
import { __, __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  ZlataPriadkaFinishedOrder as FinishedOrder,
  ZlataPriadkaErrorFinishedOrder as ErrorFinishedOrder,
  FlexCol,
  ZlataPriadkaInfoText as InfoText,
  prop,
  ZlataPriadkaLoaderWrapper as LoaderWrapper,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import styled from 'styled-components';
import {
  fetchFinishedOrder,
  finishedOrderDataSelector,
  finishedOrderIsFetchingSelector,
} from '../MyAccount/myAccountSlice';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import { getPaymentUrl, isError, shouldRedirect } from '../../utilities/order';

import {
  fbPurchase,
  ga4Purchase,
  gaPurchase,
  heurekaPurchase,
} from '@bart.sk-ecommerce/react-online-marketing';
import { getGoogleAdsId } from '../../configureTagManager';
import { round } from '../../utilities';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { rem } from 'polished';
import { handlePurchase } from './cartSlice';

interface Props {
  dispatch: any;
  orderData: ThenArg<typeof API.getOrder>;
  isFetching: boolean;
  params: any;
}

class FinishedOrderContainer extends React.Component<Props> {
  private isSendingToGA: boolean;

  public static async getInitialProps(props) {
    const {
      dispatch,
      params: { orderPublicId },
      params,
    } = props;
    dispatch(
      setBreadCrumbPath(BreadCrumbType.CART, {
        url: `${__r(
          'routes:dokoncena-objednavka',
          '/dokoncena-objednavka',
        )}/${orderPublicId}`,
      }),
    );
    try {
      await dispatch(fetchFinishedOrder(orderPublicId));
      return { params };
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  constructor(props) {
    super(props);

    this.isSendingToGA = false;
  }

  public async componentDidMount() {
    // await this.updateOrderAnalytics();
  }

  public async componentDidUpdate(prevProps): Promise<void> {
    const {
      params: { orderPublicId },
    } = this.props;
    if (
      prop(prevProps, 'orderData.order_id') !==
        prop(this.props, 'orderData.order_id') &&
      prop(this.props, 'orderData.public_id') === orderPublicId
    ) {
      await this.updateOrderAnalytics();
    }
  }

  private redirectAfterUnsuccessfulPayment(redirectUrl) {
    if (redirectUrl && window && window.location && window.location.href) {
      window.location.href = redirectUrl;
      return null;
    }

    return null;
  }

  public updateOrderAnalytics = async (): Promise<void> => {
    const {
      orderData: order,
      isFetching,
    }: { orderData: any; isFetching: boolean } = this.props;
    if (!this.isSendingToGA && !isFetching) {
      this.isSendingToGA = true;
      this.props.dispatch(handlePurchase(order));
    }
  };

  public render() {
    const { orderData, isFetching } = this.props;

    if (isFetching) {
      return <LoaderWrapper />;
    }

    if (!orderData) {
      return (
        <InfoText
          type={InfoType.ERROR}
          info={__('Takáto objednávka neexistuje')}
        />
      );
    } else if (shouldRedirect(orderData)) {
      const redirectUrl = getPaymentUrl(
        orderData.payment.payment_type,
        orderData.public_id,
      );
      if (redirectUrl && window && window.location && window.location.href) {
        window.location.href = redirectUrl;
        return null;
      }
    } else if (isError()) {
      return (
        <>
          <FinishedOrderWrapper className="container container--wide">
            <MetaTags tags={{ title: __('Chyba') }} />
            <ErrorFinishedOrder
              order={orderData}
              isFetching={isFetching}
              redirectUrl={getPaymentUrl(
                orderData.payment.payment_type,
                orderData.public_id,
              )}
              onRedirect={this.redirectAfterUnsuccessfulPayment}
              error={prop(window.location, 'query.error')}
            />
          </FinishedOrderWrapper>
        </>
      );
    } else {
      return (
        <Wrapper>
          <BreadCrumb withWrapper={true} />
          <FinishedOrderWrapper className="container container--wide">
            <MetaTags tags={{ title: __('Ďakujeme za váš nákup!') }} />
            <FinishedOrder order={orderData} isFetching={isFetching} />
          </FinishedOrderWrapper>
        </Wrapper>
      );
    }

    return null;
  }
}

const Wrapper = styled.div`
  margin-top: ${rem(32)};
`;

const FinishedOrderWrapper = styled(FlexCol)`
  margin: 0 auto;
  width: 100%;
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    orderData: finishedOrderDataSelector(state),
    isFetching: finishedOrderIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'FinishedOrder' })(FinishedOrderContainer),
);
