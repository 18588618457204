import * as React from 'react';
import styled, { css } from 'styled-components';

import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from 'pure-react-carousel';
import { getImagePath } from '../../../utilities/product';
// import LightboxCustom from '../Lightbox/LightboxCustom';

import { rem } from 'polished';
import {
  FlexColCenter,
  ZlataPriadkaProductTab as ProductTabDefault,
  // ProductTabSmall,
  // ArticleTabDefault,
} from 'eshop-defaults';
import { Link } from 'react-router';

const DotComponent = styled(Dot)`
  width: ${rem(8)};
  height: ${rem(8)};
  border: none;
  background-color: #eeeeee;
  padding: 0;
  margin: 0 4px;
  border-radius: 50%;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const Dots = styled.div`
  width: 100%;
  margin: auto;

  ${(props: any) => {
    const { theme, type } = props;
    switch (type) {
      case 'hp-banner': {
        return css`
          text-align: center;
          position: relative;
          top: auto;
          right: auto;
          display: none;
          background: ${theme.color.primary};
          padding-top: ${rem(24)};

          ${theme.mediab.l925`
              display: block;
          `}
        `;
      }
      case 'article-product':
      case 'article-photogallery': {
        return css`
          text-align: center;
          position: relative;
          top: auto;
          right: auto;
          display: block;
          background: ${theme.color.white};
          padding: ${rem(16)} 0 0;
        `;
      }
      case 'add-to-cart-popup': {
        return css`
          text-align: right;
          position: absolute;
          top: ${rem(-32)};
          right 0;
        `;
      }
      case 'product-detail': {
        return css`
          padding: ${rem(24)} 0;
          position: relative;
          top: auto;
          right auto;
          text-align: center;
        `;
      }
      case 'hp-product':
      case 'hp-article': {
        return css`
          padding: ${rem(40)} 0;
          position: absolute;
          text-align: right;
          top: ${rem(-80)};
          right 0;
        `;
      }
      default: {
        return css``;
      }
    }
  }};

  @media only screen and (max-width: 380px) {
    bottom: ${({ type }) => (type === 'ebook' ? '-220px' : 'initial')};
  }
  @media only screen and (max-width: 340px) {
    bottom: ${({ type }) => (type === 'ebook' ? '-200px' : 'initial')};
  }
`;

const BtnBack = styled(ButtonBack)`
  background-image: url('/images/golfcentrum/caret-left.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  height: ${rem(24)};
  width: ${rem(24)};
  z-index: 9;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${rem(48)};
  outline: 0;

  &:disabled {
    background-image: url('/images/zemplin/slider-back_gray.svg');
    cursor: unset;
  }

  ${({ theme }) => theme.mediab.l925`
      display: none;
  `}
`;

const BtnNext = styled(ButtonNext)`
  outline: 0;
  background-image: url('/images/golfcentrum/caret-left.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  height: ${rem(24)};
  width: ${rem(24)};
  border: none;
  z-index: 9;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  right: ${rem(48)};

  &:disabled {
    background-image: url('/images/zemplin/slider-back_gray.svg');
    cursor: unset;
  }

  ${({ theme }) => theme.mediab.l925`
      display: none;
  `}
`;

const Carousel = styled(CarouselProvider)`
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
`;

const SliderCustom = styled(Slider)`
  height: 100%;
`;

const SlideCustom = styled(Slide)`
height: 100%;
  /* margin-right: ${rem(32)}; */
`;

// const TabWrapper = styled(FlexColCenter)`
//   /* margin-right: ${rem(32)}; */

//   ${({ theme }) => theme.mediab.l925`
//     &:nth-child(2n) {
//       margin-right: 0;
//     }
//   `}

//   ${({ theme }) => theme.mediab.m580`
//     margin-right: 0;
//   `}
// `;

const PhotogalleryItem = styled(Link)<{ marginRight?: number }>`
  margin-right: ${({ marginRight }) =>
    marginRight || marginRight === 0 ? rem(marginRight) : 'initial'};
`;

const PhotogalleryCover = styled.img<{ marginRight?: number }>``;

export interface Props {
  type: string;
  slides: any;
  addToCart?: any;
  addToRequest?: any;
  maxSlidesVisible?: number;
  showButtons?: boolean;
  showDots?: boolean;
  user?: any;
  lightboxItems?: any;
}

function SecondSlider({
  type,
  slides,
  addToCart,
  addToRequest,
  maxSlidesVisible = 4,
  user,
  showButtons = false,
  showDots = false,
  lightboxItems = [],
}: Props) {
  const [state, setState]: any = React.useState({
    width: 330,
    height: 192,
    visibleSlides: 4,
  });

  const [isLightboxOpen, setIsLightboxOpen] = React.useState(false);
  const [itemIndex, setItemIndex]: any = React.useState(0);

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const openLightbox = () => {
    setIsLightboxOpen(true);
  };
  const handleLightboxAction = index => {
    setItemIndex(index);
    setIsLightboxOpen(!isLightboxOpen);
  };

  const renderDots = (items: any[], slide: number) => {
    const table: any[] = [];
    for (let i = 0; i < items.length; i += slide) {
      table.push(<DotComponent slide={i} key={i} />);
    }
    return table;
  };

  const updateState = React.useCallback(
    win => {
      if (type === 'product') {
        if (win > 1450) {
          setState({
            ...state,
            width: 330,
            height: 255,
            visibleSlides: 4,
          });
        } else if (win <= 1450 && win >= 1260) {
          setState({
            ...state,
            width: 300,
            height: 255,
            visibleSlides: 4,
          });
        } else if (win <= 1260 && win >= 1100) {
          setState({
            ...state,
            width: 330,
            visibleSlides: 3,
          });
        } else if (win <= 1100 && win >= 975) {
          setState({
            ...state,
            width: 300,
            visibleSlides: 3,
          });
        } else if (win < 975 && win >= 830) {
          setState({
            ...state,
            width: 280,
            visibleSlides: 3,
          });
        } else if (win < 830 && win >= 750) {
          setState({
            ...state,
            width: 330,
            visibleSlides: 2,
          });
        } else if (win < 750 && win >= 620) {
          setState({
            ...state,
            width: 290,
            visibleSlides: 2,
          });
        } else if (win < 620 && win >= 550) {
          setState({
            ...state,
            width: 400,
            visibleSlides: 1,
          });
        } else if (win < 550 && win >= 480) {
          setState({
            ...state,
            width: 400,
            visibleSlides: 1,
          });
        } else if (win < 480 && win >= 440) {
          setState({
            ...state,
            width: 400,
            visibleSlides: 1,
          });
        } else if (win < 440 && win >= 420) {
          setState({
            ...state,
            width: 400,
            visibleSlides: 1,
          });
        } else if (win < 420 && win >= 380) {
          setState({
            ...state,
            width: 350,
            visibleSlides: 1,
          });
        } else if (win < 420 && win >= 380) {
          setState({
            ...state,
            width: 300,
            visibleSlides: 1,
          });
        } else {
          setState({
            ...state,
            width: 300,
            visibleSlides: 1,
          });
        }
      } else if (type === 'photogallery' || type === 'product-detail') {
        setState({
          width: 480,
          height: 480,
          visibleSlides: 1,
        });
      } else if (type === 'addToCartProduct') {
        setState({
          ...state,
          width: 288,
          visibleSlides: 2,
        });
      } else if (type === 'hp-banner') {
        if (win > 925) {
          setState({
            ...state,
            height: 480,
            width: 1920,
            visibleSlides: 1,
          });
        } else {
          setState({
            ...state,
            height: 386,
            width: 320,
            visibleSlides: 1,
          });
        }
      } else if (type === 'hp-product') {
        if (win > 925) {
          setState({
            ...state,
            height: 446,
            visibleSlides: 3,
          });
        } else if (win > 760) {
          setState({
            ...state,
            height: 446,
            visibleSlides: 2,
          });
        } else {
          setState({
            ...state,
            height: 446,
            visibleSlides: 1,
          });
        }
      } else if (type === 'hp-article') {
        if (win > 925) {
          setState({
            ...state,
            height: 375,
            visibleSlides: 4,
          });
        } else {
          setState({
            ...state,
            height: 375,
            visibleSlides: 1,
          });
        }
      } else if (type === 'article-product') {
        if (win > 720 || true) {
          setState({
            ...state,
            height: 400,
            visibleSlides: 2,
          });
        }
      } else if (type === 'article-photogallery') {
        if (win > 720 || true) {
          setState({
            ...state,
            height: 350,
            visibleSlides: 3,
          });
        }
      } else if (type === 'add-to-cart-popup') {
        if (win > 1130 || true) {
          setState({
            ...state,
            height: 80,
            visibleSlides: 2,
          });
        }
      }
    },
    [type, state],
  );

  React.useEffect(() => {
    updateState(window.innerWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const handleResize = () => updateState(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateState]);

  const { width, height, visibleSlides } = state;
  const renderItem = (key: number, img: string, item: any) => {
    // if (type === 'product' || type === 'addToCartProduct') {
    //   return (
    //     <TabWrapper>
    //       <ProductTabSmall
    //         key={item.product_id}
    //         // tslint:disable-next-line:jsx-no-lambda
    //         // tslint:disable-next-line:jsx-no-lambda
    //         product={item}
    //       />
    //     </TabWrapper>
    //   );
    // } else
    if (type === 'product-detail') {
      return (
        <PhotogalleryItem key={key}>
          <PhotogalleryCover
            src={item.original}
            // src={getImagePath(item, { width: 800, height: 800 })}
          />
        </PhotogalleryItem>
      );
    } else if (type === 'hp-banner') {
      return (
        <PhotogalleryItem key={key} to={item.b_url}>
          <PhotogalleryCover
            alt={`Banner č. ${key}`}
            src={getImagePath(
              item.b_path,
              {
                width: item.device === 'phone' ? 925 : 1920,
                height: 0,
              },
              false,
              true,
            )}
          />
        </PhotogalleryItem>
      );
    }
    // else if (type === 'hp-product') {
    //   return (
    //     <ProductTabDefault
    //       key={item.product_id}
    //       product={item}
    //       isElasticCategory={false}
    //     />
    //   );
    // }
    // else if (type === 'hp-article') {
    //   return (
    //     <ArticleTabDefault
    //       key={key}
    //       article={item}
    //       anotationAsTitle={false}
    //       showCategory={true}
    //     />
    //   );
    // }
    // else if (type === 'article-product') {
    //   return (
    //     <ProductTabDefault
    //       key={item.product_id}
    //       product={item}
    //       isElasticCategory={false}
    //     />
    //   );
    // }
    else if (type === 'article-photogallery') {
      return (
        <PhotogalleryItem
          key={key}
          marginRight={8}
          title={key}
          onClick={() => handleLightboxAction(key)}
        >
          <PhotogalleryCover
            alt="Obrázok vo fotogalerií"
            src={getImagePath(
              item.document_path,
              { width: 256, height: 256 },
              false,
              false,
              false,
            )}
          />
        </PhotogalleryItem>
      );
    }
    return null;
  };

  // window.addEventListener('resize', this.updateState);
  // window.removeEventListener('resize', this.updateState);

  return (
    <>
      {/* {slides && slides.length > 0 ? ( */}
      <Carousel
        naturalSlideHeight={height}
        naturalSlideWidth={width}
        visibleSlides={
          visibleSlides > maxSlidesVisible ? maxSlidesVisible : visibleSlides
        }
        step={visibleSlides}
        totalSlides={slides.length}
      >
        {showButtons && <BtnBack type={type} />}
        <SliderCustom type={type}>
          {slides.map((item, index) => {
            return (
              <SlideCustom
                innerClassName="sliderCentered"
                index={index}
                key={index}
              >
                {renderItem(index, item.img ? item.img : '', item)}
              </SlideCustom>
            );
          })}
          {/* {lightboxItems && lightboxItems.length > 0 && (
            <LightboxCustom
              items={lightboxItems}
              isLightboxOpen={isLightboxOpen}
              onCloseRequest={closeLightbox}
              itemIndex={itemIndex}
              setItemIndex={setItemIndex}
            />
          )} */}
        </SliderCustom>
        {showButtons && <BtnNext type={type} />}
        {showDots && (
          <Dots type={type}>{renderDots(slides, visibleSlides)}</Dots>
        )}
      </Carousel>
      {/* ) : (
          <NoItemsWrapper text={'Nenašli sa žiadne produkty'} />
        )} */}
    </>
  );
}

export default SecondSlider;
