import * as React from 'react';
import { __, __r } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  prop,
  FlexRow,
  InfoText,
  ZlataPriadkaLeftUpper as LeftUpper,
  ZlataPriadkaRightUpper as RightUpper,
  ZlataPriadkaLoaderWrapper as LoaderWrapper,
  ZlataPriadkaImageGallery as ImageGallery,
  ZlataPriadkaProductList as ProductList,
  getZlataPriadkaProductDefaultInfo,
  getProductDetailInfo,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { getProductMetaTags } from '../../utilities/metatags';
import { addItemToCart } from '../../containers/Cart/cartSlice';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import {
  getAbsoluteUrl,
  getBrandFromProductInfo,
  getImagePath,
  resolveProductInfo,
  resolveProductSymbols,
} from '../../utilities/product';
import Modal from '../_helpers/Default/Modal';
import { WithRouterProps, withRouter } from 'react-router';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import BreadCrumb from '../../containers/BreadCrumb/BreadCrumb';
import SecondSlider from '../_helpers/Slider/SecondSlider';
import {
  addItemToFavourite,
  loadFavouriteProducts,
  removeItemFromFavourite,
} from '../../containers/Home/actions';
import { push } from 'react-router-redux';
import MetaTags from '../_helpers/MetaTags/MetaTags';

import { ProductMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { getAvailability } from '../../utilities/microdata';
import { round, stripHtmlTags } from '../../utilities';

interface Props {
  user: ThenArg<typeof API.tokeninfo>;
  lang?: string;
  product: ThenArg<typeof API.loadProduct>;
  isFetching: boolean;
  alternativeProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAlternatives: boolean;
  dispatch: any;
  cart: ThenArg<typeof API.getCart> | null;
  categories: ThenArg<typeof API.loadTree>;
  categoriesIsFetching;
  favouriteProducts: any;
}

function Product({
  product,
  isFetching,
  alternativeProducts,
  isFetchingAlternatives,
  dispatch,
  user,
  location,
  router,
  favouriteProducts,
}: Props & WithRouterProps) {
  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.PRODUCT, product));
  }, [product, dispatch]);

  // React.useEffect(() => {
  //   const { pathname } = location;
  //   const url = prop(product, 'url');
  //   const id = prop(product, 'product_id');
  //   const queryProductId = prop(router, 'params.product_id');
  //   if (
  //     id &&
  //     queryProductId === id.toString() &&
  //     url &&
  //     pathname !== `/produkt/${id}/${url}`
  //   ) {
  //     router.replace(`/produkt/${id}/${url}`);
  //   }
  // }, [location, product, router]);

  const [imageModalVisible, setImageModalVisible] = React.useState(false);
  const [modalCurrentImage, setModalCurrentImage] = React.useState(0);

  const { photogallery } = getZlataPriadkaProductDefaultInfo(product);

  const [currentGood, setCurrentGood] = React.useState({});
  React.useEffect(() => {
    if (product.goods && product.goods.length > 0) {
      setCurrentGood(product.goods[0]);
    }
  }, [product]);
  const info = getProductDetailInfo(product);

  const {
    attribs,
    name,
    ordernr,
    shortDescription,
    currency,
    isnew,
    issale,
    picture,
    unit,
    oldPrice,
    url,
  } = info;
  const price = Number(currentGood['final_price']).toFixed(2);
  const onStock = currentGood['on_stock_count'];

  // attribs
  const symbolsAttrib = attribs.find(a => a.attrib_name === 'Ošetrovanie');
  const symbolsArray = resolveProductSymbols(symbolsAttrib);
  const productInfo = resolveProductInfo(attribs);

  const [mainImage, setMainImage] = React.useState('');
  React.useEffect(() => {
    let imagePath = '';
    if (product.goods && product.goods.length > 1) {
      imagePath = picture
        ? getImagePath(
            picture,
            { width: 580, height: 580 },
            false,
            true,
            false,
            true,
          )
        : product.picture
        ? getImagePath(
            product.picture,
            {
              width: 580,
              height: 580,
            },
            false,
            true,
            false,
            true,
          )
        : '/images/zlatapriadka/placeholder.svg';
    } else {
      imagePath =
        product.goods && product.goods[0] && product.goods[0].imagePath
          ? getImagePath(
              product.goods[0].imagePath,
              {
                width: 580,
                height: 580,
              },
              false,
              true,
              false,
              true,
            )
          : product.picture
          ? getImagePath(
              product.picture,
              {
                width: 580,
                height: 580,
              },
              false,
              true,
              false,
              true,
            )
          : '/images/zlatapriadka/placeholder.svg';
    }
    setMainImage(imagePath);
  }, [picture, product]);

  const fullscreenImages =
    photogallery || picture
      ? [picture, ...photogallery]
          .filter(a => a)
          .map(doc => {
            const img = getImagePath(
              doc,
              {
                width: 800,
                height: 600,
              },
              false,
              true,
              false,
              true,
            );
            const thumbImg = getImagePath(
              doc,
              {
                width: 150,
                height: 150,
              },
              false,
              true,
              false,
              true,
            );
            return {
              original: img,
              thumbnail: thumbImg,
              name: '',
            };
          })
      : [];

  const initialCount = unit === 'm' ? 0.3 : 1;
  const correctInitialCount =
    initialCount > onStock && onStock > 0 ? onStock : initialCount;
  const [count, setCount] = React.useState(correctInitialCount);
  React.useEffect(() => {
    const initialCount = unit === 'm' ? 0.3 : 1;
    const correctInitialCount =
      initialCount > onStock && onStock > 0 ? onStock : initialCount;
    setCount(correctInitialCount);
  }, [unit, onStock]);

  const renderUpperPart = () => {
    return (
      <>
        <UpperDesktopWrapper>
          <LeftUpper
            title={name}
            images={fullscreenImages}
            isnew={isnew}
            issale={issale}
            topImage={mainImage}
            openModal={openModal}
          />
          <RightUpper
            id={product.product_id}
            handleGoodChange={changeGood}
            title={name}
            plu={ordernr}
            description={shortDescription}
            onStock={onStock}
            price={price}
            oldPrice={oldPrice}
            currency={currency}
            unit={unit}
            count={count}
            handleCountChange={count => setCount(count)}
            addToCart={addToCartWithModal}
            symbols={symbolsArray}
            productInfo={productInfo}
            toggleFavourite={toggleFavourite}
            favouriteProducts={favouriteProducts}
            alternativeProducts={prop(alternativeProducts, 'products', [])}
            url={url}
            image={picture}
          />
        </UpperDesktopWrapper>
        <UpperMobileWrapper>
          <LeftUpperWrapper>
            {fullscreenImages && fullscreenImages.length > 0 ? (
              <SecondSlider
                type="product-detail"
                slides={fullscreenImages}
                showDots={true}
              />
            ) : (
              <MobileImagePlaceholder src="/images/zlatapriadka/placeholder.svg" />
            )}
          </LeftUpperWrapper>
          <RightUpper
            id={product.product_id}
            handleGoodChange={changeGood}
            title={name}
            plu={ordernr}
            description={shortDescription}
            onStock={onStock}
            price={price}
            oldPrice={oldPrice}
            currency={currency}
            unit={unit}
            count={count}
            handleCountChange={count => setCount(count)}
            addToCart={addToCartWithModal}
            symbols={symbolsArray}
            productInfo={productInfo}
            toggleFavourite={toggleFavourite}
            favouriteProducts={favouriteProducts}
            alternativeProducts={prop(alternativeProducts, 'products', [])}
            url={url}
            image={picture}
          />
        </UpperMobileWrapper>
      </>
    );
  };

  const toggleFavourite = async (productId, newVal = null) => {
    if (user) {
      if (!newVal) {
        await dispatch(removeItemFromFavourite(user.id!, productId));
      } else {
        await dispatch(addItemToFavourite(user.id!, productId));
      }

      dispatch(loadFavouriteProducts(user.id?.toString() as string, true));
    } else {
      await dispatch(push(__r('routes:moj-ucet', '/moj-ucet')));
    }
  };

  const renderRelated = () => {
    return (
      <>
        {!isFetchingAlternatives ? (
          alternativeProducts &&
          alternativeProducts.products &&
          alternativeProducts.products.length > 0 && (
            <RelatedProductsWrapper>
              <Title>{__('Mohlo by vás zaujímať')}</Title>
              <ListWrapper>
                <ProductList
                  isFetching={isFetching}
                  products={alternativeProducts.products}
                  visible={true}
                  isElasticCategory={false}
                  toggleFavourite={toggleFavourite}
                  favouriteProducts={favouriteProducts}
                />
              </ListWrapper>
            </RelatedProductsWrapper>
          )
        ) : (
          <LoaderWrapper height={250} />
        )}
      </>
    );
  };

  const changeGood = (newUrl: string) => {
    if (url !== newUrl) {
      dispatch(push(newUrl));
    }
  };

  const addToCartWithModal = () => {
    dispatch(
      addItemToCart(
        product,
        product.goods[0].good_id,
        product.product_id,
        count,
      ),
    );
  };

  const closeImageModal = () => {
    setImageModalVisible(false);
  };

  const setCurrentImage = c => {
    setModalCurrentImage(c);
  };

  const openModal = (index: number = 0) => {
    setImageModalVisible(true);
    setModalCurrentImage(index);
  };

  return (
    <React.Fragment>
      <ProductMicrodata
        image={mainImage ? mainImage : ''}
        name={name}
        description={stripHtmlTags(shortDescription)}
        offers={
          price && Number(price) > 0
            ? {
                availability:
                  getAvailability(
                    prop(currentGood, 'stores_info', []),
                    onStock,
                  ) || 'OutOfStock',
                price: round(Number(price), 2).toFixed(2),
                priceCurrency: currency,
              }
            : undefined
        }
        sku={ordernr}
        url={getAbsoluteUrl(prop(product, 'url', ''))}
        brand={getBrandFromProductInfo(productInfo)}
      />
      <Wrapper className="container container--wide">
        <MetaTags tags={getProductMetaTags(product)} />
        <ProductWrapper>
          {isFetching ? (
            <LoaderWrapper height={300} />
          ) : product ? (
            <React.Fragment>
              <BreadCrumb />
              {renderUpperPart()}
              {renderRelated()}
              {imageModalVisible &&
                fullscreenImages &&
                fullscreenImages.length > 0 && (
                  <Modal size="big" showX={false} closeModal={closeImageModal}>
                    <ImageGallery
                      closeModal={closeImageModal}
                      setCurrentImage={setCurrentImage}
                      items={fullscreenImages}
                      currentIndex={modalCurrentImage}
                      isVertical={false}
                    />
                  </Modal>
                )}
            </React.Fragment>
          ) : (
            <InfoText
              info={__('Nenašiel sa žiadny produkt')}
              type={InfoType.ERROR}
            />
          )}
        </ProductWrapper>
      </Wrapper>
    </React.Fragment>
  );
}

const Wrapper = styled(FlexRow)`
  color: #444444;
  width: 100%;
  /* TODO change */
  margin-top: ${rem(32)} !important;
  margin-bottom: ${rem(80)} !important;
  padding-left: ${rem(16)} !important;
  padding-right: ${rem(16)} !important;

  ${({ theme }) => theme.mediab.l925`
      padding: ${rem(32)} 0;
      margin-top: 0 !important;
  `}
`;

const ProductWrapper = styled.div`
  width: 100%;
`;

const ListWrapper = styled.div`
  ${({ theme }) => theme.mediab.l925`
    width: 100%;
    margin: 0 auto;
  `}
`;

const RelatedProductsWrapper = styled.div`
  width: 100%;
  margin-top: ${rem(80)};

  ${({ theme }) => theme.mediab.l925`
    margin-top: ${rem(56)};
  `}
`;

// const SliderWrapper = styled(FlexCol)``;

// const AccessorySliderWrapper = styled(SliderWrapper)`
//   margin-bottom: ${rem(56)};

//   ${({ theme }) => theme.mediab.l925`
//      margin-bottom: ${rem(32)};
//   `}
// `;

const Title = styled.h4`
  font-size: ${rem(24)};
  font-weight: 500;
  line-height: ${rem(31.2)};
  font-family: ${({ theme }) => theme.font.primary};
  margin-bottom: ${rem(56)};
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  text-transform: uppercase;

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    line-height: ${rem(28)};
  `}
`;

const UpperWrapper = styled(FlexRow)`
  justify-content: space-between;
  margin-bottom: ${rem(16)};

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
  `}
`;

const UpperMobileWrapper = styled(UpperWrapper)`
  display: none;

  ${({ theme }) => theme.mediab.l925`
    display: flex;
    margin: 0 auto;
  `}

  ${({ theme }) => theme.mediab.s450`
    margin: initial;
  `}
`;

const UpperDesktopWrapper = styled(UpperWrapper)`
  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const LeftUpperWrapper = styled(UpperWrapper)`
  ${({ theme }) => theme.mediab.l925`
    display: flex;
    min-width: ${rem(320)};
    margin: 0 auto;
  `}

  ${({ theme }) => theme.mediab.m760`
    min-width: ${rem(260)};
  `}

  ${({ theme }) => theme.mediab.s450`
    min-width: ${rem(200)};
  `}
`;

const MobileImagePlaceholder = styled.div<{ src: string }>`
  max-width: ${rem(608)};
  height: ${rem(608)};
  width: 100%;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  cursor: pointer;
  ${({ src }) =>
    src.includes('placeholder') ? 'background-size: contain;' : ''}

  ${({ theme }) => theme.mediab.m810`
    margin: 0 auto;
    background-size: contain;
    height: ${rem(254)};
  `}
`;
export default withRouter(Product);
