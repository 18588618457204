import * as React from 'react';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import { Link } from 'react-router';
import { css } from 'styled-components';
import { __ } from 'react-i18n';
import { ZlataPriadkaSvgIcon as SvgIcon } from 'eshop-defaults';
import { IconType } from 'eshop-defaults/lib/components/ZlataPriadka/General/SvgIcon';

interface Props {
  title: string;
  description: string;
  imagePath: string | null;
  redirectUrl: string;
  buttonDescription: string;
  nonWebpImage: string | null;
}

export const SliderBanner = ({
  title,
  description,
  imagePath,
  redirectUrl,
  buttonDescription,
  nonWebpImage,
}: Props) => {
  const isFullUrl = redirectUrl
    ? Boolean(redirectUrl.match(/http|https/g))
    : false;

  const renderButton = (): JSX.Element => {
    return (
      <SvgIcon
        icon={IconType.rightArrow}
        width={16}
        height={16}
        viewBox="0 0 16 16"
        marginLeft={8}
        cursor="pointer"
      />
    );
  };

  const renderSymbol = (): JSX.Element => {
    return (
      <StyledSvgIcon
        icon={IconType.logoSymbol}
        viewBox="0 0 50 50"
        width={739}
        height={706}
      />
    );
  };
  return (
    <div>
      <BannerWrapper
        backgroundUrl={imagePath ? imagePath : null}
        nonWebpImage={nonWebpImage}
      >
        {renderSymbol()}
        <BannerContent>
          {title && (
            <Title>
              <span>{`${title}`.substring(0, 100)}</span>
              {isFullUrl ? (
                <CardButton
                  href={redirectUrl}
                  title={__('Link na externú stránku')}
                >
                  {buttonDescription}
                  {renderButton()}
                </CardButton>
              ) : (
                <CardButton href={redirectUrl}>
                  {buttonDescription}
                  {renderButton()}
                </CardButton>
              )}
            </Title>
          )}
          {/* <BannerText>{`${description}`.substring(0, 150)}</BannerText> */}
        </BannerContent>
      </BannerWrapper>
    </div>
  );
};

const CardButton = styled(Link)<{ spanSize: number }>`
  display: inline-block;
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  margin-bottom: 0;
  font-weight: 500;
  font-size: ${rem(16)};
  text-decoration: none;
  font-family: ${({ theme }) => theme.font.primary};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

// obrazok banneru musi obsahovat aj farbu
const BannerWrapper = styled.section<{
  backgroundUrl: string | null;
  nonWebpImage: string | null;
}>`
  background: ${({ theme, backgroundUrl }) =>
    backgroundUrl !== null
      ? `url(${backgroundUrl})`
      : `${theme.colors.primary}`};
  background-size: cover;
  background-position: center center;
  padding: ${rem(152)} 13.5%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  .no-webp &&& {
    background-image: url('${props => props.nonWebpImage}');
  }

  ${({ theme }) => theme.mediab.l1150`
    padding-bottom: ${rem(64)};
    padding-top: ${rem(64)};
  `}

  ${({ theme }) => theme.media(0, 'sm')} {
    padding: ${rem(24)} ${rem(16)};
  }
`;

const BannerContent = styled.article`
  position: relative;
  display: inline-block;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${({ theme }) => theme.mediab.l925`
    justify-content: flex-end;
    height: initial;
  `}

  ${({ theme }) => theme.media(0, 'sm')} {
    max-width: 100%;
  }
`;

const StyledSvgIcon = styled(SvgIcon)`
  position: absolute;
  left: 50%;
  bottom: 0;
  fill: ${({ theme }) => rgba(theme.colors.white, 0.16)};

  * {
    fill: inherit;
  }

  ${({ theme }) => theme.media(0, 'sm')} {
    width: ${rem(434)};
    height: ${rem(419)};
    left: ${rem(103)};
  }
`;

const Title = styled.div`
  padding: ${rem(48)};
  background: white;
  border-radius: ${rem(16)};
  font-size: ${rem(30)};
  // line-height: ${rem(39)};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: ${rem(32)};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: bold;
  max-width: ${rem(400)};

  span{
    line-height: ${rem(39)}
  }

  a{
    display:block;
    padding-top: ${rem(24)};
  }

  ${({ theme }) => theme.media(0, 'sm')} {
    font-size: ${rem(20)};
    line-height: ${rem(26)};
    margin-bottom: 0;
    max-width: initial;
    text-transform: uppercase;
  }
`;

const buttonLikeCss = css`
  display: inline-block;
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: ${rem(14)} ${rem(24)};
  font-weight: 600;
  font-size: ${rem(16)};
  line-height: ${rem(19)};
  height: initial;
  border-radius: ${rem(4)};
  text-decoration: none;
  font-family: ${({ theme }) => theme.font.primary};

  ${({ theme }) => theme.media(0, 'sm')} {
    padding: ${rem(10)} ${rem(24)};
    margin-bottom: 0;
    width: initial;
    padding: ${rem(14)} ${rem(24)};
    display: none;
  }

  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.whiteOnHover};
    outline: none;
  }
`;

const BannerButton = styled(Link)`
  ${buttonLikeCss};
`;

const StyledLink = styled.a`
  ${buttonLikeCss};
`;

const BannerText = styled.p`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${rem(20)};
  line-height: ${rem(20)};
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  margin: 0;
  font-weight: 400;
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.media(0, 'sm')} {
    font-size: ${rem(14)};
    line-height: ${rem(18)};
  }
`;
