import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { ZlataPriadkaLoginForm as LoginForm } from 'eshop-defaults';
import { withRouter, WithRouterProps } from 'react-router';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { UrlHashService } from '../../services/HashService';
import { onlyPublic } from '../../utilities/auth';
import { cartIdSelector, fetchCart } from '../Cart/cartSlice';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';

import BreadCrumb from '../../containers/BreadCrumb/BreadCrumb';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

interface Props {
  cartId: string | undefined;
  dispatch: (action: any) => void;
}

interface State {
  error: string;
}

const LoginFormWrapper = styled.div`
  width: 100%;
  max-width: ${rem(320)};
  margin: ${rem(80)} auto ${rem(80)} auto;

  ${({ theme }) => theme.mediab.l925`
     padding: 0 ${rem(16)};
     margin-top: ${rem(32)};
     margin-bottom: ${rem(56)};
  `}
`;

const Wrapper = styled.div`
  margin-top: ${rem(32)};
`;

class LoginPage extends React.Component<Props & WithRouterProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  public async componentDidMount() {
    this.props.dispatch(setBreadCrumbPath(BreadCrumbType.LOGIN, null));

    const { hash } = this.props.location;
    const urlHashService = new UrlHashService(hash.replace('#', ''));
    const accessToken = urlHashService.get('access_token');
    const error = decodeURIComponent(urlHashService.get('error'));
    // const previousUrl = decodeURIComponent(urlHashService.get('state'));

    if (accessToken) {
      this.props.dispatch(
        loginUser(accessToken, false, () => {
          this.props.dispatch(fetchCart(true));
        }),
      );
      // window.location.href = previousUrl;
      // this.props.dispatch(push('/'));
    } else if (error) {
      this.setState({
        error: error && error !== 'null' ? error : '',
      });
    }
  }

  public render() {
    const {
      cartId,
      location: { pathname, search },
    } = this.props;
    const { error } = this.state;

    return (
      <Wrapper>
        <BreadCrumb withWrapper={true} />
        <LoginFormWrapper>
          <MetaTags tags={{ title: 'Prihlásenie' }} />
          <LoginForm
            baseApiUrl={process.env.REACT_APP_API_BASE_URL}
            baseUrl={process.env.REACT_APP_BASE_URL}
            cartId={cartId}
            pathname={pathname}
            search={search}
            error={error}
          />
        </LoginFormWrapper>
      </Wrapper>
    );
  }

  public handleFacebookLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/facebook?state="${this.props.cartId}"`;
  };

  public handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/google?state="${this.props.cartId}"`;
  };
}

const mapStateToProps = state => {
  return {
    cartId: cartIdSelector(state),
  };
};

const withRouterLoginPage = onlyPublic(LoginPage);
export default connect(mapStateToProps)(withRouter(withRouterLoginPage));
