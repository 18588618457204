import * as React from 'react';
import { __, __r } from 'react-i18n';
import { Link, withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  TextP,
  FlexRow,
  FlexCol,
  prop,
  ZlataPriadkaSearchForm as SearchForm,
  ZlataPriadkaHeaderLogo,
  ZlataPriadkaIconType as IconType,
  ZlataPriadkaSvgIcon as SvgIcon,
  ZlataPriadkaCategoryCard as CategoryCard,
  ZlataPriadkaCategoryCardType as CategoryCardType,
  OutsideClick,
  FlexColCenter,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import HeaderCart from './HeaderCart';
import HeaderProfile from './HeaderProfile';
import { DesktopWrapper } from '../_helpers/Default/ResponsiveWrappers';
import { CART_ROUTES } from '../../containers/Cart/utilities';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { cartRoutes } from '../../utilities/cart';
import { resolveCategoryShortUrl } from 'eshop-defaults/lib/utilities/selectors';
import { getCategoryImagePath } from '../../utilities/category';

interface Props {
  lang: string;
  // items: ThenArg<typeof API.loadTree>;
  items: any;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  toggleResponsiveCategoryMenu: any;
  handleSearchFocus: any;
  hideSearchResultsViewer: () => void;
  resultsDropdownVisible: boolean;
  searchTerm: string;
  handleSearchTermChange: (e: any) => void;
  redirectToSearchResultsPage: (e: any) => void;
  changeDimmerVisible: (newVal: boolean) => void;
  partialSearchCategoryProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  partialSearchArticles: ThenArg<typeof API.loadArticles>;
  partialSearchArticlesIsFetching: boolean;
  user: any;
  cartData: ThenArg<typeof API.getCart>;
  resetSearchTerm: any;
  freeDel: number | null;
  addToCart: any;
  clearSearchInput;
  favouriteProductsTotal: number;
}

function DesktopHeader({
  user,
  contact: { phone, email },
  handleSearchFocus,
  hideSearchResultsViewer,
  resultsDropdownVisible,
  searchTerm,
  handleSearchTermChange,
  redirectToSearchResultsPage,
  changeDimmerVisible,
  items,
  partialSearchProducts,
  partialSearchProductsIsFetching,
  partialSearchCategories,
  partialSearchCategoriesIsFetching,
  partialSearchArticles,
  partialSearchArticlesIsFetching,
  cartData,
  location,
  resetSearchTerm,
  partialSearchCategoryProducts,
  dispatch,
  freeDel,
  addToCart,
  clearSearchInput,
  favouriteProductsTotal,
}: Props & WithRouterProps) {
  const logoSrc = '/images/zlatapriadka/zlatapriadka_logo.svg';
  const smallLogoSrc = '/images/zlatapriadka/zlatapriadka_logo.svg';
  const { items: cartItems, currency, total_items_price, total_price } =
    (cartData as any) || {};

  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [isSearchVisible, setIsSearchVisible] = React.useState(false);

  const toggleMenu = () => {
    changeDimmerVisible(!isMenuVisible);
    setIsMenuVisible(!isMenuVisible);
    if (!isMenuVisible) setIsSearchVisible(false);
  };

  const closeMenu = () => {
    setIsMenuVisible(false);
    setIsSearchVisible(false);
    changeDimmerVisible(false);
  };

  const toggleSearch = e => {
    e.stopPropagation();
    changeDimmerVisible(!isSearchVisible);
    setIsSearchVisible(!isSearchVisible);
    if (!isSearchVisible) setIsMenuVisible(false);
  };

  const redirectToSearchResultPage = e => {
    toggleSearch(e);
    redirectToSearchResultsPage(e);
  };

  const redirectHideSearchResultsViewer = e => {
    toggleSearch(e);
    hideSearchResultsViewer();
  };

  const renderCategories = () => {
    const placeholderImgSrc = '/images/zlatapriadka/placeholder.png';
    return (
      <CategoryWrapper>
        {items &&
          items.length > 0 &&
          items.map(c => {
            return (
              <CategoryCard
                categoryName={c.category_name}
                closeMenu={closeMenu}
                name={c.category_name}
                type={CategoryCardType.CLASSIC}
                url={resolveCategoryShortUrl(c.category_id, c.url)}
                img={
                  c.image
                    ? getCategoryImagePath(
                        c.image,
                        {
                          width: 56,
                          height: 56,
                        },
                        false,
                        false,
                        false,
                      )
                    : placeholderImgSrc
                }
              />
            );
          })}
      </CategoryWrapper>
    );
  };

  return (
    <OutsideClick handleFunction={closeMenu}>
      <HeaderWrapper>
        <StyledDesktopWrapper>
          <UpperHeader>
            <UpperHeaderWrapper>
              <LeftWrapper>
                <ZlataPriadkaHeaderLogo logo={logoSrc} />
              </LeftWrapper>
              <MiddleWrapper>
                <SvgIcon
                  icon={IconType.listMenu}
                  alt="Otvoriť menu"
                  cursor={'pointer'}
                  viewBox="0 0 24 24"
                  marginRight={12}
                  width={24}
                  height={24}
                  fill={`${({ theme }) => theme.colors.textPrimary}`}
                  onClick={toggleMenu}
                />
                <MenuText onClick={toggleMenu}>{__('Ponuka tovaru')}</MenuText>

                <MenuText
                  onClick={closeMenu}
                  to={__r('routes:kontakt', '/kontakt')}
                >
                  Kontakt
                </MenuText>
                <MenuText onClick={closeMenu} to={__r('routes:blog', '/blog')}>
                  Blog
                </MenuText>
              </MiddleWrapper>
              <RightWrapper onClick={closeMenu}>
                <SocialIconsWrapper>
                  <SvgIcon
                    icon={IconType.search}
                    alt="Otvoriť menu"
                    cursor={'pointer'}
                    viewBox="0 0 24 24"
                    marginRight={16}
                    width={24}
                    height={24}
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={toggleSearch}
                  />
                  <StyledLink
                    to={__r('routes:moj-ucet/oblubene', '/moj-ucet/oblubene')}
                  >
                    <SvgIcon
                      icon={IconType.favourite}
                      alt="Otvoriť menu"
                      cursor={'pointer'}
                      viewBox="0 0 24 24"
                      marginRight={16}
                      width={24}
                      height={24}
                    />
                    {favouriteProductsTotal > 0 && (
                      <FavoriteTotal>{favouriteProductsTotal}</FavoriteTotal>
                    )}
                  </StyledLink>
                  <HeaderProfile user={user} dispatch={dispatch} />
                </SocialIconsWrapper>
                <HeaderCart
                  currency={currency}
                  cartData={cartData}
                  dispatch={dispatch}
                  totalPrice={total_price}
                  freeDel={freeDel}
                  addToCart={addToCart}
                />
              </RightWrapper>
            </UpperHeaderWrapper>
          </UpperHeader>
          {isSearchVisible && (
            <BottomHeader>
              <BottomHeaderWrapper>
                <SearchForm
                  user={user}
                  searchResults={{
                    productsResult: partialSearchProducts,
                    categoriesResult: partialSearchCategories,
                    articlesResult: partialSearchArticles,
                  }}
                  redirectToSearchResultPage={redirectToSearchResultPage}
                  searchTerm={searchTerm}
                  handleSearchTermChange={handleSearchTermChange}
                  handleFocus={handleSearchFocus}
                  hideResultViewer={redirectHideSearchResultsViewer}
                  resultsDropdownVisible={resultsDropdownVisible}
                  partialSearchProductsIsFetching={
                    partialSearchProductsIsFetching
                  }
                  partialSearchCategoriesIsFetching={
                    partialSearchCategoriesIsFetching
                  }
                  partialSearchArticlesIsFetching={
                    partialSearchArticlesIsFetching
                  }
                  partialSearchCategoryProducts={partialSearchCategoryProducts}
                  clearSearchInput={clearSearchInput}
                />
              </BottomHeaderWrapper>
            </BottomHeader>
          )}
          {isMenuVisible && (
            <BottomHeader>
              <BottomHeaderWrapper>{renderCategories()}</BottomHeaderWrapper>
            </BottomHeader>
          )}
        </StyledDesktopWrapper>
      </HeaderWrapper>
    </OutsideClick>
  );
}

const StyledDesktopWrapper = styled(DesktopWrapper)`
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
`;

const HeaderWrapper = styled(FlexCol)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 auto;
  width: 100%;
  align-items: center;

  box-shadow: 0px 2px 32px #0000000a;

  @media print {
    display: none;
  }
`;

const UpperHeader = styled.div`
  width: 100%;
`;

const UpperHeaderWrapper = styled(FlexRow)`
  padding: ${rem(20)};
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  align-items: center;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  margin: 0 auto;
`;

const BottomHeader = styled.div`
  width: 100%;
`;

const BottomHeaderWrapper = styled.div`
  border-top: ${rem(2)} solid #eeeeee;
  width: 100%;

  position: absolute;
  z-index: 400;
  background-color: white;
`;

const MiddleWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  max-width: ${rem(560)};
  margin: 0 ${rem(16)};
  flex-wrap: wrap;

  ${({ theme }) => theme.mediab.l1150`
    max-width: ${rem(460)};
  `}
`;

const RightWrapper = styled(FlexRowCenterVertical)`
  flex-wrap: wrap;
  justify-content: flex-end;
  min-width: ${rem(350)};
  flex: 1 0 auto;

  ${({ theme }) => theme.mediab.l1300`
    min-width: ${rem(200)};
  `}
`;

const SocialIconsWrapper = styled.div`
  display: flex;

  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const LeftWrapper = styled(RightWrapper)`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
`;

const MenuText = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${rem(16)};
  line-height: ${rem(19)};
  font-family: ${({ theme }) => theme.font.primary};
  margin-right: ${rem(32)};
  font-weight: 600;
  text-transform: uppercase;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  position: relative;
`;

const FavoriteTotal = styled(FlexColCenter)`
  width: ${rem(24)};
  height: ${rem(24)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  top: -${rem(12)};
  left: ${rem(14)};
  position: absolute;
  font-size: ${rem(14)};
`;

const CategoryWrapper = styled.div`
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${rem(16)} ${rem(32)};
  padding: ${rem(32)} ${rem(24)};
`;

export default withRouter(DesktopHeader);
