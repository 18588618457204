import React, { useRef, useEffect } from 'react';

/* Hook that alerts clicks outside of the passed ref */
function useOutsideAlerter(ref, handleFunction) {
  /* Alert if clicked on outside of element */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      handleFunction();
      // alert('You clicked outside of me!');
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

interface Props {
  children: any;
  handleFunction: any;
  style?: any;
}

const OutsideClick = ({ children, handleFunction, style }: Props) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handleFunction);

  return (
    <div style={style} ref={wrapperRef}>
      {children}
    </div>
  );
};

export default OutsideClick;
