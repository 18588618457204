import { prop } from 'eshop-defaults/lib';
import { resolveCategoryShortUrl } from 'eshop-defaults/lib/utilities/selectors';
import { capitalizeFirstLetter, stripHtmlTags } from '.';
import { getImagePath, isWritable } from './product';

export function isEmptyObj(obj) {
  if (obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }
  return true;
}

export function getCmsMetaTags(contentData: any) {
  const content = prop(contentData, 'json_content');
  if (!content) {
    return {};
  }

  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }
  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }
  if (
    !metaTags ||
    !metaTags.og_description ||
    Object.keys(metaTags.og_description).length === 0
  ) {
    metaTags.description = content
      ? content.annotation
        ? stripHtmlTags(content.annotation)
        : content.anotation
        ? stripHtmlTags(content.anotation)
        : ''
      : '';
  }

  const ogTitle =
    prop(content, 'meta.og_title') ||
    prop(content, 'meta.title') ||
    prop(content, 'name');
  if (ogTitle) {
    metaTags.og_title = ogTitle;
  }

  const ogDescription =
    prop(content, 'meta.og_description') ||
    prop(content, 'meta.description') ||
    prop(content, 'annotation') ||
    prop(content, 'anotation');
  if (ogDescription) {
    metaTags.og_description = ogDescription ? stripHtmlTags(ogDescription) : '';
  }

  const ogImage = prop(content, 'meta.og_image') || prop(contentData, 'image');
  if (ogImage) {
    metaTags.og_image = getImagePath(ogImage, { width: 1000, height: 1000 });
  }

  return metaTags;
}

export function getEbookMetaTags(ebook: any) {
  const content = prop(ebook, 'json_content');
  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }

  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }

  return metaTags;
}

export function getAuthorMetaTags(author: any) {
  const content = prop(author, 'json_content');
  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }

  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }

  return metaTags;
}

export function getProductMetaTags(product) {
  if (!product || Object.keys(product).length === 0) {
    return {};
  }
  const productPublish = prop(product, 'publish', []).find(
    p => p.lang_id === 'sk',
  );
  let metaTags: any = {};
  if (productPublish && productPublish.content.id !== null) {
    metaTags = prop(productPublish, 'content.json_content.meta');

    if (isWritable(metaTags, 'title')) {
      if (metaTags.title && metaTags.title.length) {
        metaTags.title = product.name;
      }

      const productName = capitalizeFirstLetter(
        prop(product, 'name', '').toLowerCase(),
      );
      const productPrice = prop(product, 'main_good.final_price', 0).toFixed(2);
      const productDescription = stripHtmlTags(
        prop(product, 'publish.0.content.json_content.short_description', ''),
      );
      const productMetaDescription = `${productName} - Skladom ✓ Doručenie 1-2 dni ✓ Kúpte za akciovú cenu ${productPrice} € na e-shope ZlatáPriadka.sk ${productDescription}`;
      metaTags.description =
        productMetaDescription.length > 300
          ? `${productMetaDescription.substr(0, 300)}...`
          : productMetaDescription;
    }
  }

  if (isWritable(metaTags, 'title')) {
    metaTags.keywords = prop(product, 'name', '');

    metaTags.og_title =
      prop(product, 'publish.0.content.json_content.meta.og_title', '') ||
      prop(product, 'name', '');

    metaTags.og_type =
      prop(product, 'publish.0.content.json_content.meta.og_type', '') ||
      'website';

    metaTags.og_description =
      prop(product, 'publish.0.content.json_content.meta.og_description', '') ||
      stripHtmlTags(
        prop(product, 'publish.0.content.json_content.short_description', ''),
      );

    metaTags.og_url =
      prop(product, 'publish.0.content.json_content.meta.og_url', '') ||
      `${process.env.REACT_APP_BASE_URL}/${prop(product, 'url')}`;

    if (prop(product, 'picture')) {
      metaTags.og_image =
        prop(product, 'publish.0.content.json_content.meta.og_image', '') ||
        getImagePath(prop(product, 'picture'), { width: 1005, height: 1005 });
    }

    metaTags.og_image_width = 1005;
    metaTags.og_image_height = 1005;
  }

  return metaTags;
}

export function getCategoryMetaTags(category) {
  if (!category) {
    return null;
  }
  // const content = prop(category, 'content', {});
  const metaTags: any = {};

  // if (content && content.id !== null) {
  //   const tags = prop(content.json_content, 'meta');
  //   if (tags && !isEmptyObj(tags)) {
  //     metaTags = tags;
  //   }
  // }

  if (!metaTags.title || isEmptyObj(metaTags.title)) {
    metaTags.title =
      prop(category, 'publish.0.content.json_content.meta.title') ||
      prop(category, 'publish.0.category_name') ||
      prop(category, 'content.0.name', prop(category, 'category_name', '')) ||
      prop(category, 'category_name', '');
  }

  if (!metaTags.description || isEmptyObj(metaTags.description)) {
    // metaTags.description =
    //   prop(category, 'publish.0.content.json_content.meta.description') ||
    //   prop(category, 'publish.0.category_description') ||
    //   prop(category, 'content.0.json_content.meta.description', '') ||
    //   stripHtmlTags(prop(category, 'content.0.json_content.body', ''));
    const categoryName = prop(category, 'category_name', '');
    metaTags.description = `${categoryName} s najširšou ponukou. Tovar skladom ✓ Akciové ceny ✓ Novinky ✓ Doručenie 1-2 dni ✓ ${categoryName} z e-shopu ZlatáPriadka.sk`;
  }

  if (!metaTags.keywords || isEmptyObj(metaTags.keywords)) {
    metaTags.keywords =
      prop(category, 'publish.0.content.json_content.meta.keywords') ||
      prop(category, 'content.0.json_content.meta.keywords', '') ||
      prop(category, 'category_name', '');
  }

  metaTags.og_title =
    prop(category, 'publish.0.content.json_content.meta.og_title') ||
    prop(category, 'publish.0.category_name') ||
    prop(category, 'content.0.json_content.meta.og_title', '') ||
    prop(category, 'category_name', '');

  metaTags.og_type =
    prop(category, 'publish.0.content.json_content.meta.og_type ') ||
    prop(category, 'content.0.json_content.meta.og_type', '') ||
    'website';

  metaTags.og_description =
    prop(category, 'publish.0.content.json_content.meta.og_description') ||
    prop(category, 'publish.0.category_description') ||
    prop(category, 'content.0.json_content.meta.og_description', '') ||
    stripHtmlTags(prop(category, 'content.0.json_content.body', ''));

  metaTags.og_url =
    prop(category, 'publish.0.content.json_content.meta.og_url') ||
    prop(category, 'content.0.json_content.meta.og_url', '') ||
    `${process.env.REACT_APP_BASE_URL}${resolveCategoryShortUrl(
      prop(category, 'category_id'),
      prop(category, 'url'),
    )}`;

  metaTags.og_image =
    prop(category, 'publish.0.content.json_content.meta.og_image') ||
    prop(category, 'content.0.json_content.meta.og_image', '') ||
    getImagePath(prop(category, 'image'), { width: 1005, height: 1005 });

  metaTags.og_image_width = 1005;
  metaTags.og_image_height = 1005;

  return metaTags;
}

export function getFooterArticleMetaTags(article) {
  console.log({ article });
  // const content = prop(category, 'content', {});
  const metaTags: any = {};
  // if (content && content.id !== null) {
  //   const tags = prop(content.json_content, 'meta');
  //   if (tags && !isEmptyObj(tags)) {
  //     metaTags = tags;
  //   }
  // }

  if (article && (!metaTags.title || isEmptyObj(metaTags.title))) {
    metaTags.title = article.name;
  }

  return metaTags;
}
