import React, { useState } from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';
import { Link } from 'react-router';
import { PrimaryButton } from '../_helpers/Default/Buttons';
import { PrimaryInput } from '../_helpers/Default/Inputs';
import API from '../../services/API';
import { Loader } from '../_helpers/Loader/Loader';
import {
  ZlataPriadkaTitle as Title,
  ZlataPriadkaTitleMedium as TitleMedium,
  ZlataPriadkaTextP as TextP,
  ZlataPriadkaCardBlog as BlogCard,
  ZlataPriadkaFormInput as FormInput,
  ZlataPriadkaButton as Button,
  ZlataPriadkaSvgIcon as SvgIcon,
  ZlataPriadkaIconType as IconType,
} from 'eshop-defaults';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [sendingIsDisabled, setSendingIsDisabled] = React.useState(true);

  const validateEmail = email => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setEmail(value);
    if (!validateEmail(value)) {
      setSendingIsDisabled(true);
    } else {
      setSendingIsDisabled(false);
    }
  };

  const submitNewsletter = async e => {
    e.preventDefault();
    setIsFetching(true);

    const subscribe = await API.subscribeNewsletter(
      { engine: 'mailchimp' },
      {
        email,
        first_name: '',
        last_name: '',
        source: '',
        status: '',
      },
    );

    setIsFetching(false);
    if (subscribe && email) {
      setIsSubscribed(true);
    }
  };

  return (
    <Wrapper>
      {isSubscribed ? (
        <SuccessfulWrapper>
          <SuccessIcon
            icon={IconType.check}
            width={24}
            height={24}
            viewBox="0 0 16 16"
          />
          <SuccessfulText>
            {__('Prihlásenie na odber noviniek bolo úspešné.')}
          </SuccessfulText>
        </SuccessfulWrapper>
      ) : (
        <Newsletter>
          <NewsletterTitle>{__('Novinky na váš e-mail')}</NewsletterTitle>
          <FormWrapper>
            <FormInput
              type="email"
              placeholder={__('Sem napíšte váš e-mail')}
              onChange={handleChange}
              value={email}
              name="email"
              maxWidthPx={311}
              maxWidthMobilePx={311}
              marginBottom={0}
            />
            <SubmitButton
              onClick={submitNewsletter}
              disabled={sendingIsDisabled}
            >
              {__('Odoberať')}
            </SubmitButton>
          </FormWrapper>
          <NewsletterText>
            Uvedením svojej e-mailovej adresy súhlasím so zasielaním noviniek,
            akcií a špeciálnych ponúk na môj e-mail a som poučený o svojich
            právach dostupných
            <BaseLink
              target="_blank"
              to={'/ochrana-osobnych-udajov'}
              title={__('Ochrana osobných údajov')}
            >
              tu
            </BaseLink>
            .
          </NewsletterText>
        </Newsletter>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Newsletter = styled.article`
  max-width: ${rem(700)};
  margin: 0 auto;
  text-align: center;

  ${({ theme }) => theme.mediab.m580`
    margin-top: ${rem(32)};
  `}
`;

const NewsletterTitle = styled(TitleMedium)`
  font-size: ${rem(30)};
  line-height: ${rem(39)};
  text-transform: uppercase;
  margin-bottom: ${rem(32)};
  text-align: center;
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
    flex-direction: column;

    > div {
      width: 100% !important;
    }
  `}
`;

const SubmitButton = styled(Button)`
  margin-left: ${rem(8)};

  ${({ theme }) => theme.mediab.m580`
    max-width: ${rem(311)};
    width: 100%;
    margin-left: 0;
    margin-top: ${rem(8)};
  `}
`;

const NewsletterText = styled(TextP)`
  font-size: ${rem(14)};
  line-height: ${rem(21)};
  color: ${({ theme }) => rgba(theme.colors.textPrimary, 0.56)};
`;

const BaseLink = styled(Link)`
  display: inline-block;
  margin-left: ${rem(5)};
  color: inherit;
  text-decoration: underline;
`;

const SuccessfulWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const SuccessfulText = styled(TextP)``;

const SuccessIcon = styled(SvgIcon)`
  margin-right: ${rem(8)};
`;

export default NewsletterForm;
