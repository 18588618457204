import * as React from 'react';
import { __, __r } from 'react-i18n';
import styled, { withTheme } from 'styled-components';
import { rem, rgba } from 'polished';
import {
  FlexRowCenter,
  ZlataPriadkaProductList as ProductList,
  ZlataPriadkaSvgIcon as SvgIcon,
  ZlataPriadkaIconType as IconType,
  ZlataPriadkaCategoryCard as CategoryCard,
  ZlataPriadkaCard as CategoryLink,
  ZlataPriadkaCardBlog as CardBlog,
  BlogPreview,
  ZlataPriadkaLoaderWrapper as LoaderWrapper,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import MetaTags from '../_helpers/MetaTags/MetaTags';
import { prop, getCompanyInfoData, stripHtmlTags } from '../../utilities';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import { css } from 'styled-components';
import SliderComponent from '../_helpers/Slider/Slider';
import { resolveCategoryShortUrl } from 'eshop-defaults/lib/utilities/selectors';
import { theme } from '../../theme/theme';
import { setIsCategoriesInMenuShown } from '../../containers/App/actions';
import { ButtonLink } from 'eshop-defaults/lib/components/ZlataPriadka/General/Button';
import {
  addItemToFavourite,
  loadFavouriteProducts,
  removeItemFromFavourite,
} from '../../containers/Home/actions';
import { push } from 'react-router-redux';
import { getCategoryImagePath } from '../../utilities/category';
interface Props {
  dispatch: any;
  theme: any;
  categories: any[];
  productCategoriesIsFetching;
  newProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  saleProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  user: any;
  firstCategoryId: number;
  adBanner: any;
  basicData: ThenArg<typeof API.loadSitemap>;
  secondData: ThenArg<typeof API.loadSitemap>;
  homeCompanyInfo: any[];
  newArticles: {
    articles: ThenArg<typeof API.loadArticles>;
    total: number;
  };
  favouriteProducts: any;
}

function Home(props: Props) {
  const {
    dispatch,
    productCategoriesIsFetching,
    categories,
    favouriteProducts,
    user,
  } = props;

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.NONE));
  }, [dispatch]);

  const showMenu = () => {
    dispatch(setIsCategoriesInMenuShown(true));
  };

  const toggleFavourite = async (productId, newVal = null) => {
    if (user) {
      if (!newVal) {
        await dispatch(removeItemFromFavourite(user.id!, productId));
      } else {
        await dispatch(addItemToFavourite(user.id!, productId));
      }

      dispatch(loadFavouriteProducts(user.id?.toString() as string, true));
    } else {
      await dispatch(push(__r('routes:moj-ucet', '/moj-ucet')));
    }
  };

  const renderBannerSlider = () => {
    if (!props.adBanner) return null;
    const slides = Array.isArray(props.adBanner)
      ? props.adBanner
      : [props.adBanner];

    return (
      <BannerWrapper>
        <BannerContainer>
          <BannerSliderWrapper>
            <Slider slides={slides} type={'photogallery'} />
          </BannerSliderWrapper>
        </BannerContainer>
      </BannerWrapper>
    );
  };

  const renderCategories = () => {
    const placeholderImgSrc = '/images/zlatapriadka/placeholder.png';
    return (
      <CategoryWrapper>
        {categories && categories.length > 0 && (
          <>
            <CategoryLinksContainer>
              <CategoryLink
                bgColor={theme.colors.primary}
                size={2}
                title={categories[0].category_name}
                url={resolveCategoryShortUrl(
                  categories[0].category_id,
                  categories[0].url,
                )}
                imageSrc={
                  categories[0].additional_image
                    ? getCategoryImagePath(
                        categories[0].additional_image,
                        { width: 800, height: 600 },
                        false,
                        true,
                        true,
                      )
                    : ''
                }
                nonWebpImage={
                  categories[0].additional_image
                    ? getCategoryImagePath(
                        categories[0].additional_image,
                        { width: 800, height: 600 },
                        false,
                        false,
                        true,
                      )
                    : ''
                }
              />
              <CategoryLink
                size={1}
                title={categories[1].category_name}
                url={resolveCategoryShortUrl(
                  categories[1].category_id,
                  categories[1].url,
                )}
                imageSrc={
                  categories[1].additional_image
                    ? getCategoryImagePath(
                        categories[1].additional_image,
                        { width: 800, height: 400 },
                        false,
                        true,
                        true,
                      )
                    : ''
                }
                nonWebpImage={
                  categories[1].additional_image
                    ? getCategoryImagePath(
                        categories[1].additional_image,
                        { width: 800, height: 400 },
                        false,
                        false,
                        true,
                      )
                    : ''
                }
              />
              <CategoryLink
                size={1}
                title={categories[2].category_name}
                url={resolveCategoryShortUrl(
                  categories[2].category_id,
                  categories[2].url,
                )}
                imageSrc={
                  categories[2].additional_image
                    ? getCategoryImagePath(
                        categories[2].additional_image,
                        { width: 800, height: 400 },
                        false,
                        true,
                        true,
                      )
                    : ''
                }
                nonWebpImage={
                  categories[2].additional_image
                    ? getCategoryImagePath(
                        categories[2].additional_image,
                        { width: 800, height: 400 },
                        false,
                        false,
                        true,
                      )
                    : ''
                }
              />
            </CategoryLinksContainer>
            <CategoryCardsContainer
              offset={categories.length === 0 ? 0 : `16px`}
            >
              {categories.map((c, index) => (
                <>
                  {c && index >= 3 ? (
                    <CategoryCard
                      categoryName={c.category_name}
                      name={c.category_name}
                      url={resolveCategoryShortUrl(c.category_id, c.url)}
                      img={
                        c.image
                          ? getCategoryImagePath(
                              c.image,
                              {
                                width: 56,
                                height: 56,
                              },
                              false,
                              false,
                              false,
                            )
                          : placeholderImgSrc
                      }
                    />
                  ) : null}
                </>
              ))}
            </CategoryCardsContainer>
          </>
        )}
      </CategoryWrapper>
    );
  };

  const renderBlogPreview = () => {
    const { newArticles } = props;

    if (!newArticles.articles) {
      return null;
      // return <LoaderWrapper height={270} />;
    }
    const articles = prop(newArticles, 'articles', []);

    return (
      <BlogWrapper>
        <BlogPreview
          title={'Blog'}
          description={
            'Čítajte články na našom blogu a dozviete sa množstvo skvelých vecí'
          }
          redirectUrl={'/blog'}
        />
        {articles && articles.length > 0 && (
          <BlogCardsWrapper>
            <CardBlog
              first={true}
              title={articles[0].name}
              description={stripHtmlTags(
                prop(articles[0], 'json_content.anotation'),
              )}
              url={articles[0].url}
              imageSrc={articles[0].image ? articles[0].image : ''}
            />
            {articles.length > 1 && (
              <CardBlog
                title={articles[1].name}
                description={stripHtmlTags(
                  prop(articles[1], 'json_content.anotation'),
                )}
                url={articles[1].url}
                imageSrc={articles[1].image ? articles[1].image : ''}
              />
            )}
          </BlogCardsWrapper>
        )}

        <MobileBlogButtonLink to={'/blog'}>
          {__('Prejsť na blog')}
        </MobileBlogButtonLink>
      </BlogWrapper>
    );
  };

  const renderProducts = () => {
    const { newProducts, saleProducts } = props;

    const isFetchingSaleProducts = prop(saleProducts, 'isFetching');
    const isFetchingNewProducts = prop(newProducts, 'isFetching');

    if (
      productCategoriesIsFetching ||
      (!isFetchingSaleProducts &&
        !isFetchingNewProducts &&
        !newProducts.productsData &&
        !saleProducts.productsData &&
        !categories)
    ) {
      return <LoaderWrapper height={270} />;
    }

    const newProductsData = prop(newProducts, 'productsData', {});
    const saleProductsData = prop(saleProducts, 'productsData', {});

    const newProductsShow: ThenArg<
      typeof API.searchProducts
    >['products'] = newProductsData
      ? prop(newProductsData, 'products', [])
      : [];

    const saleProductsShow: ThenArg<
      typeof API.searchProducts
    >['products'] = saleProductsData
      ? prop(saleProductsData, 'products', [])
      : [];

    const allProducts = [...newProductsShow, ...saleProductsShow];

    return (
      <React.Fragment>
        <ListWrapper>
          <ProductList
            isFetching={isFetchingNewProducts || isFetchingSaleProducts}
            products={allProducts}
            visible={true}
            isElasticCategory={false}
            toggleFavourite={toggleFavourite}
            favouriteProducts={favouriteProducts}
          />
        </ListWrapper>
      </React.Fragment>
    );
  };

  const renderCompanyInfo = () => {
    const { homeCompanyInfo, adBanner } = props;
    if (!adBanner) {
      return null;
    }
    let { output } = getCompanyInfoData(homeCompanyInfo);

    return (
      <CompanyInfoWrapper>
        <CompanyInfoElement>
          <SvgIcon
            width={48}
            height={48}
            viewBox={'0 0 48 48'}
            marginRight={16}
            icon={IconType.ball}
            fill={'#07151C'}
          />
          {output[0]}
        </CompanyInfoElement>
        <CompanyInfoElement>
          <SvgIcon
            width={48}
            height={48}
            viewBox={'0 0 48 48'}
            marginRight={16}
            icon={IconType.calendar}
            fill={'#07151C'}
          />
          {output[1]}
        </CompanyInfoElement>
        <CompanyInfoElement>
          <SvgIcon
            width={48}
            height={48}
            viewBox={'0 0 48 48'}
            marginRight={16}
            icon={IconType.heart}
            fill={'#07151C'}
          />
          {output[2]}
        </CompanyInfoElement>
      </CompanyInfoWrapper>
    );
  };

  const renderCategoryButton = () => {
    return (
      <CategoryButton onClick={showMenu}>
        <SvgIcon
          icon={IconType.listMenu}
          alt="Otvoriť menu"
          cursor={'pointer'}
          viewBox="0 0 24 24"
          marginRight={12}
          width={24}
          height={24}
        />
        {__('Ponuka tovaru')}
      </CategoryButton>
    );
  };

  return (
    <>
      {renderBannerSlider()}
      <MainWrapper>
        <MetaTags
          tags={{
            title: __('Vlna na pletenie, Látky, Galantéria'),
            description: __(
              'Vlna na pletenie, látky, galantéria za najlepšie ceny. Tovar SKLADOM ✓ Expedícia do 1-2 dní ✓ Akciový VÝPREDAJ ✓ Ochotne pomôžeme s výberom.',
            ),
            keywords: __('Pletiaca Vlna, Priadza, Látky, Galantéria'),
          }}
        />
        <TopWrapper>{renderCompanyInfo()}</TopWrapper>
        <BottomWrapper>
          {renderCategories()}
          {renderCategoryButton()}
          {renderProducts()}
          {renderBlogPreview()}
        </BottomWrapper>
      </MainWrapper>
    </>
  );
}
const MainWrapper = styled.div`
  width: 100%;
`;

const TopWrapper = styled.div`
  width: 100%;
`;

const BottomWrapper = styled.div`
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  margin: 0 auto;
  padding: 0 ${rem(24)};

  ${({ theme }) => theme.mediab.l925`
    padding: 0 ${rem(16)};
  `}
`;

const CategoryWrapper = styled.div`
  overflow: hidden;
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const CategoryButton = styled.button`
  font-size: ${rem(16)};
  line-height: ${rem(19)};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${rem(56)};
  background: ${({ theme }) => theme.colors.primary};
  border: none;
  box-shadow: none;
  max-width: ${rem(288)};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: ${rem(12)};
  border-radius: ${rem(4)};
  display: none;

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) => theme.mediab.l925`
    display: block;
  `}
`;

const BlogWrapper = styled.div`
  margin-bottom: ${rem(80)};
  margin-top: ${rem(80)};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mediab.l925`
    flex-direction: column;
  `}

  ${({ theme }) => theme.mediab.m580`
    margin-top: ${rem(56)};
    margin-bottom: ${rem(56)};
  `}
`;

const BlogCardsWrapper = styled.div`
  display: inline-flex;
  margin-left: ${rem(-16)};
  margin-right: ${rem(-16)};
  flex: 1 1 auto;
  justify-content: flex-end;

  ${({ theme }) => theme.mediab.m580`
    margin: 0;
    flex-direction: column;
  `}
`;

const ListWrapper = styled.div`
  margin-top: ${rem(80)};
  margin-bottom: ${rem(80)};

  ${({ theme }) => theme.mediab.l925`
    width: 90%;
    margin: 0 auto;
  `}
`;

const CompanyInfoWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: space-around;
  align-items: flex-end;
  max-width: ${({ theme }) => `${theme.grid.container.huge}px`};
  width: 100%;
  height: ${rem(96)};
  margin-left: auto;
  margin-right: auto;
  margin-top: -80px;
  margin-bottom: ${rem(56)};
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(8)};
  padding: 0 ${rem(32)};

  ${({ theme }) => theme.mediab.l1620`
    width: 80%;
  `}

  ${({ theme }) => theme.mediab.l1300`
    margin-top: 0;
  `}

  ${({ theme }) => theme.mediab.l925`
    margin-top: 0;
    width: 100%;
    height: initial;
    margin-top: ${rem(16)};
    margin-bottom: ${rem(32)};
  `}
`;

const companyInfoElementLikeCss = css`
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  text-align: left;
  font-family: ${({ theme }) => theme.font.primary};
  max-width: ${rem(508)};
  height: max-content;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediab.l925`
    font-size: ${rem(12)};
    line-height: ${rem(16)};
  `}
`;

const CompanyInfoElement = styled(FlexRowCenter)`
  ${companyInfoElementLikeCss}
  color: ${({ theme }) => theme.colors.primaryFontColor};

  svg {
    fill: ${({ theme }) => theme.colors.primary};
    }

  :last-of-type {
    margin-right: 0;
  }

  ${({ theme }) => theme.mediab.l925`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    svg {
      margin-bottom: ${rem(8)};
      margin-right: 0;
      width: ${rem(32)};
      height: ${rem(32)};
    }
  `}
`;

export const CategoryLinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(50%, 1fr));
  grid-template-rows: ${rem(272)} ${rem(264)};
  grid-gap: ${rem(32)};
  margin-bottom: ${rem(80)};

  ${({ theme }) => theme.mediab.m810`
    grid-template-columns: repeat(1, minmax(100%, 1fr));
    grid-template-rows: 1fr;
    grid-gap: ${rem(16)};
    margin-bottom: ${rem(56)};
  `}
`;

export const CategoryCardsContainer = styled.div<{ offset: number }>`
  padding-bottom: ${props => props.offset};
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, minmax(328px, 1fr));
  grid-gap: ${rem(32)};
  grid-row-gap: ${rem(16)};

  ${({ theme }) => theme.mediab.l1150`
    grid-gap: ${rem(16)};
  `}

  ${({ theme }) => theme.mediab.s450`
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    display: none;
  `}

  ${({ theme }) => theme.mediab.s450`
   display: none;
  `}
`;

const BannerWrapper = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
`;

const BannerContainer = styled.div`
  width: 100%;
`;

const BannerSliderWrapper = styled.div`
  width: 100%;
`;

const MobileBlogButtonLink = styled(ButtonLink)`
  display: none;

  ${({ theme }) => theme.media(0, 'sm')} {
    display: inline-flex;
    width: 100%;
    max-width: ${rem(288)};
    text-align: center;
    margin: 0;
  }
`;

export const Slider = styled(SliderComponent)``;

export default withTheme(Home);
