import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { prop } from '../../utilities';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import BreadCrumb from '../../containers/BreadCrumb/BreadCrumb';
import {
  ZlataPriadkaTitle as Title,
  ZlataPriadkaCardBlog as BlogCard,
  ZlataPriadkaPagination as Pagination,
} from 'eshop-defaults';
import { __ } from 'react-i18n/lib';
import NewsletterForm from '../Home/NewsletterForm';
import { withRouter, WithRouterProps } from 'react-router';
import { getImagePath } from '../../utilities/product';
import { ReadTooArticles } from './ReadTooArticles';

interface Props {
  dispatch: any;
  posts: any;
  total: any;
  location: any;
}

const Blog = ({
  dispatch,
  posts,
  total,
  location,
}: Props & WithRouterProps) => {
  const catUrl = prop(location, 'pathname');
  const query = prop(location, 'search');
  const urlAttribs = prop(location, 'query');

  const offset = prop(location, 'query.offset', 0);

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.BLOG, null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <BreadCrumb />
      <StyledTitle>{__(`Blog`)}</StyledTitle>
      <PostsList>
        {posts &&
          posts.map((post, index: number) => {
            const { name, anotation } = post.json_content;
            const { url, image } = post;
            return (
              <BlogCard
                key={index}
                title={name}
                description={anotation}
                url={url}
                imageSrc={image}
              />
            );
          })}
      </PostsList>
      {posts && (
        <PaginationWrapper>
          <Pagination
            query={`${catUrl}${`${query}` ? query : '?'}`}
            totalItems={total}
            limit={8}
            offset={offset}
          />
        </PaginationWrapper>
      )}
      <NewsletterForm />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const StyledTitle = styled(Title)`
  font-size: ${rem(36)};
  line-height: ${rem(47)};
  margin-top: ${rem(32)};
  margin-bottom: ${rem(56)};
  text-align: center;
  text-transform: uppercase;

  ${({ theme }) => theme.mediab.l925`
    margin-top: ${rem(16)};
    margin-bottom: ${rem(32)};
  `}
`;

const PostsList = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
`;

const PaginationWrapper = styled.div`
  margin-bottom: ${rem(80)};

  > div {
    margin-top: ${rem(24)};
  }

  ${({ theme }) => theme.mediab.l925`
    margin-bottom: ${rem(56)};
  `}
`;

export default withRouter(Blog);
