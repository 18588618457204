import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FlexRow } from 'eshop-defaults';
import { rem, rgba } from 'polished';
import { __ } from 'react-i18n/lib';
import { loadCMSArticles } from './actions';
import { allArticlesSelector } from './selectors';
import Blog from '../../components/CMS/Blog';
import API, { ThenArg } from '../../services/API';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

interface Props {
  dispatch: any;
  location: any;
  posts: ThenArg<typeof API.loadArticles>;
  offset: number;
}

class BlogCointainer extends React.Component<Props> {
  public constructor(props) {
    super(props);
    this.state = {};
  }

  public static async getInitialProps(parentProps) {
    try {
      const { dispatch, location } = parentProps;
      const {
        query: { offset, subid },
      } = location;
      await dispatch(loadCMSArticles(subid, 8, offset));
      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public render() {
    const { dispatch, location, posts } = this.props;

    return (
      <Wrapper className="container container--wide">
        <MetaTags tags={{ title: 'Blog' }} />
        {posts && posts.articles && (
          <Blog
            dispatch={dispatch}
            posts={posts.articles}
            total={posts.total}
          />
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  margin-bottom: ${rem(56)} !important;

  /* margin-top: ${rem(32)}; */
  padding-top: ${rem(24)};
  padding-left: ${rem(24)};
  padding-right: ${rem(24)};
  flex-direction: column;

  ${({ theme }) => theme.mediab.l1050`
      padding: ${rem(16)} ${rem(16)} 0;
  `}
`;

const mapStateToProps = state => {
  return {
    posts: allArticlesSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'BlogCointainer' })(BlogCointainer),
);
