import * as React from 'react';
import API, { ThenArg } from '../../services/API';
import { connect } from 'react-redux';
import { tokenSelector, titleSelector } from './selectors';
import { langSelector } from '../App/selectors';
import { WithRouterProps } from 'react-router';
import Category from '../../components/Category/Category';
import { connectSsr } from 'ssr-service';
import {
  fetchCategory,
  categoryIsFetchingSelector,
  categoryInfoSelector,
  categoryProductsSelector,
  categoryFilterDataSelector,
  urlAttribsToObject,
  categoryChildrenCategoriesSelector,
} from './categorySlice';
import { cartDataSelector } from '../Cart/cartSlice';
import { productCategoriesSelector } from '../Header/selectors';
import {
  favouriteProductsSelector,
  firstHomeBenefitsSelector,
} from '../Home/selectors';
import { loadFavouriteProducts, loadHomeBenefits } from '../Home/actions';
import { prop } from '../../utilities';

import {
  ga4ViewCategory,
  gaViewCategory,
} from '@bart.sk-ecommerce/react-online-marketing';
import { getGoogleAdsId } from '../../configureTagManager';

export interface CategoryProps {
  location: any;
  category: any;
  parentProps: any;
  loading: boolean;
  dispatch: any;
  lang: string;
  defaultTitle: string;
  token: string;
  isLoading: boolean;
  limit: number;
  sort: string;
  sortDir: string;
  isFetching: boolean;
  offset: number;
  products: any;
  data: ThenArg<typeof API.loadCategory>;
  user: any;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  childrenCategories: any[];
  cart: ThenArg<typeof API.getCart> | null;
  categories: ThenArg<typeof API.loadTree>;
  firstBenefits: any[];
  favouriteProducts: any;
  category_id: number;
}

class CategoryContainer extends React.Component<
  CategoryProps & WithRouterProps
> {
  public static async getInitialProps(parentProps) {
    try {
      const {
        dispatch,
        location,
        params: { category_id: pathCategoryId },
        user,
        category_id,
      } = parentProps;

      const {
        query,
        query: { offset },
      } = location;

      if (category_id) {
        await Promise.all([
          dispatch(fetchCategory(category_id, urlAttribsToObject(query))),
          user && (await dispatch(loadFavouriteProducts(user.id))),
          dispatch(loadHomeBenefits()),
        ]);
      }

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public componentDidMount() {
    if (this.props.products && this.props.data) {
      const { products } = this.props;
      // ga4ViewCategory(
      //   this.props.products,
      //   this.props.data,
      //   getGoogleAdsId(),
      //   'EUR',
      // );
      gaViewCategory(products, getGoogleAdsId());
    }
  }

  public componentDidUpdate(prevProps: CategoryProps) {
    const { products, data } = this.props;
    if (prevProps.products !== products && products && data) {
      // ga4ViewCategory(products, data, getGoogleAdsId(), 'EUR');
      gaViewCategory(products, getGoogleAdsId());
    }
  }

  public render() {
    const {
      dispatch,
      isFetching,
      products,
      data,
      user,
      location,
      filterData,
      childrenCategories,
      cart,
      categories,
      firstBenefits,
      favouriteProducts,
    } = this.props;
    const { category_id } = this.props;

    const favProducts = prop(favouriteProducts, 'productsData.products', []);
    const favProductsIds: number[] = [];
    favProducts.map(p => favProductsIds.push(p.product_id));

    return (
      <Category
        dispatch={dispatch}
        isFetching={isFetching}
        products={products}
        data={data}
        user={user}
        catName={''}
        filterData={filterData}
        childrenCategories={childrenCategories}
        cart={cart}
        categories={categories}
        categoryId={category_id}
        homeCompanyInfo={firstBenefits}
        favouriteProducts={favProductsIds}
      />
    );
  }

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?q=${this.props.location.query.q}`;
  };
}

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    token: tokenSelector(state),
    isFetching: categoryIsFetchingSelector(state),
    products: categoryProductsSelector(state),
    data: categoryInfoSelector(state),
    user: state.auth.user,
    filterData: categoryFilterDataSelector(state),
    childrenCategories: categoryChildrenCategoriesSelector(state),
    cart: cartDataSelector(state),
    categories: productCategoriesSelector(state),
    firstBenefits: firstHomeBenefitsSelector(state),
    favouriteProducts: favouriteProductsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CategoryContainer' })(CategoryContainer),
);
