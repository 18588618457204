import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  ZlataPriadkaFooterLastRow as FooterLastRow,
  ZlataPriadkaFooterFirstRow as FooterFirstRow,
  ZlataPriadkaFooterLogo as FooterLogo,
  ZlataPriadkaFooterContactRow as FooterContactRow,
  getFooterContactRowData,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';

const FooterWrapper = styled.footer`
  z-index: 1;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 100%;

  @media print {
    display: none;
  }
  ${({ theme }) => theme.mediab.m580`
    margin-top: 0;
  `};
`;

const HeurekaWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${rem(40)};
  margin-bottom: ${rem(-16)};

  ${({ theme }) => theme.mediab.m580`
    margin-bottom: 0;
  `};
`;

const RowsWrapper = styled.div``;
interface Props {
  items: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  contactInfo: {
    email: string;
    phone: string;
    facebook: string;
  };
}

interface State {
  breakInnerWidth: number;
}

class Footer extends React.Component<Props, State> {
  public render() {
    const logoSrc = '/images/zlatapriadka/zlatapriadka_logo_vertical.svg';
    const { items, contactInfo }: any = this.props;

    const mappedContatFooterData = getFooterContactRowData(contactInfo);
    return (
      <FooterWrapper>
        <FooterContactRow contacts={mappedContatFooterData} />
        <HeurekaWrapper>
          <img
            width={'170px'}
            alt={''}
            src={'/images/zlatapriadka/heureka_finalista_2022.png'}
          />
        </HeurekaWrapper>
        <RowsWrapper>
          <FooterFirstRow links={items} />
          <FooterLogo logo={logoSrc} />
          <FooterLastRow leftText={__('Copyright © 2021 ZlataPriadka.sk')} />
        </RowsWrapper>
      </FooterWrapper>
    );
  }
}

export default Footer;
