import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { ZlataPriadkaCartDeliveryPart as CartDeliveryPart } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import {
  fetchDeliveryPaymentInfo,
  cartDelPayInfoIsFetchingSelector,
  updateCart,
  cartDelPayInfoByIdSelector,
  cartDelPayInfoIdsSelector,
  cartPostaPickupPointsSelector,
} from './cartSlice';
import {
  ga4AddShippingInfo,
  ga4AddPaymentInfo,
} from '@bart.sk-ecommerce/react-online-marketing';
import { getGoogleAdsId } from '../../configureTagManager';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import PostaModal from '../../components/Cart/PostaModal';

interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getCart> | null;
  isFetching: boolean;
  delAndPayInfoIds: string[];
  delAndPayInfoById: Record<string, ThenArg<typeof API.getDeliveryPaymentInfo>>;
  isFetchingDelAndPayInfo: boolean;
  showProblems: boolean;
  allPostaPickupPoints: any;
}

interface State {
  isPostaModalOpen: boolean;
  selectedPostaPickupPointId: number;
  postaModalTypeId: string;
}

class CartThirdStep extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isPostaModalOpen: false,
      selectedPostaPickupPointId:
        props.data && props.data.posta && props.data.posta.id
          ? props.data.posta.id
          : 0,
      postaModalTypeId: 'BALIKOBOX',
    };
  }

  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      await dispatch(fetchDeliveryPaymentInfo());

      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public onChooseZasielkovna = a => {
    if (a) {
      this.props.dispatch(
        updateCart({
          ...this.props.data,
          delivery: { id: 'ZASIELKOVNA' },
          zasielkovna_id: `${a.id}`,
          zasielkovna_payload: a,
        }),
      );
    }
  };

  public onChoosePosta = point => {
    if (point) {
      this.props.dispatch(
        updateCart({
          ...this.props.data,
          delivery: {
            id:
              this.state.postaModalTypeId === 'BALIKOBOX'
                ? 'BALIKOBOX'
                : 'BALIK_NA_POSTU',
          },
          posta: {
            id: point.id,
            name: point.name,
            zip: point.zip,
            street: point.street,
            number: point.number,
            village: point.village,
            is_balikobox: this.state.postaModalTypeId === 'BALIKOBOX' ? 1 : 0,
          },
        }),
      );
    }
  };

  public openZasielkovna = () => {
    window.Packeta.Widget.pick(
      process.env.REACT_ZASIELKOVNA_API_KEY,
      this.onChooseZasielkovna,
      { country: 'sk', language: 'sk' },
    );
  };

  public openPosta = (id: string) => {
    this.setState({
      isPostaModalOpen: true,
      postaModalTypeId: id,
    });
  };

  public closePosta = () => {
    this.setState({
      isPostaModalOpen: false,
    });
  };

  public handleChangePostaPickupPoint = code => {
    this.setState({
      selectedPostaPickupPointId: code,
    });
  };

  public closePostaWithoutSaving = () => {
    this.setState({
      isPostaModalOpen: false,
    });
  };

  public render() {
    const {
      data,
      isFetching,
      delAndPayInfoIds,
      delAndPayInfoById,
      isFetchingDelAndPayInfo,
      user,
      showProblems,
      allPostaPickupPoints,
    } = this.props;

    const {
      isPostaModalOpen,
      selectedPostaPickupPointId,
      postaModalTypeId,
    } = this.state;

    const { postaItems, postaItemsById, balikoboxItems, balikoboxItemsById } =
      allPostaPickupPoints &&
      allPostaPickupPoints.length > 0 &&
      allPostaPickupPoints[0];

    const pickupPoints =
      postaModalTypeId === 'BALIKOBOX' ? balikoboxItems : postaItems;
    const pickupPointsById =
      postaModalTypeId === 'BALIKOBOX' ? balikoboxItemsById : postaItemsById;

    return (
      <>
        <MetaTags tags={{ title: __('Košík - Doprava a platba') }} />
        <CartDeliveryPart
          data={data}
          isFetching={isFetching}
          delAndPayInfoIds={delAndPayInfoIds}
          delAndPayInfoById={delAndPayInfoById}
          isFetchingDelAndPayInfo={isFetchingDelAndPayInfo}
          updateCart={this.updateCart}
          user={user}
          showProblems={showProblems}
          openZasielkovnaWidget={this.openZasielkovna}
          openPostaPopup={this.openPosta}
        />
        <PostaModal
          isModalVisible={isPostaModalOpen}
          items={pickupPoints && pickupPoints.length > 0 ? pickupPoints : []}
          itemsById={pickupPointsById ? pickupPointsById : []}
          selectValue={selectedPostaPickupPointId}
          defaultSelectValue={
            pickupPoints && pickupPoints.length > 0
              ? {
                  name: `${pickupPoints[0].name}, ${pickupPoints[0].street} ${pickupPoints[0].number}`,
                  value: pickupPoints[0].id,
                }
              : {
                  name: '',
                  value: '',
                }
          }
          handleModalOpen={this.openPosta}
          handleModalClose={this.closePosta}
          handleModalCloseWithoutSaving={this.closePostaWithoutSaving}
          handleSelectChange={this.handleChangePostaPickupPoint}
          handleConfirmButtonClick={this.onChoosePosta}
        />
      </>
    );
  }

  private updateCart = data => {
    this.props.dispatch(updateCart(data, false));
    if (data && data.delivery && !data.payment && window.gtag) {
      ga4AddShippingInfo(data.items, data.delivery.id, getGoogleAdsId(), 'EUR');
    }
    if (data && data.payment && window.gtag) {
      ga4AddPaymentInfo(data.items, data.payment.id, getGoogleAdsId(), 'EUR');
    }
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    delAndPayInfoIds: cartDelPayInfoIdsSelector(state),
    delAndPayInfoById: cartDelPayInfoByIdSelector(state),
    isFetchingDelAndPayInfo: cartDelPayInfoIsFetchingSelector(state),
    allPostaPickupPoints: cartPostaPickupPointsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartThirdStep' })(CartThirdStep),
);
