import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { ZlataPriadkaRegisterForm as RegisterForm } from 'eshop-defaults';
import { withRouter, WithRouterProps } from 'react-router';
import { registerUser } from '../App/actions';
import {
  registrationIsFetchingSelector,
  registrationSuccessMessageSelector,
  registrationErrorMessageSelector,
} from '../App/selectors';
import { onlyPublic } from '../../utilities/auth';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import BreadCrumb from '../../containers/BreadCrumb/BreadCrumb';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

interface Props {
  cartId: string | undefined;
  dispatch: (action: any) => void;
  isFetching: boolean;
  successMessage: string;
  errorMessage: string;
}

interface State {
  error: string;
}

const RegisterFormWrapper = styled.div`
  width: 100%;
  max-width: ${rem(320)};
  margin: ${rem(80)} auto ${rem(80)} auto;

  ${({ theme }) => theme.mediab.l925`
     padding: 0 ${rem(16)};
     margin-top: ${rem(32)};
     margin-bottom: ${rem(56)};
  `}
`;

const Wrapper = styled.div`
  margin-top: ${rem(32)};
`;

class RegisterPage extends React.Component<Props & WithRouterProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  public componentDidMount() {
    this.props.dispatch(setBreadCrumbPath(BreadCrumbType.REGISTRATION, null));
  }

  public render() {
    const { cartId, isFetching, successMessage, errorMessage } = this.props;

    return (
      <Wrapper>
        <BreadCrumb withWrapper={true} />
        <RegisterFormWrapper>
          <MetaTags tags={{ title: 'Registrácia' }} />
          <RegisterForm
            cartId={cartId}
            handleFormSubmit={this.handleFormSubmit}
            isFetching={isFetching}
            successMessage={successMessage}
            errorMessage={errorMessage}
          />
        </RegisterFormWrapper>
      </Wrapper>
    );
  }

  public handleFormSubmit = (email, password, confirmPassword) => {
    this.props.dispatch(registerUser(email, password, confirmPassword, true));
  };

  public handleFacebookLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/facebook?state="${this.props.cartId}"`;
  };

  public handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/google?state="${this.props.cartId}"`;
  };
}

const mapStateToProps = state => {
  return {
    cartId: state.general.lang,
    isFetching: registrationIsFetchingSelector(state),
    successMessage: registrationSuccessMessageSelector(state),
    errorMessage: registrationErrorMessageSelector(state),
  };
};

const withRouterLoginPage = onlyPublic(RegisterPage);
export default connect(mapStateToProps)(withRouter(withRouterLoginPage));
