import React from 'react';
import {
  TextP,
  ZlataPriadkaSvgIcon as SvgIcon,
  ZlataPriadkaIconType as IconType,
  ZlataPriadkaMiniCart as MiniCart,
  ZlataPriadkaButtonSecondary as ButtonSecondary,
} from 'eshop-defaults';
import { rem } from 'polished';
import styled from 'styled-components';
import { __, __r } from 'react-i18n';
import { cartIndexRoute } from '../../utilities/cart';
import OutsideClick from '../_helpers/Default/OutsideClick';
import API, { ThenArg } from '../../services/API';
import { WithRouterProps, withRouter } from 'react-router';
import { setWasAddedToCartModalVisibility } from '../../containers/Cart/cartSlice';
import { prop } from 'eshop-defaults/lib';

interface Props {
  totalPrice: number;
  currency: string;
  dispatch: any;
  cartData: ThenArg<typeof API.getCart>;
  freeDel: number | null;
  addToCart: any;
}
function HeaderCart({
  totalPrice,
  currency,
  cartData,
  dispatch,
  router,
  freeDel,
  addToCart,
}: Props & WithRouterProps) {
  const [isCartVisible, setIsCartVisible] = React.useState(false);
  const toggleCart = () => {
    setIsCartVisible(!isCartVisible);
  };

  const handleToCartButtonClick = () => {
    dispatch(setWasAddedToCartModalVisibility());
    router.push(cartIndexRoute);
  };

  const closeMiniCart = () => {
    setIsCartVisible(false);
  };

  const [itemsCount, setItemsCount] = React.useState(0);
  React.useEffect(() => {
    if (cartData) {
      setItemsCount(cartData.total_items_count);
    }
  }, [cartData]);

  return (
    <>
      <DesktopWrapper>
        <OutsideClick
          style={{ position: 'relative', zIndex: 100 }}
          handleFunction={closeMiniCart}
        >
          <CartLink onClick={toggleCart}>
            <SvgIcon
              icon={IconType.cart}
              alt="Zobraziť košík"
              cursor={'pointer'}
              marginRight={8}
            />
            <TotalPrice>{`${
              prop(cartData, 'items', []).length > 0 && totalPrice
                ? totalPrice.toFixed(2)
                : 0
            } €`}</TotalPrice>
            {isCartVisible && (
              <MiniCartWrapper>
                <MiniCart
                  cartData={cartData}
                  handleToCartButtonClick={handleToCartButtonClick}
                  freeDel={freeDel}
                  addToCart={addToCart}
                />
              </MiniCartWrapper>
            )}
          </CartLink>
        </OutsideClick>
      </DesktopWrapper>
      <MobileWrapper onClick={handleToCartButtonClick}>
        <SvgIcon icon={IconType.cart} alt="Zobraziť košík" cursor={'pointer'} />
        <ItemsCount>{itemsCount}</ItemsCount>
      </MobileWrapper>
    </>
  );
}

const MiniCartWrapper = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  position: absolute;
  top: ${rem(64)};
  right: 0;
  z-index: 99;

  &::before {
    content: '';
    display: block;
    width: ${rem(12)};
    height: ${rem(12)};
    transform: rotate(45deg);
    position: absolute;
    right: ${rem(64)};
    top: ${rem(-6)};
    background: ${({ theme }) => theme.colors.white};
  }
`;

const CartLink = styled(ButtonSecondary)`
  position: relative;
  z-index: 1;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.secondaryOnHover};
    outline: none;
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const TotalPrice = styled(TextP)`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${rem(16)};
  line-height: ${rem(19)};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
`;

const DesktopWrapper = styled.div`
  display: block;

  ${({ theme }) => theme.mediab.m580`
    display: none;
  `}
`;

const MobileWrapper = styled.div`
  display: none;
  position: relative;

  ${({ theme }) => theme.mediab.m580`
    display: block;
  `}

  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const ItemsCount = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${rem(10)};
  line-height: ${rem(18)};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.success};
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
  transform: translate(50%, -50%);

  &::before {
    content: '';
    padding-top: 100%;
    display: block;
  }
`;

export default withRouter(HeaderCart);
