import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  MetaTags,
  prop,
  ZemplinContactPerson as ContactPerson,
  FlexRow,
  ZemplinTitle,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import styled from 'styled-components';
import { rem } from 'polished';
import { fetchContactPersons, contactPersonsSelector } from './contactSlice';
import { getImagePath } from '../../utilities/product';
import { getContactPersonsData } from '../../utilities';

interface Props {
  user: any;
  personsData: ThenArg<typeof API.loadArticles>;
}

class ContactPersonsPageContainer extends React.Component<Props> {
  public static async getInitialProps({ dispatch }) {
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.CONTACT));
      await dispatch(fetchContactPersons());
      return true;
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
      };
    }
  }

  public render() {
    const { personsData } = this.props;
    const persons = prop(personsData, 'articles', []);

    const firstSectionPersons = persons
      .filter(p => p.sitemapIds.includes(114))
      .map(person => {
        const {
          position,
          phone,
          phone2,
          email,
          image,
          name,
        } = getContactPersonsData(person);

        return (
          <ContactPerson
            name={name}
            picture={
              image ? getImagePath(image, { width: 80, height: 80 }) : ''
            }
            position={position}
            phone={phone}
            secondPhone={phone2}
            email={email}
          />
        );
      });
    const secondSectionPersons = persons
      .filter(p => p.sitemapIds.includes(116))
      .map(person => {
        const {
          position,
          phone,
          phone2,
          email,
          image,
          name,
        } = getContactPersonsData(person);

        return (
          <ContactPerson
            name={name}
            picture={
              image ? getImagePath(image, { width: 80, height: 80 }) : ''
            }
            position={position}
            phone={phone}
            secondPhone={phone2}
            email={email}
          />
        );
      });
    const thirdSectionPersons = persons
      .filter(p => p.sitemapIds.includes(118))
      .map(person => {
        const {
          position,
          phone,
          phone2,
          email,
          image,
          name,
        } = getContactPersonsData(person);
        return (
          <ContactPerson
            name={name}
            picture={
              image ? getImagePath(image, { width: 80, height: 80 }) : ''
            }
            position={position}
            phone={phone}
            secondPhone={phone2}
            email={email}
          />
        );
      });

    return (
      <>
        <MetaTags tags={{ title: __('Kontaktné osoby') }} />
        <StyledZemplinTitle>{__('Kontaktné osoby')}</StyledZemplinTitle>
        <ContactPersonWrapper>{firstSectionPersons}</ContactPersonWrapper>
        <SecondaryZemplinTitle>
          {/* {__('Oddelenie spojovacieho materiálu')} */}
        </SecondaryZemplinTitle>
        <ContactPersonWrapper>{secondSectionPersons}</ContactPersonWrapper>
        <SecondaryZemplinTitle>
          {/* {__('Oddelenie vykurovacej techniky')} */}
        </SecondaryZemplinTitle>
        <ContactPersonWrapper>{thirdSectionPersons}</ContactPersonWrapper>
      </>
    );
  }
}

const ContactPersonWrapper = styled(FlexRow)`
  flex-flow: row wrap;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(60)};
  `}
`;

export const StyledZemplinTitle = styled(ZemplinTitle)`
  margin: 0 0 ${rem(32)};
  font-size: ${rem(30)};
  line-height: ${rem(38)};
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.l925`
    margin: ${rem(32)} 0 0;
    font-size: ${rem(24)};
    line-height:${rem(32)};
  `}
`;

export const SecondaryZemplinTitle = styled(ZemplinTitle)`
  font-size: ${rem(24)};
  line-height: ${rem(32)};
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.l925`
    margin: ${rem(12)} 0 0;
    font-size: ${rem(20)};
    line-height:${rem(28)};
  `}
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    personsData: contactPersonsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ContactPersonsPageContainer' })(
    ContactPersonsPageContainer,
  ),
);
