import { arrayToObject, prop } from './index';
import { getCategoryUrl } from './category';

function getHostname(lang): string {
  if (process.env.REACT_APP_BASE_URL && process.env.REACT_APP_BASE_URL_CZ) {
    return lang === 'cz'
      ? process.env.REACT_APP_BASE_URL_CZ
      : `${process.env.REACT_APP_BASE_URL}`;
  }

  return '';
}

interface Breadcrumbs {
  url: string;
  name: string;
}

export function getArticleBreadcrumbMicrodata(article, lang): Breadcrumbs[] {
  const hostname: string = getHostname(lang);
  return [{ name: 'Domov', url: hostname }];
}

export function getCategoryBreadcrumbMicrodata(category, lang): Breadcrumbs[] {
  const breadcrumbList: Breadcrumbs[] = [];
  breadcrumbList.push({
    url: getCategoryUrl(prop(category, 'url', ''), lang),
    name: prop(category, 'category_name', ''),
  });
  const categories = [...prop(category, 'parent_categories', [])];
  if (categories.length > 0) {
    categories.reverse().forEach(cat => {
      breadcrumbList.push({
        url: getCategoryUrl(prop(cat, 'url', ''), lang),
        name: prop(cat, 'category_name', ''),
      });
    });
  }
  return breadcrumbList;
}

export function getAvailability(
  storesInfo: [] = [],
  stockCount: number = 0,
): any {
  if (storesInfo && storesInfo.length > 0) {
    const storesInfoObj = arrayToObject(storesInfo, 'store_id');
    if (
      prop(storesInfoObj, 'internal.quantity') > 0 ||
      prop(storesInfoObj, 'external.quantity') > 0
    ) {
      return 'InStock';
    }
  } else if (stockCount && stockCount > 0) {
    return 'InStock';
  }
  return 'OutOfStock';
}
