import {
  MetaTags,
  ZlataPriadkaLoaderWrapper as LoaderWrapper,
  InfoPanel,
} from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import * as cookie from 'react-cookies';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import {
  loadFooterData,
  setCurrency,
  loadDefaultSettings,
  setLanguage,
  resetToken,
  setTopText,
} from './actions';
import { CURRENCY_COOKIE } from './constants';
import {
  dimmerVisibilitySelector,
  currentThemeSelector,
  topTextSelector,
} from './selectors';
import Dimmer from '../../components/_helpers/Dimmer/Dimmer';
import { loadCategoriesTree } from '../Header/actions';
import Footer from '../Footer/Footer';
import { GlobalStyles } from '../../theme/globalStyles';
import { LogoMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { theme } from '../../theme/theme';
import ContainerHeader from '../Header/Header';
import { rem } from 'polished';
import { USER_COOKIE } from 'react-auth';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { ThemeProvider } from 'styled-components';
import { fetchCart } from '../Cart/cartSlice';
import AddedToCartModal from '../Cart/AddedToCartModal';
import {
  checksRedirect,
  isSSR,
} from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import Cookies from '../../components/Cookies/Cookies';
import API from '../../services/API';
import { prop, stripHtmlTags } from '../../utilities';
import { loadFavouriteProducts, loadHomeBenefits } from '../Home/actions';
import { CART_COOKIE_ID } from '../Cart/helpers';

interface Props {
  user: any;
  lang: string;
  props: any;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  metaTags: {
    description: string;
    keywords: string;
    og_article_author: string;
  };
  dispatch: any;
  menuItems: Array<{
    active: boolean;
    name: string;
    url: string;
    attribs: any;
  }>;
  isError: boolean;
  dimmerVisible: boolean;
  currentTheme: string;
  isLoaded: boolean;
  token: string;
  topText: string;
}

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  min-height: ${rem(600)};
  position: relative;

  ${() => theme.mediab.l925`
     min-height: ${rem(400)};
  `}
`;

class App extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, token, user, topText, cookies } = props;

    try {
      await dispatch(checksRedirect(props.location));

      dispatch(setLanguage('sk'));
      dispatch(setCurrency(cookie.load(CURRENCY_COOKIE)));
      if (!token || !user) {
        if (cookie.load(USER_COOKIE)) {
          await dispatch(loginUser(cookie.load(USER_COOKIE)));
        } else {
          await dispatch(resetToken());
        }
        // if (process.env.NODE_ENV === 'development') {
        //   await dispatch(
        //     loginUser(
        //       '46b6f04e25b99244795d991c32a3ef51824e63abba3ae1b774a0e84744161621a43064f234dceb509ae97f860222f34b1fedea2b89b7799f19ca3c446d9da9568ee3337fa6eed1dbbde29b1ec9b159dd7f0b77b94b1f4a46a8c53456aa13de1faa342b22144eadc91d13a3d18eca61c2135c2abb0b5de1698cc77d886e759fce',
        //     ),
        //   );
        // }
      }

      const cartCookie = prop(cookies, `${CART_COOKIE_ID}`);
      await Promise.all[
        (await dispatch(loadDefaultSettings()),
        await dispatch(loadFooterData()),
        await dispatch(loadCategoriesTree()),
        await dispatch(fetchCart(true, cartCookie)),
        user && (await dispatch(loadFavouriteProducts(user.id))))
      ];

      if (!topText) {
        const text = await API.loadOtherTexts('HEADER');
        text &&
          (await dispatch(
            setTopText(stripHtmlTags(prop(text, 'content.json_content.body'))),
          ));
      }

      return {
        props,
        isLoaded: true,
      };
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
        isLoaded: true,
      };
    }
  }

  public render() {
    const {
      children,
      dimmerVisible,
      socialUrls,
      user,
      currentTheme,
      isLoaded,
      token,
      dispatch,
      topText,
    } = this.props;

    const currentThemeColors =
      !currentTheme || !theme[currentTheme]
        ? {
            primary: theme.colors.primary,
            navSecondary: theme.colors.navSecondary,
            paginationSecondary: theme.colors.paginationSecondary,
          }
        : {
            primary: theme[currentTheme].primary,
            navSecondary: theme[currentTheme].navSecondary,
            paginationSecondary: theme[currentTheme].paginationSecondary,
          };

    const currentThemeColorsFilters =
      !currentTheme || !theme[currentTheme]
        ? {
            color: theme.colors.primary,
            activeBgColor: theme.colors.primary,
            sliderTrackColor: theme.colors.primary,
            trackBorderColor: theme.colors.primary,
          }
        : {
            color: theme[currentTheme].primary,
            activeBgColor: theme[currentTheme].primary,
            sliderTrackColor: theme[currentTheme].primary,
            trackBorderColor: theme[currentTheme].primary,
          };

    return (
      <React.Fragment>
        <GlobalStyles />
        <Helmet />
        <LogoMicrodata
          url={process.env.REACT_APP_BASE_URL as string}
          logo={`${process.env
            .REACT_APP_BASE_URL as string}/images/zlatapriadka/zlatapriadka_logo.svg`}
        />

        <ThemeProvider
          theme={{
            ...theme,
            colors: {
              ...theme.colors,
              ...currentThemeColors,
            },
            categoryFilter: {
              ...theme.categoryFilter,
              ...currentThemeColorsFilters,
            },
          }}
        >
          {this.props.metaTags && (
            <MetaTags
              tags={{
                ...this.props.metaTags,
                themeColor: currentThemeColors.primary,
              }}
            />
          )}
          <InfoPanel text={topText ? topText : ''} />
          <ContainerHeader />
          <FillSpaceWrapper>
            {!isLoaded ? <LoaderWrapper height={300} /> : children}
          </FillSpaceWrapper>
          <Footer socialUrls={socialUrls} />
          {dimmerVisible && <Dimmer height={null} />}
          <AddedToCartModal dispatch={dispatch} />
          {/*{!isSSR() && <Cookies />}*/}
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    socialUrls: state.general.socialUrls,
    metaTags: state.general.metaTags,
    lang: state.general.lang,
    dimmerVisible: dimmerVisibilitySelector(state),
    currentTheme: currentThemeSelector(state),
    topText: topTextSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'App' })(App),
);
