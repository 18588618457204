import * as React from 'react';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  cartDataSelector,
  cartIsFetchingSelector,
  cartErrorSelector,
  updateCart,
  fetchCart,
  freeDelRemainingSelector,
  cartShowProblemsSelector,
} from './cartSlice';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import {
  deliveryAddressesIsFetchingSelector,
  deliveryAddressesSelector,
  fetchDeliveryAddresses,
} from '../MyAccount/myAccountSlice';
import { userSelector, isPriceWithVatSelector } from '../App/selectors';
import API, { ThenArg } from '../../services/API';
import CartLogic from './CartLogic';
import { ZlataPriadkaEmptyCart as EmptyCart } from 'eshop-defaults';
import BreadCrumb from '../../containers/BreadCrumb/BreadCrumb';
import { ga4BeginCheckout } from '@bart.sk-ecommerce/react-online-marketing';
import { getGoogleAdsId } from '../../configureTagManager';
import { prop } from '../../utilities';
import { CART_COOKIE_ID } from './helpers';

interface Props {
  dispatch: (action: any) => void;
  children: any;
  user: any;
  data: any;
  error: { details: { description: string } };
  deliveryAddresses: ThenArg<typeof API.getDeliveryAddresses>;
  isFetching: boolean;
  deliveryAddressesIsFetching: boolean;
  freeDel: number | null;
  isPriceWithVat: boolean;
  showProblems: boolean;
}

class Cart extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const { dispatch, cookies } = props;
    try {
      const cartCookie = prop(cookies, `${CART_COOKIE_ID}`);
      if (props.user) {
        await Promise.all[
          (await dispatch(fetchDeliveryAddresses()),
          await dispatch(fetchCart(false, cartCookie)))
        ];
      } else {
        await dispatch(fetchCart(false, cartCookie));
      }
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public componentDidMount = () => {
    this.props.dispatch(setBreadCrumbPath(BreadCrumbType.CART, null));

    const { pathname } = this.props.location;
    const data = this.props.data;

    if (pathname === '/kosik' && window.gtag && data) {
      ga4BeginCheckout(data.items, getGoogleAdsId(), 'EUR');
    }
  };

  public render() {
    const {
      children,
      data,
      error,
      deliveryAddresses,
      isFetching,
      deliveryAddressesIsFetching,
      dispatch,
      freeDel,
      isPriceWithVat,
      showProblems,
    } = this.props;

    return (
      <>
        {data === null || (data && data.items.length === 0) ? (
          <EmptyCartWrapper>
            <BreadCrumb withWrapper={true} />
            <EmptyCart />
          </EmptyCartWrapper>
        ) : (
          <CartLogic
            key={1}
            handleAcceptTermsChange={this.handleAcceptTermsChange}
            createOrder={this.createOrder}
            dispatch={dispatch}
            data={data}
            isFetching={isFetching}
            error={error}
            deliveryAddresses={deliveryAddresses}
            deliveryAddressesIsFetching={deliveryAddressesIsFetching}
            freeDel={freeDel}
            isPriceWithVat={isPriceWithVat}
            showProblems={showProblems}
          >
            {children}
          </CartLogic>
        )}
      </>
    );
  }

  private handleAcceptTermsChange = e => {
    this.props.dispatch(
      updateCart({ ...this.props.data, terms_accept: e.target.value }),
    );
  };

  private createOrder = async (): Promise<string> => {
    let orderPublicId = '';

    try {
      const res = await API.createOrder(
        this.props.data.id,
        {},
        { ...this.props.data, step: 3 },
      );
      orderPublicId = res.orderPublicId;
    } catch (err) {
      console.log({ err });
    }

    return orderPublicId;
  };
}

const EmptyCartWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: ${rem(24)} 0;

  ${({ theme }) => theme.mediab.m580`
  padding: ${rem(32)} 0 0;
  `}
`;

const mapStateToProps = state => {
  return {
    user: userSelector(state),
    data: cartDataSelector(state),
    isFetching: cartIsFetchingSelector(state),
    error: cartErrorSelector(state),
    deliveryAddresses: deliveryAddressesSelector(state),
    deliveryAddressesIsFetching: deliveryAddressesIsFetchingSelector(state),
    freeDel: freeDelRemainingSelector(state),
    isPriceWithVat: isPriceWithVatSelector(state),
    showProblems: cartShowProblemsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Cart' })(withRouter(Cart)),
);
