import * as React from 'react';
import { __r } from 'react-i18n';
import { withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  OutsideClick,
  ZlataPriadkaMobileNavMenu as MobileNavMenu,
  ZlataPriadkaHeaderLogo as HeaderLogo,
  ZlataPriadkaSearchForm as SearchForm,
  ZlataPriadkaSvgIcon as SvgIcon,
  ZlataPriadkaIconType as IconType,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import HeaderCart from './HeaderCart';
import HeaderProfile from './HeaderProfile';
import { MobileWrapper } from '../_helpers/Default/ResponsiveWrappers';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { MobileNavigation } from './MobileNavigation';

import { CART_ROUTES } from '../../containers/Cart/utilities';
import { setIsCategoriesInMenuShown } from '../../containers/App/actions';

interface Props {
  lang: string;
  // items: ThenArg<typeof API.loadTree>;
  items: any;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  toggleResponsiveCategoryMenu: any;
  searchTerm: string;
  handleSearchTermChange: (e: any) => void;
  redirectToSearchResultsPage: (e: any) => void;
  handleSearchFocus: any;
  hideSearchResultsViewer: () => void;
  resultsDropdownVisible: boolean;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  partialSearchArticles: ThenArg<typeof API.loadArticles>;
  partialSearchArticlesIsFetching: boolean;
  cartData: ThenArg<typeof API.getCart>;
  resetSearchTerm: any;
  user: any;
  partialSearchCategoryProducts: any;
  freeDel: number | null;
  addToCart: any;
  clearSearchInput;
  isCategoriesInMenuShown: boolean;
  changeDimmerVisible: (newVal: boolean) => void;
}

/*****************/

const StyledMobileWrapper = styled(MobileWrapper)`
  width: 100%;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.white};
  z-index: 6;

  box-shadow: 0px 2px 32px #0000000a;
`;

const HeaderWrapper = styled(OutsideClick)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 auto;
  width: 100%;
  align-items: center;

  @media print {
    display: none;
  }
`;

const MobileMenuWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  padding: ${rem(20)} ${rem(16)};
  height: initial;
  justify-content: space-between;
  align-items: center;
`;

const BottomHeaderWrapper = styled.div`
  border-top: ${rem(2)} solid #eeeeee;
  width: 100%;

  position: absolute;
  z-index: 400;
  background-color: white;
`;

const BottomHeader = styled.div`
  width: 100%;
`;

const RightWrapper = styled(FlexRowCenterVertical)``;

const LeftWrapper = styled(RightWrapper)``;

const SearchIconWrapper = styled.div`
  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const MenuIconWrapper = styled.div`
  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }
`;

function MobileHeader({
  searchTerm,
  handleSearchTermChange,
  redirectToSearchResultsPage,
  handleSearchFocus,
  hideSearchResultsViewer,
  resultsDropdownVisible,
  items,
  partialSearchProducts,
  partialSearchCategories,
  partialSearchArticles,
  partialSearchArticlesIsFetching,
  partialSearchProductsIsFetching,
  partialSearchCategoriesIsFetching,
  cartData,
  location,
  resetSearchTerm,
  user,
  partialSearchCategoryProducts,
  dispatch,
  freeDel,
  addToCart,
  clearSearchInput,
  isCategoriesInMenuShown,
  changeDimmerVisible,
}: Props & WithRouterProps) {
  const { items: cartItems, currency, total_items_price, total_price } =
    (cartData as any) || {};

  const [isSearchVisible, setIsSearchVisible] = React.useState(false);
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);

  const logoSrc = '/images/zlatapriadka/zlatapriadka_logo.svg';

  const searchWrapper = React.createRef<HTMLDivElement>();

  const toggleSearch = () => {
    // changeDimmerVisible(!isSearchVisible);
    setIsSearchVisible(!isSearchVisible);
  };

  const closeSearch = () => {
    setIsSearchVisible(false);
    // changeDimmerVisible(false);
  };

  React.useEffect(() => {
    const node = searchWrapper.current;
    if (isSearchVisible && node) {
      const input = node?.querySelector('input');
      if (input) {
        input.focus();
      }
    }
  }, [isSearchVisible, searchWrapper]);

  const toggleMenu = () => {
    document.body.style.overflow = isMenuVisible ? 'visible' : 'hidden';
    setIsMenuVisible(!isMenuVisible);
  };

  const closeMenu = () => {
    document.body.style.overflow = 'visible';
    setIsMenuVisible(false);
    dispatch(setIsCategoriesInMenuShown(false));
  };

  const redirectToSearchResultPage = e => {
    toggleSearch();
    redirectToSearchResultsPage(e);
  };

  return (
    <StyledMobileWrapper>
      <HeaderWrapper handleFunction={closeSearch}>
        <MobileMenuWrapper>
          <LeftWrapper>
            <MenuIconWrapper>
              <SvgIcon
                icon={IconType.listMenuMobile}
                alt="Otvoriť menu"
                cursor={'pointer'}
                marginRight={16}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={toggleMenu}
              />
            </MenuIconWrapper>
            <HeaderLogo logo={logoSrc} />
          </LeftWrapper>
          <RightWrapper>
            <SearchIconWrapper>
              <SvgIcon
                icon={IconType.search}
                alt="Otvoriť vyhľadávanie"
                cursor={'pointer'}
                marginRight={16}
                onClick={toggleSearch}
              />
            </SearchIconWrapper>
            <HeaderCart
              currency={currency}
              cartData={cartData}
              dispatch={dispatch}
              totalPrice={total_price}
              freeDel={freeDel}
              addToCart={addToCart}
            />
          </RightWrapper>
        </MobileMenuWrapper>
        {isSearchVisible && (
          <BottomHeader ref={searchWrapper}>
            <BottomHeaderWrapper>
              <SearchForm
                user={user}
                searchResults={{
                  productsResult: partialSearchProducts,
                  categoriesResult: partialSearchCategories,
                  articlesResult: partialSearchArticles,
                }}
                partialSearchProductsIsFetching={
                  partialSearchProductsIsFetching
                }
                partialSearchCategoriesIsFetching={
                  partialSearchCategoriesIsFetching
                }
                partialSearchArticlesIsFetching={
                  partialSearchArticlesIsFetching
                }
                redirectToSearchResultPage={redirectToSearchResultPage}
                searchTerm={searchTerm}
                handleSearchTermChange={handleSearchTermChange}
                handleFocus={handleSearchFocus}
                hideResultViewer={hideSearchResultsViewer}
                resultsDropdownVisible={resultsDropdownVisible}
                isMobile={true}
                partialSearchCategoryProducts={partialSearchCategoryProducts}
                clearSearchInput={clearSearchInput}
              />
            </BottomHeaderWrapper>
          </BottomHeader>
        )}
        {(isMenuVisible || isCategoriesInMenuShown) && (
          <>
            <MobileNavMenu closeMenu={closeMenu}>
              <MobileNavigation
                closeMenu={closeMenu}
                items={items}
                user={user}
                location={location}
                dispatch={dispatch}
                isCategoriesInMenuShown={isCategoriesInMenuShown}
              />
            </MobileNavMenu>
            <Dimmer height={100} />
          </>
        )}
      </HeaderWrapper>
    </StyledMobileWrapper>
  );
}

export default withRouter(MobileHeader);
