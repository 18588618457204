import * as React from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import { __, __r } from 'react-i18n';
import {
  ZlataPriadkaCartStepper as CartStepper,
  ZlataPriadkaCartSummary as CartSummary,
  FlexRow,
  FlexCol,
  ZlataPriadkaLoaderWrapper as LoaderWrapper,
  Loader,
  ZlataPriadkaInfoText as InfoText,
} from 'eshop-defaults';
import {
  cartIndexRoute,
  cartSecondStepRoute,
  cartThirdStepRoute,
  CART_ROUTES,
} from './utilities';
import {
  updateCart,
  resetCart,
  showProblemsInCart,
  fetchCartSuccess,
  addGiftCard,
  removeGiftCard,
} from './cartSlice';
import API, { ThenArg } from '../../services/API';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import { removeCartCookie } from './helpers';
import { prop } from '../../utilities';
import { useForm } from '../../utilities/hooks';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import * as cookie from 'react-cookies';
import { USER_COOKIE } from 'react-auth';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

const CART_STAGES = [
  { title: __('Nákupný košík'), stepNumber: 1, stepUrl: cartIndexRoute },
  {
    title: __('Osobné údaje'),
    stepNumber: 2,
    stepUrl: cartSecondStepRoute,
  },
  {
    title: __('Dodanie a platba'),
    stepNumber: 3,
    stepUrl: cartThirdStepRoute,
  },
];

interface Props {
  children: any;
  data: any | null;
  isFetching: boolean;
  error: { details: { description: string } };
  deliveryAddresses: any;
  deliveryAddressesIsFetching: boolean;
  dispatch: any;
  handleAcceptTermsChange: (e: any) => void;
  createOrder: () => Promise<string>;
  freeDel: number | null;
  isPriceWithVat: boolean;
  showProblems: boolean;
}

interface Address {
  city: string;
  company: string;
  country: string;
  country_code: string;
  country_id: string;
  person: {
    name: string;
    surname: string;
    email: string;
    phone: string;
  };
  street: string;
  street_number: string;
  zip: string;
}

interface DeliveryAddress extends Address {
  delivery_address_id: number;
}

function CartLogic({
  children,
  location: { pathname },
  router,
  data,
  error,
  isFetching,
  deliveryAddresses,
  deliveryAddressesIsFetching,
  dispatch,
  handleAcceptTermsChange,
  createOrder,
  freeDel,
  isPriceWithVat,
  showProblems,
}: Props & WithRouterProps) {
  const { items } = data || {};
  const [step, setStep] = React.useState(1);
  const [isMovingToNextStep, setIsMovingToNextStep] = React.useState(false);

  const contactInfoInitialState = {
    billing_address: prop(data, 'billing_address'),
    company: prop(data, 'company'),
    delivery_address: prop(data, 'delivery_address'),
    billing_company: prop(data, 'billing_company'),
    use_delivery_address: prop(data, 'use_delivery_address'),
    note: prop(data, 'note'),
  };

  const [cartContactInfo, dispatchCartContactInfo] = useForm<{
    billing_address: Address;
    company: { name: string; ic_dph: string; ico: string; dic: string };
    delivery_address: DeliveryAddress;
    billing_company: boolean;
    use_delivery_address: boolean;
    note: string;
  }>(contactInfoInitialState);

  const handleStepChange = async (newStep: number) => {
    setIsMovingToNextStep(true);
    if (newStep !== step) {
      if (newStep === 4) {
        const res = await API.updateCart(data.id, { vatGroups: 0 }, data);
        if (res && res.problems) {
          const canMoveToNext = !res.problems.some(
            p => p.type === 'availability',
          );
          if (!canMoveToNext) {
            setIsMovingToNextStep(false);
            setStep(1);
            router.push(CART_ROUTES[1]);
          }
          dispatch(fetchCartSuccess({ cart: res }));
        }

        const publicId = await createOrder();

        if (publicId) {
          // remove cart, create order, redirect
          removeCartCookie();
          await dispatch(loginUser(cookie.load(USER_COOKIE)));
          await dispatch(resetCart());
          setIsMovingToNextStep(false);
          router.push(
            `${__r(
              'routes:dokoncena-objednavka',
              '/dokoncena-objednavka',
            )}/${publicId}`,
          );
        }
      } else {
        let canMoveToNext = true;
        if (data) {
          const deliveryAddressPerson =
            cartContactInfo.delivery_address.person &&
            cartContactInfo.delivery_address.person.name &&
            cartContactInfo.delivery_address.person.surname
              ? cartContactInfo.delivery_address.person
              : cartContactInfo.billing_address.person;

          const newData: any = {
            ...data,
            step: newStep,
            billing_address: cartContactInfo.billing_address,
            company: cartContactInfo.company,
            delivery_address: {
              ...cartContactInfo.delivery_address,
              person: {
                ...deliveryAddressPerson,
                email: cartContactInfo.billing_address.person.email,
                phone: cartContactInfo.billing_address.person.phone,
              },
            },
            note: cartContactInfo.note,
            use_delivery_address: cartContactInfo.use_delivery_address,
            billing_company: cartContactInfo.billing_company,
          };

          try {
            const res = await API.updateCart(
              data.id,
              { vatGroups: 0 },
              newData,
            );

            if (res && res.problems) {
              if (newStep === 3) {
                canMoveToNext = !res.problems.some(p => p.type === 'form');
                if (!canMoveToNext) {
                  dispatch(showProblemsInCart(true));
                } else {
                  canMoveToNext = !res.problems.some(
                    p => p.type === 'availability',
                  );
                  if (!canMoveToNext) {
                    dispatch(showProblemsInCart(true));
                    setStep(1);
                    router.push(CART_ROUTES[1]);
                  }
                }
              } else if (newStep === 2) {
                canMoveToNext = !res.problems.some(
                  p => p.type === 'availability',
                );
                if (!canMoveToNext) {
                  dispatch(showProblemsInCart(true));
                  setStep(1);
                  router.push(CART_ROUTES[1]);
                }
              }
              dispatch(fetchCartSuccess({ cart: res }));
              window.scrollTo({
                behavior: 'smooth',
                top: 200,
              });
            }
          } catch (err) {}
        }

        if (canMoveToNext) {
          dispatch(showProblemsInCart(false));
          setStep(newStep);
          router.push(CART_ROUTES[newStep] || cartIndexRoute);
        }

        setIsMovingToNextStep(false);
      }
    }
  };

  React.useEffect(() => {
    let step = 1;
    if (pathname === cartSecondStepRoute) {
      step = 2;
    } else if (pathname === cartThirdStepRoute) {
      step = 3;
    }
    setStep(step);
    const newRoute = CART_ROUTES[step];
    router.push(newRoute || cartIndexRoute);
  }, [pathname, router, dispatch]);

  const handleAddGiftCard = (cardNumber: string) => {
    dispatch(addGiftCard(cardNumber));
  };

  const handleRemoveGiftCard = (cardNumber: string) => {
    dispatch(removeGiftCard(cardNumber));
  };

  if (isFetching && !data) {
    return <LoaderWrapper height={500} />;
  }

  const itemsNotes = prop(data, 'items', []).map(i => i.product_note);
  const productHasPaint = itemsNotes.find(i => i) ? true : false;

  const isEmpty = !items || items.length === 0;
  const childrenWithProps = React.cloneElement(children, {
    data,
    isFetching,
    deliveryAddressesIsFetching,
    deliveryAddresses,
    freeDel,
    isPriceWithVat,
    dispatchCartContactInfo,
    cartContactInfo,
    showProblems,
    productHasPaint,
  });

  return (
    <>
      {!isEmpty && (
        <CartStepper
          steps={CART_STAGES}
          setCartStep={handleStepChange}
          currentStepNumber={step}
          pathname={pathname}
          onStepClick={handleStepChange}
        />
      )}
      <Wrapper className="container container--wide">
        <MetaTags tags={{ title: __('Košík') }} />
        {error && (
          <InfoText
            type={InfoType.ERROR}
            info={`${error && error.details ? error.details.description : ''}`}
          />
        )}

        {isEmpty ? (
          <SummaryContentWrapper>
            {/* {childrenWithProps}{' '} */}
            <Loader
              style={{
                position: isFetching ? 'absolute' : 'initial',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 6,
              }}
              loading={isFetching}
              dim={true}
            />
          </SummaryContentWrapper>
        ) : (
          <SummaryContentWrapper>
            {/* Left Part */}
            <ContentWrapper>{childrenWithProps}</ContentWrapper>
            {/* Right Part */}
            <CartSummary
              handleAcceptTermsChange={handleAcceptTermsChange}
              data={data}
              handleStepChange={handleStepChange}
              step={step}
              isFetching={isFetching || isMovingToNextStep}
              freeDel={freeDel}
              addGiftCard={handleAddGiftCard}
              removeGiftCard={handleRemoveGiftCard}
            />

            {step === 3 && (
              <Loader
                style={{
                  position: isFetching ? 'absolute' : 'initial',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 6,
                  display: isFetching ? 'block' : 'none',
                }}
                loading={isFetching}
                dim={true}
              />
            )}
          </SummaryContentWrapper>
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  padding: ${rem(56)} ${rem(24)};
  position: relative;

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    padding: 0 ${rem(24)} ${rem(32)};
  `}
`;

const SummaryContentWrapper = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  max-width: ${rem(1148)};
  margin: 0 auto;

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
     flex-flow: column;
  `}
`;

const ContentWrapper = styled(FlexCol)`
  width: 100%;
  justify-content: flex-start;
  max-width: ${rem(700)};

  /* ${({ theme }) => theme.mediab.l1300`
    width: initial;
  `} */

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
     margin: 0 auto;
     width: 100%;
  `}
`;

export default withRouter(CartLogic);
