import { rem, rgba } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { formatDate } from '../../utilities';
import {
  ZlataPriadkaTitleMedium as TitleMedium,
  ZlataPriadkaTextP as TextP,
  FlexRow,
  ZlataPriadkaLoaderWrapper as LoaderWrapper,
} from 'eshop-defaults';
import { __ } from 'react-i18n/lib';
import { Link } from 'react-router';
import { getImagePath } from '../../utilities/product';

interface Props {
  readTooArticles: any[];
  isFetching: boolean;
}

export const ReadTooArticles = ({ readTooArticles, isFetching }: Props) => {
  if (isFetching) {
    return <LoaderWrapper height={300} />;
  }

  if (!readTooArticles || readTooArticles.length === 0) {
    return null;
  }

  return (
    <ContentWrapper>
      <StyledTitleMedium>{__(`Prečítajte si tiež`)}</StyledTitleMedium>
      <ArticlesWrapper
        hasOneArticle={readTooArticles && readTooArticles.length === 1}
      >
        {readTooArticles &&
          readTooArticles.map((article, index: number) => (
            <CMSContentWrapper to={`/${article.url}`} key={index}>
              <CMSContent>
                <CMSImage
                  src={
                    article.image
                      ? getImagePath(
                          article.image,
                          {
                            width: 120,
                            height: 90,
                          },
                          false,
                          false,
                          true,
                        )
                      : '/images/zlatapriadka/placeholder.svg'
                  }
                />
                <RightWrapper>
                  <CMSDate>{formatDate(article.created_date)}</CMSDate>
                  <CMSName>{article.name}</CMSName>
                </RightWrapper>
              </CMSContent>
            </CMSContentWrapper>
          ))}
      </ArticlesWrapper>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  margin: ${rem(56)} auto;
  max-width: ${({ theme }) => `${theme.grid.container.base}px`};

  ${({ theme }) => theme.mediab.m580`
    margin-bottom: ${rem(24)}; 
  `}
`;

const StyledTitleMedium = styled(TitleMedium)`
  font-weight: 500;
  line-height: ${rem(39)};
  font-size: ${rem(30)};
  margin-top: 0;
  margin-bottom: ${rem(56)};
  text-transform: uppercase;

  ${({ theme }) => theme.mediab.m580`
    margin-bottom: ${rem(32)}; 
    font-size: ${rem(24)};
    line-height: 32px;
  `}
`;

const ArticlesWrapper = styled.div<{ hasOneArticle: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props =>
    props.hasOneArticle ? 'flex-start' : 'space-around'};
  align-items: center;
`;

const CMSContentWrapper = styled(Link)`
  text-decoration: none;
  width: 50%;

  ${({ theme }) => theme.mediab.l925`
     width: 100%;
  `}
`;

const CMSContent = styled(FlexRow)`
  margin: ${rem(0)} ${rem(0)} ${rem(32)} 0;
  padding-right: ${rem(24)};

  ${({ theme }) => theme.mediab.l925`
     padding: 0;
     margin-bottom: ${rem(24)}; 
     margin-right: 0;
  `}
`;

const CMSImage = styled.div<{ src: string }>`
  width: ${rem(120)};
  height: ${rem(90)};
  margin-right: ${rem(16)};
  border-radius: ${rem(8)};
  background: url(${prop => (prop.src ? prop.src : '')}) no-repeat;
  background-size: cover;
  background-position: center;
  flex: 0 0 ${rem(120)};
`;

const CMSName = styled(TextP)`
  width: 100%;
  font-weight: 500;
  line-height: ${rem(24)};
  margin: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  ${({ theme }) => theme.mediab.l925`
     text-align: left;
  `}

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(14)};
  `}
`;

const CMSDate = styled.p`
  color: ${({ theme }) => rgba(theme.colors.textPrimary, 0.56)};
  font-weight: 300;
  line-height: ${rem(21)};
  font-size: ${rem(14)};
  margin-top: 0;
  margin-bottom: ${rem(10)};
  font-family: ${({ theme }) => theme.font.primary};
`;

const RightWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;
