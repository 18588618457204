import * as React from 'react';
import { connect } from 'react-redux';
import API, { ThenArg } from '../../services/API';
import { prop, formatDate, stripHtmlTags } from '../../utilities';
import styled from 'styled-components';
import { ArticleMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { __ } from 'react-i18n';
import { resolveArticleContentDetails } from '../../utilities/article';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { getCmsMetaTags } from '../../utilities/metatags';
import { getImagePath } from '../../utilities/product';
import { ZlataPriadkaArticle as Article } from 'eshop-defaults';
import { rem } from 'polished';
import ShareWrapper from '../../components/_helpers/Default/ShareWrapper';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { connectSsr } from 'ssr-service';
import BreadCrumb from '../../containers/BreadCrumb/BreadCrumb';
import { ReadTooArticles } from '../../components/CMS/ReadTooArticles';
import {
  allArticlesSelector,
  articleConnectionsIsFetchingSelector,
  articleConnectionsSelector,
} from './selectors';
import { loadArticleConnections, loadCMSArticles } from './actions';
export interface CmsProps {
  sitemap: any;
  sitemapTree: any;
  parentSitemap: any;
  isError: boolean;
  articles: any;
  isArticle: boolean;
  contentData: ThenArg<typeof API.loadArticleDetail>;
  draftContent: boolean;
  lang: string;
  dispatch: (action: any) => void;
  readTooArticles: ThenArg<
    typeof API.loadContentConnections
  >['web_content_connections'];
  isFetchingReadTooArticles: boolean;
  fromSitemap: boolean;
}

class Cms extends React.Component<
  CmsProps,
  { photoIndex: number; isOpen: boolean; galleryPhotos: any[] }
> {
  public static async getInitialProps({
    sitemapId,
    contentId,
    lang,
    dispatch,
  }) {
    if (sitemapId || contentId) {
      try {
        const returnProps: any = {};
        returnProps.isArticle = true;
        returnProps.articles = [];
        let articleDetail;
        let fromSitemap = false;
        if (sitemapId) {
          const sitemap = await API.loadSitemap(
            sitemapId,
            {},
            { xAcceptLanguage: lang },
          );

          articleDetail = sitemap.content;
          articleDetail.url = sitemap.url ? sitemap.url : '';
          dispatch(setBreadCrumbPath(BreadCrumbType.OTHER_CMS, articleDetail));
          fromSitemap = true;
        } else if (contentId) {
          articleDetail = await API.loadArticleDetail(
            contentId,
            {},
            { xAcceptLanguage: lang },
          );
          dispatch(setBreadCrumbPath(BreadCrumbType.CMS, articleDetail));
        }

        returnProps.contentData = articleDetail;
        returnProps.fromSitemap = fromSitemap;

        await dispatch(loadArticleConnections(articleDetail.id));

        return returnProps;
      } catch (exp) {
        console.log(exp);
        return {
          isError: true,
        };
      }
    }
  }

  public constructor(props) {
    super(props);
  }

  public render() {
    const {
      contentData,
      isError,
      readTooArticles,
      isFetchingReadTooArticles,
      fromSitemap,
    } = this.props;
    const lastUpdate = prop(contentData, 'last_update');
    const createdAt = prop(contentData, 'created_date');
    const author = prop(contentData, 'author');
    const article = prop(contentData, 'json_content');
    const photogallery = prop(contentData, 'photogallery');

    let titleImage = '';
    if (photogallery) {
      titleImage = prop(photogallery[0], 'document_path');
    }
    const { name, annotation } = resolveArticleContentDetails(contentData);

    return (
      <>
        {isError && !contentData ? (
          <NotFoundArticle>
            {__('Tento článok nebolo možné nájsť')}
          </NotFoundArticle>
        ) : (
          <>
            <MetaTags tags={getCmsMetaTags(contentData)} />
            <ArticleMicrodata
              headline={name}
              image={
                titleImage
                  ? getImagePath(titleImage, { width: 1920, height: 600 }, true)
                  : ''
              }
              datePublished={
                createdAt ? formatDate(createdAt, 'MM/DD/YYYY') : ''
              }
              description={stripHtmlTags(annotation)}
              dateModified={
                lastUpdate ? formatDate(lastUpdate, 'MM/DD/YYYY') : ''
              }
              author={author}
            />
            <Wrapper className="container container--wide">
              <BreadCrumb />
              <Article
                article={contentData}
                photogallery={photogallery}
                apiUrl={process.env.REACT_APP_API_BASE_URL!}
                author={author}
                fromSitemap={fromSitemap}
                shareComponent={
                  <ShareWrapper
                    title={name}
                    desc={stripHtmlTags(annotation)}
                    withTitle={false}
                    image={titleImage}
                  />
                }
              />
              <ReadTooArticles
                readTooArticles={readTooArticles}
                isFetching={isFetchingReadTooArticles}
              />
            </Wrapper>
          </>
        )}
      </>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  padding: ${rem(24)} ${rem(24)};
`;

const NotFoundArticle = styled.p`
  color: ${({ theme }) => `${theme.colors.textPrimary}`};
  margin: 0 auto;
  padding: ${rem(24)};
  font-size: ${rem(24)};
`;

const mapStateToProps = state => ({
  lang: state.general.lang,
  isFetchingReadTooArticles: articleConnectionsIsFetchingSelector(state),
  readTooArticles: articleConnectionsSelector(state),
});

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CMS' })(Cms),
);
