import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { rem } from 'polished';
import API from '../../services/API';
import { __, __r } from 'react-i18n';
import { withRouter, WithRouterProps } from 'react-router';
import {
  ZlataPriadkaInfoText as InfoText,
  ZlataPriadkaTitle as Title,
  ZlataPriadkaButton as Button,
  ZlataPriadkaFormInput as FormInput,
} from 'eshop-defaults';
import { Loader } from 'eshop-defaults/lib';
import { LogoutButton } from './MyAccount';
import { connectSsr } from 'ssr-service';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { userSelector } from '../App/selectors';
import { InfoType } from 'eshop-defaults/lib/components/ZlataPriadka/General/InfoText';

const Wrapper = styled.div`
  width: 100%;
  /* max-width: ${rem(320)}; */
  padding: 0;
  border-radius: ${rem(4)};

  ${({ theme }) => theme.mediab.l925`
   padding-top: ${rem(24)};
  `}

  > * {
    width: 100%;
  }
`;

const Container = styled.div``;

const StyledTitle = styled(Title)`
  font-size: ${rem(24)};
  line-height: ${rem(31)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: ${rem(32)};
`;

const EmailWrapper = styled.div`
  margin-bottom: ${rem(10)};
  max-width: ${rem(328)};
  width: 100%;
`;

const AuthButtonWrapper = styled.div`
  margin-bottom: ${rem(8)};
`;

const SuccessTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rem(25)};
`;

const ChangePasswordButton = styled(Button)`
  max-width: ${rem(320)};
  width: initial;
  margin-top: ${rem(22)};

  ${({ theme }) => theme.mediab.m580`
    width: 100%;
  `}

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.primaryOnHover};
    outline: none;
  }
`;

interface NewPasswordBoxPropsIO {
  token: string;
  onOk: () => void;
  onSignUpLinkClick: () => void;
  dispatch: (a: any) => void;
  user: any;
}

interface State {
  password: string;
  confirmPassword: string;
  oldPassword: string;
  error: string;
  passwordChanged: boolean;
  fetching: boolean;
}

class ChangePassword extends React.Component<
  NewPasswordBoxPropsIO & WithRouterProps,
  State
> {
  public static async getInitialProps({ dispatch }) {
    dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_CHANGE_PASSWORD));
    return;
  }

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      oldPassword: '',
      error: '',
      passwordChanged: false,
      fetching: false,
    };
  }

  public onSetPasswordClick = async () => {
    const { user } = this.props;
    const { password, confirmPassword, oldPassword } = this.state;
    if (password === confirmPassword) {
      try {
        this.setState({ fetching: true });
        await API.changePassword(
          user.id,
          {},
          {
            new_password: password,
            current_password: oldPassword,
            password_check: confirmPassword,
          },
        );
        this.setState({ passwordChanged: true, fetching: false });
      } catch (exp) {
        this.setState({ error: exp.details.description, fetching: false });
      }
    } else {
      this.setState({ error: 'Heslá sa nezhodujú', fetching: false });
    }
  };

  public onPasswordChanged = e => {
    this.setState({ password: e.target.value });
  };

  public onConfirmPasswordChanged = e => {
    this.setState({ confirmPassword: e.target.value });
  };

  public onOldPasswordChanged = e => {
    this.setState({ oldPassword: e.target.value });
  };

  public onOk = () => {
    this.setState({ passwordChanged: false, error: '' });
    this.props.router.push(
      __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
    );
  };

  public render() {
    const {
      password,
      confirmPassword,
      oldPassword,
      error,
      fetching,
    } = this.state;
    return (
      <Wrapper>
        <Loader loading={fetching}>
          <Container>
            {!this.state.passwordChanged ? (
              <>
                {error ? <InfoText type={InfoType.ERROR} info={error} /> : ''}
                <StyledTitle marginTop={0} marginBottom={32}>
                  {__('Zmena hesla')}
                </StyledTitle>
                <EmailWrapper>
                  <FormInput
                    type="password"
                    label={__('Zadajte staré heslo')}
                    onChange={this.onOldPasswordChanged}
                    value={oldPassword}
                    name="old-password"
                    required={true}
                  />
                </EmailWrapper>
                <EmailWrapper>
                  <FormInput
                    type="password"
                    onChange={this.onPasswordChanged}
                    value={password}
                    name="new-password"
                    required={true}
                    label={__('Zadajte nové heslo')}
                    placeholder={__('Minimálne 6 znakov')}
                  />
                </EmailWrapper>
                <EmailWrapper>
                  <FormInput
                    type="password"
                    label={__('Zadajte nové heslo (ešte raz)')}
                    onChange={this.onConfirmPasswordChanged}
                    value={confirmPassword}
                    name="confirm-password"
                    required={true}
                  />
                </EmailWrapper>
                <AuthButtonWrapper>
                  <ChangePasswordButton onClick={this.onSetPasswordClick}>
                    {__('Uložiť')}
                  </ChangePasswordButton>
                </AuthButtonWrapper>
              </>
            ) : (
              <div>
                <SuccessTextWrapper>
                  {__('Heslo bolo úspešne zmenené')}
                </SuccessTextWrapper>
                <AuthButtonWrapper>
                  <ChangePasswordButton onClick={this.onOk}>
                    {' '}
                    OK{' '}
                  </ChangePasswordButton>
                </AuthButtonWrapper>
              </div>
            )}
          </Container>
        </Loader>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: userSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ChangePassword' })(withRouter(ChangePassword)),
);
