import * as React from 'react';
import { __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { rem } from 'polished';
import { logoutUser } from 'react-auth/lib/containers/Auth/actions';
import { authProtected } from '../../utilities/auth';
import {
  ZlataPriadkaSidebar as Sidebar,
  FlexRow,
  FlexCol,
} from 'eshop-defaults';
import { withRouter, WithRouterProps } from 'react-router';
import { fetchCart, cartIdSelector } from '../Cart/cartSlice';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import BreadCrumb from '../../containers/BreadCrumb/BreadCrumb';
import { loadFavouriteProducts } from '../Home/actions';
import { favouriteProductsSelector } from '../Home/selectors';
import { prop } from '../../utilities';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

interface Props {
  user: any;
  dispatch: any;
  children: any;
  cartId: string;
  favouriteProducts: any;
}

class MyAccount extends React.Component<Props & WithRouterProps> {
  public componentDidMount() {
    this.props.dispatch(
      setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_ORDERS, null),
    );
    const { user } = this.props;
    this.props.dispatch(loadFavouriteProducts(user.id));
  }

  public render() {
    const { user, children } = this.props;
    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags tags={{ title: 'Moj účet' }} />
          <BreadCrumb />
          <ContentWrapper>
            {this.renderSidebar()}
            {user ? children : null}
          </ContentWrapper>
        </Wrapper>
      </>
    );
  }

  public renderSidebar = () => {
    const {
      location: { pathname },
      favouriteProducts,
    } = this.props;

    const products = prop(favouriteProducts, 'productsData.products', []);
    const countOfFavouriteProducts = String(products.length);

    const items = [
      {
        name: 'Moje objednávky',
        url: __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
        active:
          pathname.includes(
            __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
          ) || pathname === __r('routes:moj-ucet', '/moj-ucet'),
      },

      {
        name: 'Moje údaje',
        url: __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
        active: pathname.includes(
          __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
        ),
      },
      {
        name: 'Moje dodacie adresy',
        url: __r(
          'routes:moj-ucet/moje-dodacie-adresy',
          '/moj-ucet/moje-dodacie-adresy',
        ),
        active: pathname.includes(
          __r(
            'routes:moj-ucet/moje-dodacie-adresy',
            '/moj-ucet/moje-dodacie-adresy',
          ),
        ),
      },
      {
        name: 'Obľúbené',
        url: __r('routes:moj-ucet/oblubene', '/moj-ucet/oblubene'),
        active: pathname.includes(
          __r('routes:moj-ucet/oblubene', '/moj-ucet/oblubene'),
        ),
        favouriteCount: countOfFavouriteProducts,
      },
      {
        name: 'Zmena hesla',
        url: __r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla'),
        active: pathname.includes(
          __r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla'),
        ),
      },
    ];

    return (
      <Sidebar
        handleLogout={this.logoutUser}
        items={items}
        withoutLogout={false}
      />
    );
  };

  public logoutUser = async () => {
    const { dispatch, cartId } = this.props;
    await dispatch(
      logoutUser(cartId, () => {
        dispatch(fetchCart(true));
      }),
    );
  };
}

const ContentWrapper = styled(FlexRow)`
  width: 100%;
  flex-grow: 1;
  overflow: visible;
  max-width: initial;

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
  `};

  ${({ theme }) => theme.mediab.m580`
    flex-direction: column;
  `};
`;

const Wrapper = styled(FlexCol)`
  width: 100%;
  padding: ${rem(32)} ${rem(24)} ${rem(56)} ${rem(24)};
  justify-content: flex-start;

  ${({ theme }) => theme.mediab.l925`
    padding: 0 ${rem(16)} ${rem(48)};
  `}
`;

export const LogoutButton = styled.button`
  text-decoration: none;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  transition: filter 0.2s ease, background-color 0.2s ease;

  &:disabled {
    cursor: not-allowed;

    &:hover {
      filter: brightness(100%);
    }
  }

  &:hover {
    filter: brightness(90%);
  }

  margin: ${rem(56)} auto;
  flex: 0 1 auto;
  border: none;
  border-radius: 0 ${rem(2)} ${rem(2)} 0;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.secondary};
  height: ${rem(48)};
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  max-width: ${rem(320)};

  padding: ${rem(15)} ${rem(32)};
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    cartId: cartIdSelector(state),
    favouriteProducts: favouriteProductsSelector(state),
  };
};

const MyAccountProtected = withRouter(authProtected(MyAccount));
// const MyAccountProtected = MyAccount;

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyAccount' })(MyAccountProtected),
);
