import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZlataPriadkaTitle as Title,
  ZlataPriadkaContactPersonBlock as ContactPersonBlock,
  ZlataPriadkaBlockWithTitle as BlockWithTitle,
  ZlataPriadkaContactInfoBlock as ContactInfoBlock,
  ZlataPriadkaInvoiceAddressBlock as InvoiceAddressBlock,
  ZlataPriadkaCompanyInfo as CompanyInfo,
  ZlataPriadkaInfoText as InfoText,
  ZlataPriadkaButton as Button,
  FlexRowCenterVertical,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import { fetchCustomerDetails } from '../../containers/MyAccount/myAccountSlice';

interface Props {
  customerInfo: ThenArg<typeof API.getCustomerDetails>;
  userId: number;
  dispatchProp?: any;
}

function reducer(state, { field, value }) {
  return { ...state, [field]: value };
}

function MyInfo({ customerInfo, userId, dispatchProp }: Props) {
  const companyRef: any = React.useRef() as React.MutableRefObject<
    HTMLInputElement
  >;
  const {
    meno,
    priezvisko,
    email,
    ico: currentIco,
    dic: currentDic,
    icdph: currentIcDph,
    firma,
    country: currentCountry,
    cislo,
    mesto,
    psc,
    telefon,
    ulica,
  } = customerInfo;
  const isNameSet = meno ? meno !== '' : false;
  const isSurnameSet = priezvisko ? priezvisko !== '' : false;
  const initialState = {
    name: meno || '',
    surname: priezvisko || '',
    ico: currentIco || '',
    dic: currentDic || '',
    icdph: currentIcDph || '',
    company: firma || '',
    country: currentCountry || 'sk',
    streetNumber: cislo || '',
    city: mesto || '',
    zip: psc || '',
    phone: telefon || '',
    street: ulica || '',
    isCompany: firma && currentIco && currentDic && currentIcDph,
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [formProblem, setFormProblem] = React.useState({});

  const onChange = e => {
    const {
      target: { name, value },
    } = e;
    dispatch({ field: name, value: value });
    if (name === 'isCompany' && value) {
      if (companyRef && companyRef.current) {
        window.scrollTo({
          behavior: 'smooth',
          top: companyRef.current.offsetTop,
        });
      }
    }
  };

  const {
    ico,
    dic,
    icdph,
    company,
    country,
    streetNumber,
    street,
    city,
    zip,
    phone,
    isCompany,
    name,
    surname,
  } = state;

  const updateDetails = async () => {
    setIsSaving(true);

    if (isValidUserData()) {
      try {
        await API.updateCustomerDetails(
          userId,
          {},
          {
            ...customerInfo,
            meno: name,
            priezvisko: surname,
            firma: company,
            ico,
            dic,
            icdph,
            ulica: street,
            cislo: streetNumber,
            telefon: phone,
            psc: zip,
            mesto: city,
            country,
          },
        );

        setFormProblem({});
        setDataUpdated(true);
        await dispatchProp(fetchCustomerDetails());
      } catch (e) {
        setError(e);
        setIsSaving(false);
        return;
      }
    }

    setIsSaving(false);
  };

  const isValidUserData = () => {
    // check phone number
    const re = /^\+(\d{12})$/;
    if (
      (!phone.startsWith('+421') || !phone.startsWith('+420')) &&
      !re.test(phone.trim())
    ) {
      setFormProblem({
        phone: __(
          'Telefónne číslo je potrebné zadať v tvare +421xxxxxxxxx, resp. +420xxxxxxxxx',
        ),
      });
      return false;
    }
    return true;
  };

  React.useEffect(() => {});

  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={32}>
        {__('Moje údaje')}
      </StyledTitle>
      <DimWrapper dimmed={isSaving}>
        <BlockWithTitle title={'Kontaktná osoba'}>
          <ContactPersonBlock
            onChange={onChange}
            isNameSet={isNameSet}
            isSurnameSet={isSurnameSet}
            name={name}
            surname={surname}
            isCompany={isCompany}
          />
        </BlockWithTitle>
        <BlockWithTitle title={'Kontaktné údaje'}>
          <ContactInfoBlock
            email={email}
            phone={phone}
            onChange={onChange}
            formProblems={formProblem}
          />
        </BlockWithTitle>
        <BlockWithTitle title={'Fakturačná adresa'}>
          <InvoiceAddressBlock
            onChange={onChange}
            street={street}
            streetNumber={streetNumber}
            city={city}
            zip={zip}
            country={country}
            use_delivery_address={true}
            isCompany={isCompany}
          />
        </BlockWithTitle>
        <CompanyWrapper visible={isCompany} ref={companyRef}>
          <BlockWithTitle title={'Údaje o firme'}>
            <CompanyInfo
              onChange={onChange}
              name={company}
              ico={ico}
              dic={dic}
              icdph={icdph}
            />
          </BlockWithTitle>
        </CompanyWrapper>
      </DimWrapper>
      {!error && dataUpdated && (
        <InfoText
          type={InfoType.SUCCESS}
          info={__('Údaje boli úspešne zmenené')}
        />
      )}
      {error && (
        <InfoText
          type={InfoType.ERROR}
          info={__('Pri ukladaní sa vyskytla chyba')}
        />
      )}
      <ButtonWrapper>
        <SaveButton onClick={updateDetails}>
          {isSaving ? __('Ukladám údaje ...') : __('Uložiť')}
        </SaveButton>
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  /* max-width: ${rem(1048)}; */
  margin-right: 0;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(16)};
  `}
`;

const CompanyWrapper = styled.div<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  height: ${({ visible }) => (visible ? 'auto' : 0)};
`;

const DimWrapper = styled(FlexCol)<{ dimmed: boolean }>`
  opacity: ${({ dimmed }) => (dimmed ? 0.4 : 1)};
`;

const ButtonWrapper = styled(FlexRowCenterVertical)`
  flex-flow: row wrap;

  ${({ theme }) => theme.mediab.m580`
    justify-content: center;
  `}
`;

const SaveButton = styled(Button)`
  max-width: ${rem(320)};
  width: initial;

  ${({ theme }) => theme.mediab.m580`
    width: 100%;
  `}

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.primaryOnHover};
    outline: none;
  }
`;

const StyledTitle = styled(Title)`
  font-size: ${rem(24)};
  line-height: ${rem(31)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.l925`
    margin-bottom: ${rem(56)};
  `}
`;

export default MyInfo;
