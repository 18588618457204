import { useState, Dispatch, useReducer } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      if (window && window.localStorage) {
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      }
      return initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

function useInterval(callback: any, delay) {
  const savedCallback: any = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback && savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay]);
}

function useInput(initialValue) {
  const [value, setValue] = useState(initialValue || '');

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: event => {
        setValue(event.target.value);
      },
    },
  };
}

function useForm<T>(
  initialForm: T,
): [T, Dispatch<{ field: string; value: any }>] {
  function formReducer(
    state: T,
    { field, value }: { field: string; value: any },
  ) {
    if (field) {
      const pathToProperty = field.split('.');

      if (pathToProperty.length === 3) {
        return {
          ...state,
          [pathToProperty[0]]: {
            ...state[pathToProperty[0]],
            [pathToProperty[1]]: {
              ...state[pathToProperty[0]][pathToProperty[1]],
              [pathToProperty[2]]: value,
            },
          },
        };
      } else if (pathToProperty.length === 2) {
        return {
          ...state,
          [pathToProperty[0]]: {
            ...state[pathToProperty[0]],
            [pathToProperty[1]]: value,
          },
        };
      } else if (pathToProperty.length === 1) {
        return {
          ...state,
          [field]: value,
        };
      }
    }

    return state;
  }

  const [formState, dispatchForm] = useReducer(formReducer, initialForm);
  return [formState, dispatchForm];
}

export { useLocalStorage, useInterval, useInput, useForm };
