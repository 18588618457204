import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import API, { ThenArg } from '../../services/API';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { loadFavouriteProducts } from '../Home/actions';
import {
  favouriteProductsSelector,
  newProductsSelector,
} from '../Home/selectors';
import { categoryProductsSelector } from '../Category/categorySlice';
import { prop } from '../../utilities';
import { MyFavourites } from '../../components/MyAccount/MyFavourites';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

interface Props {
  user: any;
  dispatch: any;
  products: any;
}

interface State {
  currentId: string;
}

class MyFavouritesCointainer extends React.Component<Props, State> {
  public static async getInitialProps(props) {
    const { dispatch, user } = props;

    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.FAVOURITE));

      await Promise.all[await dispatch(loadFavouriteProducts(user.id))];

      return {
        props,
        isLoaded: true,
      };
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      currentId: props.user.id,
    };
  }

  public render() {
    const { user, dispatch, products } = this.props;
    const isFetching = prop(products, 'isFetching', false);
    const favouriteProducts = prop(products, 'productsData.products', []);
    return (
      <>
        <MetaTags tags={{ title: 'Moj účet - Obľúbené' }} />
        <MyFavourites
          products={favouriteProducts}
          dispatch={dispatch}
          user={user}
          isFetching={isFetching}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    products: favouriteProductsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyFavouritesCointainer' })(MyFavouritesCointainer),
);
