export default function configureTagManager(hostname) {
  switch (hostname) {
    default:
      return 'GTM-MJCBGN5';
  }
}

export function getGoogleAdsId() {
  return 'AW-467316774';
}
