import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from 'react-i18n';
import {
  FlexColCenter,
  Flexbox,
  HighlightedLink,
  ModalWindow,
  ZlataPriadkaSelect as Select,
  ZlataPriadkaButton as Button,
} from 'eshop-defaults';

const ModalContentWrapper: any = styled(FlexColCenter)`
  height: 100%;
  width: ${(props: any) => (props.isValueSet ? rem(710) : rem(510))};
  color: ${props => props.theme.color.textPrimary};

  @media all and (max-height: 650px) {
    height: 500px;
  }

  @media all and (max-height: 600px) {
    height: 300px;
  }

  @media all and (max-width: 900px) {
    width: 90%;
  }
`;

const ModalTitle = styled.span`
  font-size: ${rem(24)};
  font-family: ${({ theme }) => theme.font.primary};
`;

const UpperWrapper: any = styled(Flexbox)`
  width: 100%;
  height: ${rem(40)};
  align-items: flex-end;
  margin-top: ${rem(30)};
  margin-bottom: ${(props: any) => (props.isValueSet ? rem(30) : 0)};

  @media all and (max-width: 900px) {
    flex-direction: column;
    height: 5.6rem;
    align-items: center;
  }
`;

const SelectContainer: any = styled(Flexbox)`
  max-width: ${rem(512)};
  width: 100%;
  height: ${rem(40)};
  align-items: flex-end;
`;

const StyledButton = styled(Button)`
  max-width: ${rem(188)};
  width: 100%;
  border: none;
  border-radius: ${rem(4)};
  margin-left: auto;
  background: ${({ theme }) => theme.color.secondary};
  color: #fff;
  font-size: ${rem(14)};
  font-family: ${({ theme }) => theme.font.primary};
  height: ${rem(48)};

  @media all and (max-width: 900px) {
    max-width: 32rem;
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
`;

const WideDiv = styled.div`
  width: 100%;
`;

const InformationWrapper = styled(WideDiv)`
  flex-direction: column;
  max-height: ${rem(370)};
  overflow-y: auto;

  @media all and (max-height: 600px) {
    max-height: ${rem(150)};
  }
`;

const InformationBlock = styled(WideDiv)`
  margin-bottom: ${rem(30)};
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }
`;
const Header = styled(WideDiv)`
  border-bottom: ${rem(1)} solid #eaeaea;
  margin-bottom: ${rem(15)};
  font-size: ${rem(20)};
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.primary};
  padding-bottom: ${rem(10)};
`;

const StrongTd: any = styled.td`
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.primary};
  ${(props: any) => props.color && `color: ${props.color}`};
`;

const StyledTd = styled.td`
  padding-left: ${rem(32)};
  font-family: ${({ theme }) => theme.font.primary};
`;

const LowerWrapper = styled.div`
  width: 100%;
  margin-top: ${rem(15)};
  font-size: ${rem(14)};
  font-family: ${({ theme }) => theme.font.primary};
`;

interface PostaModalProps {
  isModalVisible: boolean;
  items: any;
  itemsById: any;
  selectValue: string | number;
  defaultSelectValue: {
    name: string;
    value: string | undefined;
  };
  handleModalOpen: (id: string) => void;
  handleModalClose: () => void;
  handleSelectChange: (value: string | number) => void;
  handleConfirmButtonClick: (point: any) => void;
  handleModalCloseWithoutSaving: any;
}

class PostaModal extends React.Component<PostaModalProps> {
  constructor(props) {
    super(props);

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.openingHoursForWholeWeek = this.openingHoursForWholeWeek.bind(this);
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
  }

  public handleModalOpen(id: string): void {
    this.props.handleModalOpen(id);
  }

  public handleModalClose(): void {
    this.props.handleModalClose();
  }

  public handleSelectChange(e) {
    this.props.handleSelectChange(e.target.value);
  }

  public openingHoursForWholeWeek(item): React.ReactNode {
    const {
      hodiny_pondelok,
      hodiny_utorok,
      hodiny_streda,
      hodiny_stvrtok,
      hodiny_piatok,
      hodiny_sobota,
      hodiny_nedela,
    } = item;

    return (
      <tbody>
        <tr>
          <StrongTd>{__('Pondelok')}</StrongTd>
          <StyledTd>{hodiny_pondelok}</StyledTd>
        </tr>
        <tr>
          <StrongTd>{__('Utorok')}</StrongTd>
          <StyledTd>{hodiny_utorok}</StyledTd>
        </tr>
        <tr>
          <StrongTd>{__('Streda')}</StrongTd>
          <StyledTd>{hodiny_streda}</StyledTd>
        </tr>
        <tr>
          <StrongTd>{__('Štvrtok')}</StrongTd>
          <StyledTd>{hodiny_stvrtok}</StyledTd>
        </tr>
        <tr>
          <StrongTd>{__('Piatok')}</StrongTd>
          <StyledTd>{hodiny_piatok}</StyledTd>
        </tr>
        <tr>
          <StrongTd>{__('Sobota')}</StrongTd>
          <StyledTd>{hodiny_sobota}</StyledTd>
        </tr>
        <tr>
          <StrongTd>{__('Nedeľa')}</StrongTd>
          <StyledTd>{hodiny_nedela}</StyledTd>
        </tr>
      </tbody>
    );
  }

  public getMapUrl(selectedItem) {
    const { gps_latitude, gps_longitude } = selectedItem;
    return `http://maps.google.com/maps?q=${gps_latitude},${gps_longitude}`;
  }

  public handleConfirmButtonClick(): void {
    const { itemsById, selectValue } = this.props;
    this.props.handleConfirmButtonClick(itemsById[selectValue]);
    this.handleModalClose();
  }

  public render() {
    const {
      isModalVisible,
      defaultSelectValue,
      items,
      itemsById,
      selectValue,
      handleModalCloseWithoutSaving,
    } = this.props;
    const isValueSet: boolean = selectValue !== '' ? true : false;

    return (
      <ModalWindow
        isVisible={isModalVisible}
        handleHideModal={handleModalCloseWithoutSaving}
        hideOnEscPress={true}
      >
        <ModalContentWrapper isValueSet={isValueSet}>
          <ModalTitle>{__('Zvoľte pobočku')}</ModalTitle>
          <UpperWrapper isValueSet={isValueSet}>
            <SelectContainer isValueSet={isValueSet}>
              <Select
                onChange={this.handleSelectChange}
                options={items.map(deliveryPoint => ({
                  name: `${deliveryPoint.name}, ${deliveryPoint.street} ${deliveryPoint.number}`,
                  value: deliveryPoint.id,
                }))}
                value={selectValue}
                defaultValue={defaultSelectValue}
                border={'#EEEEEE'}
                maxWidth={512}
                mobileMarginBottom={0}
              />
            </SelectContainer>
            {selectValue !== defaultSelectValue.value && (
              <StyledButton onClick={this.handleConfirmButtonClick}>
                {__('Potvrdiť a uložiť pobočku')}
              </StyledButton>
            )}
          </UpperWrapper>
          {itemsById[selectValue] && (
            <>
              <InformationWrapper>
                <InformationBlock>
                  <Header>{__('Pobočka')}</Header>
                  <table>
                    <tbody>
                      <tr>
                        <StrongTd>{__('Meno')}:</StrongTd>
                        <StyledTd>{itemsById[selectValue].name}</StyledTd>
                      </tr>
                      <tr>
                        <StrongTd>{__('Ulica')}:</StrongTd>
                        <StyledTd>
                          {itemsById[selectValue].street}{' '}
                          {itemsById[selectValue].number}
                        </StyledTd>
                      </tr>
                      <tr>
                        <StrongTd>{__('Mesto')}:</StrongTd>
                        <StyledTd>
                          {itemsById[selectValue].zip}{' '}
                          {itemsById[selectValue].village}
                        </StyledTd>
                      </tr>
                    </tbody>
                  </table>
                </InformationBlock>
                <InformationBlock>
                  <Header>{__('Otváracie hodiny')}</Header>
                  <table>
                    {this.openingHoursForWholeWeek(itemsById[selectValue])}
                  </table>
                </InformationBlock>
              </InformationWrapper>
              <LowerWrapper>
                <HighlightedLink
                  target="_blank"
                  href={this.getMapUrl(itemsById[selectValue])}
                >
                  {__('Zobraziť na mape')}
                </HighlightedLink>
              </LowerWrapper>
            </>
          )}
        </ModalContentWrapper>
      </ModalWindow>
    );
  }
}

export default PostaModal;
